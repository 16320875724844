import {
  IArke,
  UserRolesResponseV1,
  UserRoles
} from "@intreba/arke-api-client";
import { action, observable, runInAction, computed } from "mobx";
import { PaginationStore } from "../../../../services/pagination/PaginationStore";
import history from "../../../../services/history";
import {
  showErrorToast,
  showSuccessToast
} from "../../../../services/commonToasts";

export function userUrl(email: string) {
  return `users/user?email=${encodeURIComponent(email)}`;
}

export class UsersStore extends PaginationStore<string> {
  private arke: IArke;
  private siteId: string;
  @observable
  public search: string = "";
  @observable
  public isWorking: boolean = false;
  @computed public get selectedUserUrl(): string {
    if (this.selectedItem === null) {
      return "";
    }
    return userUrl(this.selectedItem);
  }

  @computed public get usersUrl(): string {
    return `/sites/${this.siteId}/settings/users`;
  }

  @observable
  public userDetails:
    | {
        role: UserRoles;
        manageable: boolean;
        permission: boolean;
        accountLevelRole: boolean;
      }[]
    | null = null;
  @observable
  public userDetailsOriginal: UserRolesResponseV1 | null = null;

  @computed public get disabled() {
    return this.isGettingItems || this.isWorking;
  }
  constructor(arke: IArke, siteId: string) {
    super();
    this.arke = arke;
    this.siteId = siteId;
  }

  @action
  private async getDetails(email: string) {
    try {
      var result = await this.arke.users.find(this.siteId, email);

      runInAction(() => {
        this.userDetailsOriginal = result;
        this.userDetails = result.roles.map(value => {
          return {
            role: value.role,
            manageable: value.manageable,
            permission: value.permissionId !== null,
            accountLevelRole: value.accountLevelRole
          };
        });
      });
    } catch (e) {
      console.error(e);
    }
  }
  @action
  public async toggleRole(
    e: React.ChangeEvent<HTMLInputElement>,
    role: UserRoles
  ) {
    if (this.userDetailsOriginal === null) {
      return;
    }
    e.preventDefault();
    this.isWorking = true;
    try {
      const currentValues = this.userDetailsOriginal.roles.filter(
        r => r.role === role && r.accountLevelRole === false
      );
      if (currentValues.length !== 1) {
        return;
      }
      const currentValue = currentValues[0];
      if (currentValue.permissionId === null) {
        await this.addRole(this.userDetailsOriginal.email, currentValue.role);
      } else {
        await this.removeRole(
          this.userDetailsOriginal.email,
          currentValue.role
        );
      }
    } finally {
      this.stopWorking();
    }
  }

  @action
  public async toggleRolesState(index: UserRoles) {
    if (this.userDetails === null || this.userDetailsOriginal === null) {
      return;
    }
    this.userDetails[index].permission = !this.userDetails[index].permission;
  }

  @action
  public async saveRoleChanges() {
    if (this.userDetails === null || this.userDetailsOriginal === null) {
      return;
    }
    this.isWorking = true;
    try {
      //iterating through the 'roles do add' first
      for (let i = 0; i < this.userDetails.length; i++) {
        if (
          this.userDetails[i].permission === true &&
          typeof this.userDetailsOriginal.roles[i].permissionId !== "string"
        ) {
          await this.addRole(
            this.userDetailsOriginal.email,
            this.userDetails[i].role as UserRoles
          );
        }
      }

      for (let i = 0; i < this.userDetails.length; i++) {
        if (
          this.userDetails[i].permission === false &&
          typeof this.userDetailsOriginal.roles[i].permissionId !== "object"
        ) {
          await this.removeRole(
            this.userDetailsOriginal.email,
            this.userDetails[i].role as UserRoles
          );
        }
      }

      if (
        this.userDetails !== null &&
        this.userDetails.filter(r => !r.accountLevelRole && !r.permission)
          .length === this.userDetails.length
      ) {
        history.push(this.usersUrl);
      } else {
        await this.getDetails(this.userDetailsOriginal.email);
      }
      showSuccessToast();
    } catch (e) {
      console.error(e);
      showErrorToast();
    }

    this.stopWorking();
  }

  @action
  public async getUserDetails(user: string) {
    this.isGettingItems = true;
    await this.getDetails(user);
    this.selectItem(user);
    runInAction(() => (this.isGettingItems = false));
  }
  public getItems(): Promise<{
    items: string[];
    totalAmount: number;
  }> {
    return this.arke.users.list(
      this.siteId,
      this.pageToBeRetrieved,
      this.itemsPerPage
    );
  }
  @action
  public async removeRole(email: string, role: UserRoles) {
    try {
      await this.arke.users.removeRole(this.siteId, email, role);
    } catch (e) {
      console.error(e);
      showErrorToast();
    }
  }
  @action
  public async addRole(email: string, role: UserRoles) {
    try {
      await this.arke.users.addRole(this.siteId, email, role);
    } catch (e) {
      console.error(e);
      showErrorToast();
    }
  }

  @action
  private stopWorking() {
    this.isWorking = false;
  }

  @action
  public async removePermissions() {
    if (this.userDetailsOriginal === null) {
      return;
    }
    this.isWorking = true;
    try {
      for (
        let index = 0;
        index < this.userDetailsOriginal.roles.length;
        index++
      ) {
        const role = this.userDetailsOriginal.roles[index];
        if (role.permissionId !== null && !role.accountLevelRole) {
          await this.arke.users.removeRole(
            this.siteId,
            this.userDetailsOriginal.email,
            role.role
          );
        }
      }
      showSuccessToast();
      history.push(this.usersUrl);
    } catch (e) {
      console.error(e);
      showErrorToast();
    }

    this.stopWorking();
  }
}
