import React from "react";
import { AdditionalFieldResponseV1 } from "@intreba/arke-api-client";
import { Ctd } from "../../../../components";
import { Button } from "reactstrap";
import { useTranslation } from "react-i18next";

export const AdditionalFieldRow = (props: {
  additionalField: AdditionalFieldResponseV1;
  onSelect: (additionalField: AdditionalFieldResponseV1) => void;
  onDelete: (additionalFieldId: string) => void;
}) => {
  const onEditClick = (
    event: React.MouseEvent<HTMLTableDataCellElement, MouseEvent>
  ) => {
    event.preventDefault();
    props.onSelect(props.additionalField);
  };

  const onDeleteClick = (
    event: React.MouseEvent<HTMLTableDataCellElement, MouseEvent>
  ) => {
    event.preventDefault();
    props.onDelete(props.additionalField.additionalFieldId);
  };
  const { t } = useTranslation("sitesettingsAdditionalFields");

  const hostHref = `additionalfields/${props.additionalField.additionalFieldId}`;
  return (
    <tr style={{ cursor: "pointer" }}>
      <Ctd href={hostHref} onClick={(e) => e.preventDefault()}>
        {props.additionalField.name}
      </Ctd>
      <Ctd href={hostHref} onClick={(e) => e.preventDefault()}>
        {t(
          "sitesettingsAdditionalFieldsDetails:" +
            props.additionalField.fieldType
        )}
      </Ctd>
      <Ctd href={hostHref} onClick={(e) => e.preventDefault()}>
        {props.additionalField.description}
      </Ctd>
      <Ctd
        href={hostHref}
        style={{ verticalAlign: "middle", textAlign: "center" }}
        onClick={(e) => onEditClick(e)}
      >
        <Button color="primary">{t("edit")}</Button>
      </Ctd>
      <Ctd
        href={hostHref}
        style={{ verticalAlign: "middle", textAlign: "center" }}
        onClick={(e) => onDeleteClick(e)}
      >
        <Button color="primary">{t("delete")}</Button>
      </Ctd>
    </tr>
  );
};
