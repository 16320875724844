import {
  IArke,
  SiteResponseV1,
  userHasSitePermission,
  SitePermissions,
} from "@intreba/arke-api-client";
import { inject, observer, Provider } from "mobx-react";
import * as React from "react";
import { Route, RouteComponentProps, Switch } from "react-router-dom";
import ViewVisits from "./ViewVisits";
import { VisitDetailPage } from "./VisitDetails";
import { VisitDocumentPage } from "./VisitDocumentPage";
import VisitsStore from "./VisitsStore";
import { CreateVisitPage } from "./CreateVisitPage";

interface IProps {
  arke: IArke;
  itemContainer: { site: SiteResponseV1 };
}

@inject("arke", "itemContainer")
@observer
export default class VisitsRouter extends React.Component<
  IProps & RouteComponentProps<{ siteId: string }>,
  {}
> {
  private visitsStore: VisitsStore;
  public constructor(props: any) {
    super(props);
    this.visitsStore = new VisitsStore(
      this.props.arke,
      this.props.match.params.siteId,
      this.props.history,
      userHasSitePermission(
        this.props.itemContainer.site.permissions,
        SitePermissions.visitsreadall
      )
    );
  }
  public render() {
    return (
      <Provider visitsStore={this.visitsStore}>
        <Switch>
          <Route
            exact={true}
            path={this.props.match.path}
            component={ViewVisits}
          />
          <Route
            path={`${this.props.match.path}/create`}
            exact={true}
            component={CreateVisitPage}
          />
          <Route
            path={`${this.props.match.path}/:visitId`}
            exact={true}
            component={VisitDetailPage}
          />
          <Route
            path={`${this.props.match.path}/:visitId/documents`}
            component={VisitDocumentPage}
          />
        </Switch>
      </Provider>
    );
  }
}
