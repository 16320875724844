import {
  IArke,
  PinLength,
  PinMode,
  SiteNet2DoorV1,
  SitePermissions,
  SiteResponseV1,
  GroupVisitLimitOverrides,
  SiteNet2DepartmentResponseV1,
  DailyVisitLimitSettingsRequestV1,
  VisitLimitMode,
} from "@intreba/arke-api-client";
import i18n from "i18next";
import { action, IObservableArray, observable, runInAction } from "mobx";
import BaseSettingsState from "../../BaseSettingsState";

export enum VisitLimitInputState {
  global,
  custom,
  unlimited,
}

export interface GlobalLimitSettingsBase
  extends DailyVisitLimitSettingsRequestV1 {
  visitLimitMode: VisitLimitMode;
  allowDailyVisitLimitOverrideByStaff: boolean;
  globalDailyVisitLimit: number | null;
  groupVisitLimitOverrides: { [k: string]: any };
  groupVisitLimitOverridesObjects: GroupVisitLimit[];
}

export class GlobalLimitSettings implements GlobalLimitSettingsBase {
  @observable
  public visitLimitMode!: VisitLimitMode;
  @observable
  public allowDailyVisitLimitOverrideByStaff!: boolean;
  @observable
  public globalDailyVisitLimit!: number | null;
  @observable
  public groupVisitLimitOverrides!: { [k: string]: any };
  @observable
  public groupVisitLimitOverridesObjects!: GroupVisitLimit[];
}

export interface GroupVisitLimit extends GroupVisitLimitOverrides {
  groupId: string;
  dailyLimit: number | null;
  groupName: string;
  inputState: VisitLimitInputState;
}

export class AccessSettingsState extends BaseSettingsState {
  private arke: IArke;

  constructor(t: i18n.TFunction, arke: IArke, site: SiteResponseV1) {
    super(
      t,
      SitePermissions.siteaccesssettingsupdate,
      site.permissions,
      site.siteId
    );
    this.arke = arke;
    this.getNet2Departments();
  }

  public async save(): Promise<void> {
    let overrides: { [k: string]: any } = {};
    let response: DailyVisitLimitSettingsRequestV1 = {
      visitLimitMode: VisitLimitMode.daily,
      globalDailyVisitLimit: null,
      allowDailyVisitLimitOverrideByStaff: false,
      groupVisitLimitOverrides: overrides,
    };

    if (this.globalLimitSettings.globalDailyVisitLimit) {
      this.globalLimitSettings.groupVisitLimitOverridesObjects.forEach(
        (group) => {
          if (
            group.dailyLimit !== this.globalLimitSettings.globalDailyVisitLimit
          )
            if (group.dailyLimit === null) {
              overrides[group.groupId] = null;
            } else {
              overrides[group.groupId] = group.dailyLimit;
            }
        }
      );
      response = {
        visitLimitMode: this.globalLimitSettings.visitLimitMode,
        globalDailyVisitLimit: this.globalLimitSettings.globalDailyVisitLimit,
        allowDailyVisitLimitOverrideByStaff: this.globalLimitSettings
          .allowDailyVisitLimitOverrideByStaff,
        groupVisitLimitOverrides: overrides,
      };
    }
    await this.arke.sites.updateAccessSettings(this.siteId, {
      pinExpirationDays: this.pinExpirationDays,
      pinExpirationHours: this.pinExpirationHours,
      dailyLimitSettings: response,
      pinMode: this.pinMode,
      useHostAccessLevel: this.useHostAccessLevel,
      net2InFilter: this.net2InFilter,
      net2OutFilter: this.net2OutFilter,
      scanning: this.scanning,
      unregisteredVisitor: this.unregisteredVisitor,
      pinLength: this.pinLength === 0 ? null : this.pinLength,
    });
    this.net2Departments = [];
  }

  public getDepartmentInfo(groupId: string) {
    let group: GroupVisitLimit[] = this.globalLimitSettings.groupVisitLimitOverridesObjects.filter(
      (value) => {
        return value.groupId === groupId;
      }
    );
    if (group.length)
      return {
        dailyLimit: group[0].dailyLimit,
        inputState: group[0].inputState
          ? VisitLimitInputState.custom
          : VisitLimitInputState.unlimited,
      };
    return {
      dailyLimit: this.globalLimitSettings.globalDailyVisitLimit,
      inputState: VisitLimitInputState.global,
    };
  }

  public async getNet2Departments() {
    const settings = await this.arke.sites.findNet2HostProvisioningSettings(
      this.siteId
    );
    if (settings !== null) {
      this.net2Departments = settings.departments;
    }
  }

  @observable
  public scanning: boolean = false;

  @observable
  public unregisteredVisitor: boolean = false;

  @observable
  public net2InFilter: IObservableArray<number> = observable([]);
  @observable
  public net2OutFilter: IObservableArray<number> = observable([]);

  @observable
  public globalLimitSettings: GlobalLimitSettings = {
    visitLimitMode: VisitLimitMode.daily,
    globalDailyVisitLimit: null,
    allowDailyVisitLimitOverrideByStaff: false,
    groupVisitLimitOverrides: {},
    groupVisitLimitOverridesObjects: [],
  };

  public async retrieve(): Promise<void> {
    const settings = await this.arke.sites.findAccessSettings(this.siteId);
    const doors = await this.arke.sites.listNet2Doors(this.siteId);
    if (settings !== null) {
      runInAction(() => {
        this.pinMode = settings.pinMode;
        this.pinLength = settings.pinLength;
        this.pinExpirationDays = settings.pinExpirationDays;
        this.pinExpirationHours = settings.pinExpirationHours;
        this.useHostAccessLevel = settings.useHostAccessLevel;
        this.net2InFilter = observable(settings.net2InFilter);
        this.net2OutFilter = observable(settings.net2OutFilter);
        this.doors = observable(doors);
        this.scanning = settings.scanning;
        this.unregisteredVisitor = settings.unregisteredVisitor;
        this.globalLimitSettings.visitLimitMode =
          settings.dailyLimitSettingsResponseV1.visitLimitMode;
        this.globalLimitSettings.allowDailyVisitLimitOverrideByStaff =
          settings.dailyLimitSettingsResponseV1.allowDailyVisitLimitOverrideByStaff;
        this.globalLimitSettings.globalDailyVisitLimit =
          settings.dailyLimitSettingsResponseV1.globalDailyVisitLimit;
        this.globalLimitSettings.groupVisitLimitOverrides =
          settings.dailyLimitSettingsResponseV1.groupVisitLimitOverrides;

        this.net2Departments.forEach((group) => {
          if (group.synchronised === true) {
            let value: any =
              settings.dailyLimitSettingsResponseV1.globalDailyVisitLimit;
            let inputState;
            if (
              group.departmentId in
              settings.dailyLimitSettingsResponseV1.groupVisitLimitOverrides
            ) {
              value =
                settings.dailyLimitSettingsResponseV1.groupVisitLimitOverrides[
                  group.departmentId
                ];
              if (value == null) {
                inputState = VisitLimitInputState.unlimited;
              } else {
                inputState = VisitLimitInputState.custom;
              }
            }
            this.globalLimitSettings.groupVisitLimitOverridesObjects.push({
              groupId: group.departmentId.toString(),
              groupName: group.name,
              dailyLimit: value,
              inputState: inputState ? inputState : VisitLimitInputState.global,
            });
          }
        });
      });
    }
  }

  @observable
  public doors: IObservableArray<SiteNet2DoorV1> = observable([]);

  @observable
  public net2Departments: SiteNet2DepartmentResponseV1[] = [];

  @observable
  public pinMode: PinMode = "InOut";

  @observable
  public pinLength: PinLength | 0 = 0;

  @observable
  public pinExpirationHours: number = 0;

  @observable
  public pinExpirationDays: number = 1;

  @observable
  public useHostAccessLevel: boolean = false;

  @action
  public changePinExpirationHours = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    this.pinExpirationHours = event.target.valueAsNumber;
  };

  @action
  public onDailyVisitLimitInputChange = (groupId: string, value: number) => {
    let group = this.globalLimitSettings.groupVisitLimitOverridesObjects.find(
      (value) => {
        return value.groupId === groupId;
      }
    );
    if (!group) return;
    group.dailyLimit = value;
  };

  @action
  public onVisitLimitModeChange = (input: VisitLimitMode) => {
    this.globalLimitSettings.visitLimitMode = input;
  };

  @action
  public onDailyLimitDropdownChange = (
    groupId: string,
    state: VisitLimitInputState
  ) => {
    let group = this.globalLimitSettings.groupVisitLimitOverridesObjects.find(
      (value) => {
        return value.groupId === groupId;
      }
    );
    if (!group) return;
    if (state === VisitLimitInputState.global) {
      group.dailyLimit = this.globalLimitSettings.globalDailyVisitLimit;
    } else if (state === VisitLimitInputState.unlimited) {
      group.dailyLimit = null;
    } else {
      group.dailyLimit = 10;
    }
    group.inputState = state;
  };

  @action
  public changePinLength = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = parseInt(event.target.value, 10);
    if (
      value !== 4 &&
      value !== 5 &&
      value !== 6 &&
      value !== 7 &&
      value !== 8
    ) {
      return;
    }
    this.pinLength = value;
  };
  @action
  public changePinExpirationDays = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    this.pinExpirationDays = event.target.valueAsNumber;
  };
  @action
  public changePinMode = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    if (value !== "InOut" && value !== "Unlimited" && value !== "In") {
      return;
    }
    this.pinMode = value;
  };
  @action
  public changeUseHostAccessLevel = () => {
    this.useHostAccessLevel = !this.useHostAccessLevel;
  };
  @action
  public changeHostDailyLimit = async (value: number | null) => {
    this.globalLimitSettings.globalDailyVisitLimit = value;
    this.globalLimitSettings.groupVisitLimitOverridesObjects.forEach(
      (group) => {
        if (group.inputState === VisitLimitInputState.global) {
          group.dailyLimit = value;
        }
      }
    );
  };

  @action
  public changeNet2InFilter = (event: React.ChangeEvent<HTMLInputElement>) => {
    const door = this.net2InFilter.filter(
      (d) => d.toString() === event.currentTarget.value
    )[0];
    if (door === undefined) {
      this.net2InFilter.push(parseInt(event.currentTarget.value, 10));
    } else {
      this.net2InFilter.remove(door);
    }
  };

  @action
  public changeNet2OutFilter = (event: React.ChangeEvent<HTMLInputElement>) => {
    const door = this.net2OutFilter.filter(
      (d) => d.toString() === event.currentTarget.value
    )[0];
    if (door === undefined) {
      this.net2OutFilter.push(parseInt(event.currentTarget.value, 10));
    } else {
      this.net2OutFilter.remove(door);
    }
  };

  @action
  public changeScanning = () => {
    this.scanning = !this.scanning;
  };

  @action
  public changeUnregisteredVisitor = () => {
    this.unregisteredVisitor = !this.unregisteredVisitor;
  };

  @action
  public changeAllowDailyVisitLimitOverrideByStaff = () => {
    this.globalLimitSettings.allowDailyVisitLimitOverrideByStaff = !this
      .globalLimitSettings.allowDailyVisitLimitOverrideByStaff;
  };
}
