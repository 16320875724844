import { SiteResponseV1 } from "@intreba/arke-api-client";
import { inject, observer } from "mobx-react";
import * as React from "react";
import PhoneNumberUtility from "./PhoneNumberUtility";

const utility = new PhoneNumberUtility();
interface InjectedProps extends IProps {
  itemContainer: { site: SiteResponseV1 };
}

interface IProps {
  phoneNumber: string;
}

@inject("itemContainer")
@observer
export default class LocalisedPhoneNumber extends React.Component<IProps, {}> {
  private get injectedprops() {
    return this.props as InjectedProps;
  }
  public render() {
    return (
      <span>
        {utility.friendlyFormat(
          this.props.phoneNumber,
          this.injectedprops.itemContainer.site.countryCode
        )}
      </span>
    );
  }
}
