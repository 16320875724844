import { inject, observer } from "mobx-react";
import * as React from "react";
import {
  Button,
  Col,
  Form,
  FormGroup,
  FormText,
  Label,
  Row,
  CardHeader,
  CardBody,
  Media,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from "reactstrap";

import {
  IArke,
  SitePermissions,
  SiteResponseV1,
} from "@intreba/arke-api-client";
import Input from "reactstrap/lib/Input";
import ShowWithPermission from "../../../../components/user/ShowWithPermission";
import { Spinner } from "../../../../components";
import countryCodeToName from "../../../../services/countryCodeToName";
import { CannotEditWarning } from "../CannotEditWarning";
import { GeneralSettingsState } from "./GeneralSettingsState";
import { withTranslation, WithTranslation } from "react-i18next";
import {
  ValidatedPhoneNumberInput,
  PhoneValidation,
} from "../../../../components/phonenumber/PhoneNumberInput";
import { X } from "react-feather";
interface InjectedProps extends IProps {
  itemContainer: { site: SiteResponseV1 };
  arke: IArke;
}

interface IProps extends WithTranslation {}

@inject("itemContainer", "arke")
@observer
class GeneralSettingsContent extends React.Component<IProps, {}> {
  private get injectedprops() {
    return this.props as InjectedProps;
  }
  public constructor(props: any) {
    super(props);
    this.settingsState = new GeneralSettingsState(
      this.injectedprops.t,
      this.injectedprops.arke,
      this.injectedprops.itemContainer.site
    );
  }
  private settingsState: GeneralSettingsState;

  public async componentDidMount() {
    await this.settingsState.load();
  }
  public render() {
    const t = this.injectedprops.t!;
    return (
      <>
        <Modal
          isOpen={this.settingsState.showRepresentativeLogoDeletionModal}
          toggle={this.settingsState.toggleRepresentativeLogoDeletionModal}
        >
          <ModalHeader
            toggle={this.settingsState.toggleRepresentativeLogoDeletionModal}
          >
            {t("common:areYouSure")}
          </ModalHeader>
          <ModalBody>{t("removeLogo")}</ModalBody>
          <ModalFooter>
            <Button
              color="primary"
              onClick={this.settingsState.removeRepresentativeLogo}
            >
              {t("common:yes")}
            </Button>{" "}
            <Button
              color="secondary"
              onClick={this.settingsState.toggleRepresentativeLogoDeletionModal}
            >
              {t("common:cancel")}
            </Button>
          </ModalFooter>
        </Modal>
        <Modal
          isOpen={this.settingsState.showSiteLogoDeletionModal}
          toggle={this.settingsState.toggleSiteLogoDeletionModal}
        >
          <ModalHeader toggle={this.settingsState.toggleSiteLogoDeletionModal}>
            {t("common:areYouSure")}
          </ModalHeader>
          <ModalBody>{t("removeLogo")}</ModalBody>
          <ModalFooter>
            <Button color="primary" onClick={this.settingsState.removeSiteLogo}>
              {t("common:yes")}
            </Button>{" "}
            <Button
              color="secondary"
              onClick={this.settingsState.toggleSiteLogoDeletionModal}
            >
              {t("common:cancel")}
            </Button>
          </ModalFooter>
        </Modal>
        <CardHeader tag="div">
          <Row style={{ marginBottom: "1rem" }}>
            <Col md={12}>
              <h2>{t("title")}</h2>
            </Col>
          </Row>
        </CardHeader>
        <CardBody>
          <ShowWithPermission permission={SitePermissions.sitesyncscreate}>
            <Row style={{ marginBottom: "1rem" }}>
              <Col sm="2">{t("syncLabel")}</Col>
              <Col sm="10">
                <Button
                  disabled={this.settingsState.loading}
                  onClick={this.settingsState.startSync}
                >
                  {t("syncButton")}
                </Button>
                <FormText color="muted">{t("syncHelp")}</FormText>
              </Col>
            </Row>
          </ShowWithPermission>
          <Row>
            <Col sm="12">
              <Form onSubmit={this.settingsState.onSubmit}>
                <CannotEditWarning state={this.settingsState} />
                <FormGroup row={true}>
                  <Label for="name" lg={2}>
                    {t("nameLabel")}
                  </Label>
                  <Col lg={10}>
                    <Input
                      type="text"
                      name="name"
                      id="siteName"
                      maxLength={150}
                      value={this.settingsState.name}
                      onChange={this.settingsState.changeName}
                      disabled={this.settingsState.disabled}
                    />
                    <FormText color="muted">{t("nameHelp")}</FormText>
                  </Col>
                </FormGroup>
                <Row>
                  <Col lg={{ size: 10, offset: 2 }}>
                    <Media>
                      {this.settingsState.siteLogoSource === null ? (
                        <Media heading>
                          <Spinner />
                        </Media>
                      ) : (
                        <>
                          <Media
                            object
                            src={this.settingsState.siteLogoSource}
                            alt="Current site logo"
                          ></Media>
                          <X
                            className="text-danger"
                            style={{ cursor: "pointer" }}
                            onClick={
                              this.settingsState.confirmAndDeleteSiteLogo
                            }
                          />
                        </>
                      )}
                    </Media>
                  </Col>
                </Row>
                <FormGroup row={true}>
                  <Label for="siteLogo" lg={2}>
                    {t("siteLogoLabel")}
                  </Label>
                  <Col lg={10}>
                    <input
                      type="file"
                      name="siteLogo"
                      onChange={this.settingsState.changeSiteLogo}
                    />
                    <FormText color="muted">{t("siteLogoHelp")}</FormText>
                  </Col>
                </FormGroup>
                <Row>
                  <Col lg={{ size: 10, offset: 2 }}>
                    <Media>
                      {this.settingsState.representativeLogoSource === null ? (
                        <Media heading>
                          <Spinner />
                        </Media>
                      ) : (
                        <>
                          <Media
                            object
                            src={this.settingsState.representativeLogoSource}
                            alt="Current site logo"
                          ></Media>
                          <X
                            className="text-danger"
                            style={{ cursor: "pointer" }}
                            onClick={
                              this.settingsState
                                .confirmAndDeleteRepresentativeLogo
                            }
                          />
                        </>
                      )}
                    </Media>
                  </Col>
                </Row>
                <FormGroup row={true}>
                  <Label for="representativeLogo" lg={2}>
                    {t("representativeLogoLabel")}
                  </Label>
                  <Col lg={10}>
                    <input
                      type="file"
                      name="representativeLogo"
                      onChange={this.settingsState.changeRepresentativeLogo}
                    />
                    <FormText color="muted">
                      {t("representativeLogoHelp")}
                    </FormText>
                  </Col>
                </FormGroup>
                <FormGroup row={true}>
                  <Label for="removalDays" lg={2}>
                    {t("removalDaysLabel")}
                  </Label>
                  <Col lg={10}>
                    <Input
                      type="number"
                      name="removalDays"
                      min={1}
                      max={365}
                      id="removalDays"
                      value={this.settingsState.retentionDaysValue}
                      onChange={this.settingsState.changeRetentionDays}
                      disabled={this.settingsState.disabled}
                    />
                    <FormText color="muted">{t("removalDaysHelp")}</FormText>
                  </Col>
                </FormGroup>
                <FormGroup row={true}>
                  <Label for="emergencyPhoneNumber" lg={2}>
                    {t("emergencyPhoneNumberLabel")}
                  </Label>
                  <Col lg={10}>
                    <ValidatedPhoneNumberInput
                      disabled={this.settingsState.disabled}
                      countryCode={
                        this.injectedprops.itemContainer.site.countryCode
                      }
                      id="emergencyPhoneNumber"
                      value={this.settingsState.emergencyPhoneNumber}
                      onChange={this.settingsState.changeEmergencyPhoneNumber}
                      validationType={PhoneValidation.All}
                    />
                    <FormText color="muted">
                      {t("emergencyPhoneNumberHelp")}
                    </FormText>
                  </Col>
                </FormGroup>

                <FormGroup row={true}>
                  <Label for="countryCode" lg={2}>
                    {t("countryLabel")}
                  </Label>
                  <Col lg={10}>
                    <Input
                      disabled={true}
                      type="text"
                      name="countryCode"
                      id="countryCode"
                      value={countryCodeToName(
                        this.injectedprops.itemContainer.site.countryCode
                      )}
                    />
                    <FormText color="muted">{t("countryHelp")}</FormText>
                  </Col>
                </FormGroup>
                <FormGroup row={true}>
                  <Label for="cultureId" lg={2}>
                    {t("cultureLabel")}
                  </Label>
                  <Col lg={10}>
                    <Input
                      type="select"
                      name="cultureId"
                      id="cultureId"
                      value={this.settingsState.cultureId}
                      onChange={this.settingsState.changeCultureId}
                      disabled={this.settingsState.disabled}
                    >
                      {this.settingsState.cultures.map((c) => (
                        <option value={c.id} key={c.id}>
                          {c.name}
                        </option>
                      ))}
                    </Input>
                    <FormText color="muted">{t("cultureHelp")}</FormText>
                  </Col>
                </FormGroup>
                <FormGroup row={true}>
                  <Label for="timeZoneId" lg={2}>
                    {t("timeZoneLabel")}
                  </Label>
                  <Col lg={10}>
                    <Input
                      type="select"
                      name="timeZoneId"
                      id="timeZoneId"
                      value={this.settingsState.timeZoneId}
                      // tslint:disable-next-line:jsx-no-lambda
                      onChange={this.settingsState.changeTimeZone}
                      disabled={this.settingsState.disabled}
                    >
                      {this.settingsState.timeZones.map((timeZone) => (
                        <option value={timeZone.id} key={timeZone.id}>
                          {timeZone.name}
                        </option>
                      ))}
                    </Input>
                    <FormText color="muted">{t("timeZoneHelp")}</FormText>
                  </Col>
                </FormGroup>
                {this.settingsState.disabled ? (
                  <CannotEditWarning state={this.settingsState} />
                ) : (
                  <Button
                    type="submit"
                    color="primary"
                    disabled={this.settingsState.disabled}
                  >
                    Save
                  </Button>
                )}
              </Form>
              {this.settingsState.loading && <Spinner />}
            </Col>
          </Row>
        </CardBody>
      </>
    );
  }
}

const tab = withTranslation("sitesettingsgeneral")(GeneralSettingsContent);

export { tab as GeneralSettings };
