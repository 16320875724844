import { IArke } from "@intreba/arke-api-client";
import { action, computed, observable, runInAction } from "mobx";
import { Moment } from "moment";
import { FocusedInputShape } from "react-dates";

export class ExportVisitsState {
  @observable
  public startDate: Moment | null = null;
  @observable
  public endDate: Moment | null = null;
  @observable
  public focusedInput: FocusedInputShape | null = null;
  @observable
  public isExporting: boolean = false;
  private arke: IArke;
  private siteId: string;
  constructor(arke: IArke, siteId: string) {
    this.arke = arke;
    this.siteId = siteId;
  }
  @action
  public onDatesChange = (arg: {
    startDate: Moment | null;
    endDate: Moment | null;
  }) => {
    this.startDate = arg.startDate;
    this.endDate = arg.endDate;
  };
  @action
  public onDateFocusChange = (arg: FocusedInputShape | null) => {
    this.focusedInput = arg;
  };
  public isOutsideRange = (date: Moment) => {
    let maxDays: number = 90;
    if (this.startDate !== null) {
      const dayDifference = date.diff(this.startDate, "days");
      if (dayDifference > maxDays - 1) {
        return true;
      }
      if (dayDifference < -(maxDays - 1)) {
        return true;
      }
    }
    if (this.endDate !== null) {
      const dayDifference = date.diff(this.endDate, "days");
      if (dayDifference > maxDays - 1) {
        return true;
      }
      if (dayDifference < -(maxDays - 1)) {
        return true;
      }
    }
    return false;
  };
  @computed
  public get canExport(): boolean {
    return (
      this.startDate !== null &&
      this.endDate !== null &&
      this.endDate.diff(this.startDate, "days") < 91 &&
      this.canCancel
    );
  }
  @computed
  public get canCancel(): boolean {
    return !this.isExporting;
  }
  @action
  public export = async (): Promise<string | null> => {
    if (this.startDate === null || this.endDate === null) {
      return null;
    }
    this.isExporting = true;

    try {
      const result = await this.arke.visits.export(
        this.siteId,
        this.startDate.format("YYYY-MM-DD"),
        this.endDate.format("YYYY-MM-DD")
      );
      return result.exportId;
    } catch (e) {
      return null;
    } finally {
      runInAction(() => {
        this.isExporting = false;
      });
    }
  };
}
