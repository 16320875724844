import { inject, observer, Provider } from "mobx-react";
import * as React from "react";
import { Route, RouteComponentProps, Switch } from "react-router-dom";

import AppState from "../../services/AppState";
import Dashboard from "../../views/dashboard/Dashboard";
import Setup from "../../views/sites/Setup";
import Wrapper from "../../views/theme/Wrapper";
import VisitsRouter from "../../views/visits/VisitsRouter";
import { AccessSettings } from "../../views/sites/settings/accesssettings/AccessSettings";
import { GeneralSettings } from "../../views/sites/settings/general/GeneralSettings";
import { MessagesSettings } from "../../views/sites/settings/messages/MessagesSettings";
import { NotificationSettings } from "../../views/sites/settings/notifications/NotificationSettings";
import { HostsSettings } from "../../views/sites/settings/hosts/HostsSettings";
import { UsersRouter } from "../../views/sites/settings/users/UsersRouter";
import HostsRouter from "../../views/hosts/HostsRouter";
import { MessagingRouter } from "../../views/conversations/MessagingRouter";
import AdditionalFieldsRouter from "../../views/sites/settings/additionalfields/AdditionalFieldsRouter";
import WatchlistRouter from "../../views/watchlist/WatchlistRouter";
import ScreeningRouter from "../../views/sites/settings/screening/ScreeningRouter";

interface IProps {
  appState: AppState;
}

@inject("appState")
@observer
export default class SiteRouter extends React.Component<
  IProps & RouteComponentProps<{ siteId: string }>,
  {}
> {
  public componentDidMount() {
    this.props.appState.setSite(this.props.match.params.siteId);
    if (this.props.appState.site === null) {
      this.props.appState.loadSite(this.props.match.params.siteId);
    }
  }
  public render() {
    if (this.props.appState.site === null) {
      return null;
    }
    console.debug(
      `Rendering site router for site ${
        (this.props as any).match.params.siteId
      }`
    );
    return (
      <Provider itemContainer={this.props.appState.itemContainer}>
        <Wrapper
          location={this.props.location}
          authState={this.props.appState.authState}
          appState={this.props.appState}
        >
          <Switch key={this.props.appState.site.siteId}>
            <Route
              path={this.props.match.path + "/dashboard"}
              component={Dashboard}
            />
            <Route
              path={this.props.match.path + "/settings/additionalfields"}
              component={AdditionalFieldsRouter}
            />
            <Route
              path={this.props.match.path + "/settings/general"}
              component={GeneralSettings}
            />
            <Route
              path={this.props.match.path + "/settings/screening"}
              component={ScreeningRouter}
            />
            <Route
              path={this.props.match.path + "/settings/users"}
              component={UsersRouter}
            />
            <Route
              path={this.props.match.path + "/settings/messages"}
              component={MessagesSettings}
            />
            <Route
              path={this.props.match.path + "/settings/notifications"}
              component={NotificationSettings}
            />
            <Route
              path={this.props.match.path + "/settings/hosts"}
              component={HostsSettings}
            />
            <Route
              path={this.props.match.path + "/settings/access"}
              component={AccessSettings}
            />
            <Route
              path={this.props.match.path + "/watchlist"}
              component={WatchlistRouter}
            />
            <Route
              path={this.props.match.path + "/visits"}
              component={VisitsRouter}
            />
            <Route
              path={this.props.match.path + "/messaging"}
              component={MessagingRouter}
            />
            <Route
              path={this.props.match.path + "/hosts"}
              component={HostsRouter}
            />
            <Route path={this.props.match.path + "/setup"} component={Setup} />
          </Switch>
        </Wrapper>
      </Provider>
    );
  }
}
