import { Provider } from "mobx-react";
import * as React from "react";
import { Suspense } from "react";
import "react-dates/initialize";
import "react-dates/lib/css/_datepicker.css";
import { I18nextProvider, useTranslation } from "react-i18next";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "draft-js/dist/Draft.css";
import ErrorHandler from ".//services/routing/ErrorHandler";
import SplashScreen from ".//services/splashscreen/SplashScreen";
import "./App.css";

import MainRouter from "./services/routing/MainRouter";
import { AppStateProvider, useAppState } from "./state/State";
function TranslationContainer() {
  const { i18n } = useTranslation();
  const state = useAppState();

  return (
    <I18nextProvider i18n={i18n}>
      <Provider authState={state.authState} appState={state} arke={state.arke}>
        <div className="App">
          <ErrorHandler>
            <ToastContainer autoClose={5000} />
            <SplashScreen>
              <MainRouter />
            </SplashScreen>
          </ErrorHandler>
        </div>
      </Provider>
    </I18nextProvider>
  );
}

function AppStateContainer() {
  return (
    <AppStateProvider>
      <TranslationContainer />
    </AppStateProvider>
  );
}

class App extends React.Component<{}, {}> {
  public render() {
    return (
      <Suspense fallback={<div>Loading</div>}>
        <AppStateContainer />
      </Suspense>
    );
  }
}

export default App;
