import { IArkeClient } from "../Client/IArkeClient";
import { AddOrUpdateDeviceRequestV1 } from "../Entities";

interface IDeviceResourceGroup {
  register(
    installationId: string,
    request: AddOrUpdateDeviceRequestV1
  ): Promise<void>;
  unregister(installationId: string): Promise<void>;
}

class DeviceResourceGroup implements IDeviceResourceGroup {
  private Client: IArkeClient;
  constructor(client: IArkeClient) {
    this.Client = client;
  }
  public register(
    installationId: string,
    request: AddOrUpdateDeviceRequestV1
  ): Promise<void> {
    return this.Client.performPut(`/v1/me/devices/${installationId}`, request);
  }
  public unregister(installationId: string): Promise<void> {
    return this.Client.performDelete(`/v1/me/devices/${installationId}`);
  }
}

export { IDeviceResourceGroup, DeviceResourceGroup };
