import { AppUpdateMessageV1 } from "../Entities";
import { IArkeClient } from "../Client/IArkeClient";

interface IAppResourceGroup {
  findUpdateMessage(
    platform: string,
    version: string
  ): Promise<AppUpdateMessageV1 | null>;
}

class AppResourceGroup implements IAppResourceGroup {
  private Client: IArkeClient;
  constructor(client: IArkeClient) {
    this.Client = client;
  }
  public async findUpdateMessage(
    platform: string,
    version: string
  ): Promise<AppUpdateMessageV1 | null> {
    const result = await this.Client.performGet<AppUpdateMessageV1 | null>(
      `/v1/app/update/${platform}/${version}`
    );
    return result;
  }
}

export { IAppResourceGroup, AppResourceGroup };
