import { IArke } from "@intreba/arke-api-client";
import { inject, observer, Provider } from "mobx-react";
import * as React from "react";
import { Route, RouteComponentProps, Switch } from "react-router-dom";
import HostsStore from "./HostsStore";
import { WithTranslation } from "react-i18next";
import ViewHosts from "./ViewHosts";
import { HostDetailPage } from "./HostDetails";

interface IProps extends WithTranslation {
  arke: IArke;
}

@inject("arke")
@observer
export default class HostsRouter extends React.Component<
  IProps & RouteComponentProps<{ siteId: string }>,
  {}
> {
  private hostsStore: HostsStore;
  public constructor(props: any) {
    super(props);
    this.hostsStore = new HostsStore(
      this.props.arke,
      this.props.match.params.siteId
    );
  }
  public render() {
    return (
      <Provider hostsStore={this.hostsStore}>
        <Switch>
          <Route
            exact={true}
            path={this.props.match.path}
            component={ViewHosts}
          />
          <Route
            path={`${this.props.match.path}/:hostId`}
            exact={true}
            component={HostDetailPage}
          />
        </Switch>
      </Provider>
    );
  }
}
