import i18n from "i18next";
import { observer } from "mobx-react";
import * as React from "react";
import { clientName } from "../../services/ClientName";
import { StatusUpdateResponseV1 } from "./StatusUpdateResponseV1";
let moment = require("moment");

const StatusUpdateTimelineItem = (props: {
  clientId: string;
  update: StatusUpdateResponseV1;
  t: i18n.TFunction;
}) => {
  let statusTranslation = props.t(props.update.status.replace(/\./g, ""));
  if (statusTranslation === "") {
    statusTranslation = props.update.status.replace(/\./g, " ");
  }
  statusTranslation = statusTranslation.replace(
    "{#client}",
    clientName(props.clientId, props.t).toLowerCase()
  );
  statusTranslation = statusTranslation.replace(
    "{#content}",
    props.update.content
  );
  return (
    <li style={{ color: "#f0682c" }}>
      <span>{statusTranslation}</span>
      <div className="float-right time">
        {moment
          .utc(props.update.dateTimeUtc)
          .local()
          .format("LT l")}
      </div>
    </li>
  );
};

const StatusUpdateTimeline = observer(
  (props: {
    statusUpdates: StatusUpdateResponseV1[];
    t: i18n.TFunction;
    clientId: string;
  }) => {
    return (
      <>
        <h3>{props.t("timeline")}</h3>
        <ul className="timeline">
          {props.statusUpdates.map(s => (
            <StatusUpdateTimelineItem
              key={s.eventId}
              update={s}
              t={props.t}
              clientId={props.clientId}
            />
          ))}
        </ul>
      </>
    );
  }
);

export { StatusUpdateTimeline };
