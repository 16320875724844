import { IArke } from "@intreba/arke-api-client";
import { action, computed, observable, runInAction } from "mobx";
import { User, UserManager, UserManagerSettings } from "oidc-client";
import * as Oidc from "oidc-client";

export default class AuthState {
  @observable
  public user: User | undefined = undefined;
  @observable
  public error: boolean = false;
  @observable
  public userManager: UserManager;
  @observable
  private loaded: boolean = false;
  private arke: IArke;
  constructor(settings: UserManagerSettings, arke: IArke) {
    this.userManager = new UserManager(settings);
    this.userManager.clearStaleState();
    Oidc.Log.logger = console;
    this.arke = arke;
  }
  @computed
  public get hasUser(): boolean {
    return this.user !== undefined;
  }
  @computed
  get finishedLoading(): boolean {
    return this.loaded;
  }
  @computed
  public get userName(): string {
    return this.user === undefined ? "unknown" : this.user.profile.name;
  }
  @computed
  public get email(): string {
    return this.user === undefined
      ? "unknown"
      : this.user.profile.preferred_username;
  }
  @action
  public async signin() {
    try {
      console.debug("Signing in with redirect");

      await this.userManager.signinRedirect();
      console.debug("Signed in with redirect");
    } catch (e) {
      console.error(e);
      runInAction(() => {
        this.error = true;
      });
    }
  }

  @action
  public async signinSilent() {
    try {
      let user = await this.userManager.getUser();
      if (user === null || user.expired) {
        console.debug("User expired, signing in silently");
        user = await this.userManager.signinSilent();
      }

      this.handleSigningSuccess(user);
    } catch (e) {
      const error: Error = e;
      if (error.message.includes(this.inFutureMessage)) {
        runInAction(() => {
          this.error = true;
        });
      } else {
        console.debug(
          "Signing in silently failed. Signing in through direct method."
        );

        await this.signin();
      }
    }
  }

  private inFutureMessage = "iat is in the future";

  @action
  public async handleSigninCallback() {
    try {
      const user = await this.userManager.signinRedirectCallback();
      this.userManager.storeUser(user);
      this.handleSigningSuccess(user);
    } catch (e) {
      const error: Error = e;
      if (error.message.includes(this.inFutureMessage)) {
        runInAction(() => {
          this.error = true;
        });
      } else {
        console.debug(
          "Signing in silently failed. Signing in through direct method."
        );

        this.signin();
      }
    }
  }

  @action
  public handleSigningSuccess(user: User) {
    console.debug("Signin success");
    this.user = user;
    this.userManager.startSilentRenew();
    this.userManager.events.addUserLoaded(() => console.debug("Loaded a user"));
    this.arke.setAccessToken(this.user.access_token);
    return;
  }

  @action
  public signout() {
    this.userManager.signoutRedirect();
  }
}
