import {
  CountryResponseV1,
  CultureResponseV1,
  IArke,
  Net2TelephoneField,
  TimeZoneResponseV1
} from "@intreba/arke-api-client";
import i18n from "i18next";
import {
  action,
  computed,
  IObservableArray,
  observable,
  runInAction
} from "mobx";
import { inject, observer } from "mobx-react";
import * as React from "react";
import { withTranslation, WithTranslation } from "react-i18next";
import { RouteComponentProps } from "react-router-dom";
import { toast } from "react-toastify";
import { Button, Form, FormGroup, FormText, Input, Label } from "reactstrap";
import { Spinner } from "../../components";
import AppState from "../../services/AppState";
import history from "../../services/history";
class NewSiteState {
  @observable
  public name: string = "";
  @observable
  public timeZoneId: string = "";
  @observable
  public cultureId: string = "";
  @observable
  public countryCode: string = "";
  @observable
  public telephoneField: Net2TelephoneField = "telephone";
  @computed
  public get disabled() {
    return this.loading;
  }

  @observable
  public loading: boolean = false;

  @observable
  public timeZones: IObservableArray<TimeZoneResponseV1> = observable([]);
  @observable
  public cultures: IObservableArray<CultureResponseV1> = observable([]);
  @observable
  public countries: IObservableArray<CountryResponseV1> = observable([]);
  private arke: IArke;
  private t: i18n.TFunction;
  private accountId: string;
  private appState: AppState;
  public constructor(
    accountId: string,
    arke: IArke,
    t: i18n.TFunction,
    appState: AppState
  ) {
    this.arke = arke;
    this.accountId = accountId;
    this.t = t;
    this.appState = appState;
  }
  @action
  public onNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.name = event.target.value;
  };
  @action
  public changeTimeZone = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.timeZoneId = event.target.value;
  };
  @action
  public changeCultureId = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.cultureId = event.target.value;
  };
  @action
  public changeCountry = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.countryCode = event.target.value;
  };
  @action
  public changeTelephoneField = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const net2Field = event.target.value as Net2TelephoneField;
    this.telephoneField = net2Field;
  };

  @action
  public onSubmit = async (event: React.FormEvent) => {
    this.loading = true;
    event.preventDefault();

    try {
      const siteId = await this.arke.sites.add(this.accountId, {
        countryCode: this.countryCode,
        cultureId: this.cultureId,
        name: this.name,
        net2PhoneField: this.telephoneField,
        timeZoneId: this.timeZoneId
      });
      await this.appState.loadSites();
      await history.push(`/sites/${siteId}/setup`);
    } catch (e) {
      toast.error(this.t("errorCreating"));
    } finally {
      runInAction(() => (this.loading = false));
    }
  };

  @action
  public async retrieveItems() {
    this.loading = true;
    if (this.cultures.length === 0) {
      const cultures = (await this.arke.system.listCultures()) as CultureResponseV1[];
      runInAction(() => {
        this.cultures.clear();
        cultures.forEach(c => this.cultures.push(c));
        try {
          const locale = new Intl.DateTimeFormat().resolvedOptions().locale;
          if (this.cultures.filter(c => locale === c.id)) {
            this.cultureId = locale;
          }
        } catch {
          // ignore
        }
      });
    }
    if (this.timeZones.length === 0) {
      const timezones = (await this.arke.system.listTimezones()) as TimeZoneResponseV1[];
      runInAction(() => {
        this.timeZones.clear();
        timezones.forEach(t => this.timeZones.push(t));
      });
    }
    if (this.countries.length === 0) {
      const countries = (await this.arke.system.listCountries()) as CountryResponseV1[];
      runInAction(() => {
        this.countries.clear();
        countries.forEach(c => this.countries.push(c));
      });
    }
    runInAction(() => {
      this.loading = false;
    });
  }
}

interface InjectedProps extends IProps {
  arke: IArke;
  appState: AppState;
}

interface IProps
  extends WithTranslation,
    RouteComponentProps<{ accountId: string }> {}

@inject("arke", "appState")
@observer
class NewSite extends React.Component<IProps, {}> {
  public get injectedprops() {
    return this.props as InjectedProps;
  }
  private newState: NewSiteState;
  public constructor(props: any) {
    super(props);
    this.newState = new NewSiteState(
      this.props.match.params.accountId,
      this.injectedprops.arke,
      this.injectedprops.t,
      this.injectedprops.appState
    );
  }
  public async componentDidMount() {
    await this.newState.retrieveItems();
  }
  public render() {
    const t = this.props.t;
    return (
      <Form style={{ marginTop: "1rem" }} onSubmit={this.newState.onSubmit}>
        <h1>{t("title")}</h1>
        <div className="text-left">
          <FormGroup row={true}>
            <Label for="siteName">{t("name")}</Label>
            <Input
              type="text"
              name="siteName"
              id="siteName"
              disabled={this.newState.disabled}
              value={this.newState.name}
              required={true}
              onChange={this.newState.onNameChange}
            />
            <FormText color="muted">{t("nameHelp")}</FormText>
          </FormGroup>
          <FormGroup row={true}>
            <Label for="countryCode">
              {t("sitesettingsgeneral:countryLabel")}
            </Label>
            <Input
              disabled={this.newState.disabled}
              type="select"
              name="countryCode"
              id="countryCode"
              required={true}
              value={this.newState.countryCode}
              onChange={this.newState.changeCountry}
            >
              <option value="" disabled={true} />
              {this.newState.countries.map(country => (
                <option value={country.alpha2} key={country.alpha2}>
                  {country.name}
                </option>
              ))}
            </Input>
            <FormText color="muted">
              {t("sitesettingsgeneral:countryHelp")}
            </FormText>
          </FormGroup>
          <FormGroup row={true}>
            <Label for="timeZoneId">
              {t("sitesettingsgeneral:timeZoneLabel")}
            </Label>
            <Input
              type="select"
              name="timeZoneId"
              id="timeZoneId"
              required={true}
              value={this.newState.timeZoneId}
              // tslint:disable-next-line:jsx-no-lambda
              onChange={this.newState.changeTimeZone}
              disabled={this.newState.disabled}
            >
              <option value="" disabled={true} />
              {this.newState.timeZones.map(timeZone => (
                <option value={timeZone.id} key={timeZone.id}>
                  {timeZone.name}
                </option>
              ))}
            </Input>
            <FormText color="muted">
              {t("sitesettingsgeneral:timeZoneHelp")}
            </FormText>
          </FormGroup>

          <FormGroup row={true}>
            <Label for="cultureId">
              {t("sitesettingsgeneral:cultureLabel")}
            </Label>

            <Input
              type="select"
              name="cultureId"
              id="cultureId"
              required={true}
              value={this.newState.cultureId}
              onChange={this.newState.changeCultureId}
              disabled={this.newState.disabled}
            >
              <option value="" disabled={true} />
              {this.newState.cultures.map(c => (
                <option value={c.id} key={c.id}>
                  {c.name}
                </option>
              ))}
            </Input>
            <FormText color="muted">
              {t("sitesettingsgeneral:cultureHelp")}
            </FormText>
          </FormGroup>
          <FormGroup row={true}>
            <Label for="phoneField">
              {t("sitesettingshosts:phoneFieldLabel")}
            </Label>
            <Input
              disabled={this.newState.disabled}
              type="select"
              name="phoneField"
              id="phoneField"
              value={this.newState.telephoneField}
              onChange={this.newState.changeTelephoneField}
            >
              <option value="telephone">
                {t("sitesettingshosts:phoneFieldTelephone")}
              </option>
              <option value="fax">
                {t("sitesettingshosts:phoneFieldFax")}
              </option>
              <option value="field8">
                {t("sitesettingshosts:phoneFieldField8")}
              </option>
            </Input>
            <FormText color="muted">
              {t("sitesettingshosts:phoneFieldHelp")}
            </FormText>
          </FormGroup>
          <FormGroup row={true}>
            <Button
              color="primary"
              block={true}
              disabled={this.newState.disabled}
            >
              {t("submit")}
            </Button>
          </FormGroup>
          {this.newState.loading && <Spinner />}
        </div>
      </Form>
    );
  }
}

export default withTranslation("newSite")(NewSite);
