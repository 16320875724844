import { inject, observer } from "mobx-react";
import * as React from "react";
import { withTranslation, WithTranslation } from "react-i18next";
import {
  SitePermissions,
  userHasSitePermission,
  SiteResponseV1,
  ConversationStatus,
} from "@intreba/arke-api-client";
import {
  Col,
  Row,
  Button,
  Dropdown,
  DropdownMenu,
  DropdownItem,
  DropdownToggle,
} from "reactstrap";
import PaginationView from "../../services/pagination/PaginationView";
import { Spinner } from "../../components";
import { RouteComponentProps } from "react-router";
import ConversationStore, { ConversationContent } from "./ConversationStore";
import { MessageOverviewRow } from "./MessageOverviewRow";
import ShowWithPermission from "../../components/user/ShowWithPermission";
import { ChatContent } from "./ChatComponents";
import { EditorState } from "draft-js";
import { EnhancedSearch } from "../../components/EnhancedSearch";
import { RefreshCcw } from "react-feather";

interface InjectedProps extends IProps {
  conversationStore: ConversationStore;
  itemContainer: { site: SiteResponseV1 };
}

interface IProps extends WithTranslation {}

@inject("conversationStore", "itemContainer")
@observer
class ConversationsOverview extends React.Component<
  InjectedProps &
    RouteComponentProps<{ siteId: string; conversationId: string }>,
  {
    showStatusDropdownFilter: boolean;
    mainChatVisible: boolean;
  }
> {
  private get injectedprops() {
    return this.props as InjectedProps;
  }

  public constructor(props: any) {
    super(props);
    this.state = {
      showStatusDropdownFilter: false,
      mainChatVisible: false,
    };
  }

  private onConversationSelect = (conversationId: string) => {
    this.setState({ mainChatVisible: true });
    this.injectedprops.conversationStore.selectConversation(conversationId);
  };

  public setMainChatVisibleFalse = () => {
    this.setState({ mainChatVisible: false });
  };

  private extractKey = (item: ConversationContent) => {
    return item.conversationId;
  };
  private renderItem = ({ item }: { item: ConversationContent }) => {
    return (
      <MessageOverviewRow
        item={item}
        url={this.props.conversationStore.getUrl()}
        onSelect={this.onConversationSelect}
      />
    );
  };

  public componentDidMount() {
    this.injectedprops.conversationStore.setConversationId(
      this.props.match.params.conversationId
    );
    this.injectedprops.conversationStore.goToPage(1);
  }

  public async getNextMessages() {
    await this.injectedprops.conversationStore.getNextMessages();
  }

  public async updateStatus(status: ConversationStatus) {
    await this.injectedprops.conversationStore.updateConversationStatus(status);
  }

  public async sendConversationMessage() {
    let newConversation = await this.injectedprops.conversationStore.sendConversationMessage();
    if (newConversation) {
      window.location.replace(
        `${this.injectedprops.conversationStore.getUrl()}/conversations/${
          newConversation.conversationId
        }`
      );
    }
  }

  render() {
    const t = this.injectedprops.t!;
    let hasPermission = userHasSitePermission(
      this.injectedprops.itemContainer.site.permissions,
      SitePermissions.broadcastcreate
    );
    return (
      <>
        <div className="layout">
          <div className="sidebar" style={{ height: "90vh" }}>
            <div className="tab-content h-100" role="tablist">
              <div
                className="tab-pane fade h-100 show active"
                id="tab-content-dialogs"
                role="tabpanel"
              >
                <div className="d-flex flex-column h-100">
                  <div className="hide-scrollbar">
                    <div className="container-fluid py-6">
                      <Row
                        style={{
                          padding: "0.3rem",
                        }}
                      >
                        <Col
                          style={{
                            margin: "auto",
                          }}
                        >
                          <h2 className="font-bold mb-6"> {t("title")}</h2>
                        </Col>
                        <Col
                          style={{
                            margin: "auto",
                          }}
                        >
                          {!hasPermission && (
                            <Button
                              outline={true}
                              color="secondary"
                              block={true}
                              onClick={() => {
                                this.props.history.replace(
                                  `${this.injectedprops.conversationStore.getNewConversationUrl()}`
                                );
                              }}
                            >
                              {t("newMessage")}
                            </Button>
                          )}
                        </Col>
                      </Row>
                      <ShowWithPermission
                        permission={SitePermissions.broadcastcreate}
                      >
                        <EnhancedSearch
                          type="text"
                          value={this.injectedprops.conversationStore.search}
                          onChange={
                            this.injectedprops.conversationStore.onSearchChange
                          }
                          placeholder={t("searchPlaceholder")}
                          disabled={
                            this.injectedprops.conversationStore
                              .isGettingItems ||
                            this.injectedprops.conversationStore.isWorking
                          }
                          resetSearch={
                            this.injectedprops.conversationStore.resetSearch
                          }
                          onSubmit={(page: number) =>
                            this.injectedprops.conversationStore.goToPage(page)
                          }
                        />
                      </ShowWithPermission>
                      <Row>
                        <Col
                          sm={10}
                          style={{ paddingRight: 0 }}
                          className="filter-dropdown"
                        >
                          <Dropdown
                            style={{ padding: "0.3rem" }}
                            isOpen={this.state.showStatusDropdownFilter}
                            toggle={() =>
                              this.setState({
                                showStatusDropdownFilter: !this.state
                                  .showStatusDropdownFilter,
                              })
                            }
                          >
                            <DropdownToggle
                              disabled={
                                this.injectedprops.conversationStore
                                  .isWorking ||
                                this.injectedprops.conversationStore
                                  .isGettingItems
                              }
                              caret
                              style={{ width: "100%", marginTop: 5 }}
                            >
                              {t("show") + ": "}
                              {
                                ConversationStatus[
                                  this.injectedprops.conversationStore
                                    .statusFilter
                                ]
                              }
                            </DropdownToggle>
                            <DropdownMenu style={{ width: "100%" }}>
                              <DropdownItem
                                key={ConversationStatus.Open}
                                style={{ width: "100%", textAlign: "center" }}
                                onClick={() => {
                                  this.injectedprops.conversationStore.setStatusFilter(
                                    ConversationStatus.Open
                                  );
                                  this.injectedprops.conversationStore.goToPage(
                                    1
                                  );
                                }}
                              >
                                {t("open")}
                              </DropdownItem>
                              <DropdownItem
                                style={{ width: "100%", textAlign: "center" }}
                                key={ConversationStatus.Closed}
                                onClick={() => {
                                  this.injectedprops.conversationStore.setStatusFilter(
                                    ConversationStatus.Closed
                                  );
                                  this.injectedprops.conversationStore.goToPage(
                                    1
                                  );
                                }}
                              >
                                {t("closed")}
                              </DropdownItem>
                              <DropdownItem
                                key={ConversationStatus.All}
                                style={{ width: "100%", textAlign: "center" }}
                                onClick={() => {
                                  this.injectedprops.conversationStore.setStatusFilter(
                                    ConversationStatus.All
                                  );
                                  this.injectedprops.conversationStore.goToPage(
                                    1
                                  );
                                }}
                              >
                                {t("all")}
                              </DropdownItem>
                            </DropdownMenu>
                          </Dropdown>
                        </Col>
                        <Col
                          sm={2}
                          className="refresh-button"
                          style={{ paddingLeft: 0 }}
                        >
                          <div
                            className="dropdown"
                            style={{ padding: "0.3rem" }}
                            onClick={() => {
                              this.injectedprops.conversationStore.resetSearch();
                            }}
                          >
                            <Button
                              disabled={
                                this.injectedprops.conversationStore
                                  .isWorking ||
                                this.injectedprops.conversationStore
                                  .isGettingItems
                              }
                              style={{ width: "100%", marginTop: 5 }}
                            >
                              <RefreshCcw size={20} />
                            </Button>
                          </div>
                        </Col>
                      </Row>
                      <nav className="nav d-block list-discussions-js mb-n6">
                        <PaginationView
                          renderItem={this.renderItem}
                          keyExtractor={this.extractKey}
                          store={this.injectedprops.conversationStore}
                          columns={[]}
                        />
                      </nav>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {this.injectedprops.conversationStore.selectedConversation && (
            <ChatContent
              mainChatVisible={this.state.mainChatVisible}
              subject={
                this.injectedprops.conversationStore.selectedConversation
                  .subject
              }
              isStaff={hasPermission}
              status={
                this.injectedprops.conversationStore.selectedConversation.status
              }
              participants={
                this.injectedprops.conversationStore.selectedConversation
                  .participants
              }
              updateStatus={(status: ConversationStatus) =>
                this.updateStatus(status)
              }
              closeChat={() => this.setMainChatVisibleFalse()}
              selectedConversation={
                this.injectedprops.conversationStore.selectedConversation
              }
              pageNumber={
                this.injectedprops.conversationStore
                  .conversationMessagePageToBeRetrieved
              }
              isWorking={this.injectedprops.conversationStore.isWorking}
              messageMarkdowncontent={
                this.injectedprops.conversationStore.messageMarkdowncontent
              }
              setMessageMarkdowncontent={(state: EditorState) =>
                this.injectedprops.conversationStore.setMessageMarkdowncontent(
                  state
                )
              }
              sendMessage={() => this.sendConversationMessage()}
              getNextMessages={() => this.getNextMessages()}
              selectedConversationUrl={this.injectedprops.conversationStore.getSelectedConversationUrl()}
            />
          )}
          <div style={{ position: "absolute", zIndex: 99 }}>
            {this.injectedprops.conversationStore.isGettingItems && <Spinner />}
          </div>
        </div>
      </>
    );
  }
}

export default withTranslation("conversations")(ConversationsOverview);
