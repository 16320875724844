import { IArke } from "@intreba/arke-api-client";
import { inject, observer, Provider } from "mobx-react";
import * as React from "react";
import { Route, RouteComponentProps, Switch } from "react-router-dom";
import { ViewUsers } from "./ViewUsers";
import { UsersStore } from "./UsersStore";
import { UserDetailPage } from "./UserDetailPage";
import { AddUser } from "./AddUser";

interface IProps {
  arke: IArke;
}

@inject("arke")
@observer
class UsersRouter extends React.Component<
  IProps & RouteComponentProps<{ siteId: string }>,
  {}
> {
  private usersStore: UsersStore;
  public constructor(props: any) {
    super(props);
    this.usersStore = new UsersStore(
      this.props.arke,
      this.props.match.params.siteId
    );
  }
  public render() {
    return (
      <Provider usersStore={this.usersStore}>
        <Switch>
          <Route
            exact={true}
            path={this.props.match.path}
            component={ViewUsers}
          />
          <Route
            path={`${this.props.match.path}/add`}
            exact={true}
            component={AddUser}
          />
          <Route
            path={`${this.props.match.path}/user`}
            exact={true}
            component={UserDetailPage}
          />
        </Switch>
      </Provider>
    );
  }
}

export { UsersRouter };
