import React from "react";
import { SiteResponseV1, IArke } from "@intreba/arke-api-client";
import { inject, observer, Provider } from "mobx-react";
import { RouteComponentProps, Switch, Route } from "react-router-dom";
import { AdditionalFieldsStore } from "./AdditionalFieldsStore";
import { ViewAdditionalFields } from "./ViewAdditionalFields";
import { AdditionalFieldDetails } from "./AdditionalFieldDetails";

interface IProps {
  arke: IArke;
  itemContainer: { site: SiteResponseV1 };
}

@inject("arke", "itemContainer")
@observer
export default class AdditionalFieldsRouter extends React.Component<
  IProps & RouteComponentProps<{ siteId: string }>,
  {}
> {
  private additionalFieldsStore = new AdditionalFieldsStore(
    this.props.arke,
    this.props.match.params.siteId
  );
  render() {
    return (
      <Provider additionalFieldsStore={this.additionalFieldsStore}>
        <Switch>
          <Route
            exact={true}
            path={this.props.match.path}
            component={ViewAdditionalFields}
          />
          <Route
            exact={true}
            path={`${this.props.match.path}/:additionalFieldId`}
            component={AdditionalFieldDetails}
          />
        </Switch>
      </Provider>
    );
  }
}
