import { SiteMenu } from "./SiteMenu";

import { AccountResponseV1, SiteResponseV1 } from "@intreba/arke-api-client";
import { Location } from "history";
import { inject, observer } from "mobx-react";
import * as React from "react";
import { LifeBuoy } from "react-feather";
import { useTranslation } from "react-i18next";
import { Nav, NavItem, NavLink } from "reactstrap";
import AppState from "../../services/AppState";
import { Menu } from "react-feather";
interface InjectedProps extends IProps {
  itemContainer: { site: SiteResponseV1; accounts: AccountResponseV1[] };
  appState: AppState;
}

interface IProps {
  location: Location;
}

const ExpandableMenu = (props: {
  site: SiteResponseV1;
  appState: AppState;
}) => {
  const { t } = useTranslation("menu");

  const [expanded, setExpanded] = React.useState<boolean>(false);
  let navClassName =
    "text-left SideMenu p-2 mt-3" + (expanded ? " expanded" : " compressed");
  return (
    <>
      <Nav
        vertical={true}
        className={navClassName}
        style={{ flex: "1 1 auto" }}
      >
        <Menu
          className="d-sm-block d-md-none"
          style={{ cursor: "pointer" }}
          onClick={(e) => {
            e.stopPropagation();
            setExpanded(!expanded);
          }}
        />
        <SiteMenu site={props.site} />
        <hr style={{ minWidth: "100%" }} className="m-0 mb-3" />
        <NavItem>
          <NavLink href="https://help.arke.io" target="_blank">
            <LifeBuoy />
            {t("support")}
          </NavLink>
        </NavItem>
      </Nav>
    </>
  );
};

@inject("appState", "itemContainer")
@observer
class SideMenu extends React.Component<IProps, {}> {
  private get injectedprops() {
    return this.props as InjectedProps;
  }

  public render() {
    return (
      <ExpandableMenu
        appState={this.injectedprops.appState}
        site={this.injectedprops.itemContainer.site}
      />
    );
  }
}

export default SideMenu;
