import * as React from "react";
import { Card, Col, Container, Row } from "reactstrap";

import { Location } from "history";
import SideMenu from "../../services/sidemenu/SideMenu";
import AuthState from "../../services/authentication/AuthState";
import { TopBar } from "./TopBar";
import AppState from "../../services/AppState";
interface IWrapperProps {
  location: Location;
  children: React.ReactNode;
  authState: AuthState;
  appState: AppState;
}

export default function Wrapper(props: IWrapperProps) {
  return (
    <Container fluid={true} style={{ height: "100%" }}>
      <TopBar authState={props.authState} appState={props.appState} />
      <Row style={{ height: "90vh" }} className="main-container">
        <Col
          md={2}
          lg={2}
          xl={2}
          style={{
            display: "flex",
            backgroundColor: "white",
            borderWidth: "0px 1px 0px 0px",
            borderStyle: "solid",
            borderColor: "#e3ebf6",
          }}
          className="m-0 p-2 pt-2 pl-1"
        >
          <SideMenu location={props.location} />
        </Col>
        <Col md={9} lg={10} xl={10} className="m-0 p-0">
          <div className="main-card">
            <Card className="m-4">{props.children}</Card>
          </div>
        </Col>
      </Row>
    </Container>
  );
}
