import { inject, observer } from "mobx-react";
import * as React from "react";
import { withTranslation, WithTranslation } from "react-i18next";

import {
  IArke,
  SiteResponseV1,
  VisitResponseV1,
  FullVisitResponse,
  SitePermissions,
} from "@intreba/arke-api-client";
import { Moment } from "moment";
import { SingleDatePicker } from "react-dates";
import { ChevronRight } from "react-feather";
import { ChevronLeft } from "react-feather";
import { RouteComponentProps } from "react-router";
import { Button, CardBody, CardHeader, Col, Row } from "reactstrap";
import PaginationView from "../../services/pagination/PaginationView";
import { Spinner } from "../../components";
import { ExportVisitsModal } from "./ExportVisitsModal";
import { VisitRow } from "./VisitRow";
import VisitsStore from "./VisitsStore";
import ShowWithPermission from "../../components/user/ShowWithPermission";
let moment = require("moment");

interface InjectedProps extends IProps {
  itemContainer: { site: SiteResponseV1 };
  arke: IArke;
  visitsStore: VisitsStore;
}

interface IProps extends WithTranslation {}

@inject("itemContainer", "arke", "visitsStore")
@observer
class ViewVisits extends React.Component<
  InjectedProps & RouteComponentProps<{}>,
  {
    dateSelectorOpen: boolean;
  }
> {
  private get injectedprops() {
    return this.props as InjectedProps;
  }
  public constructor(props: any) {
    super(props);
    this.state = {
      dateSelectorOpen: false,
    };
  }
  public extractKey(item: VisitResponseV1) {
    return item.visitId;
  }

  private onVisitSelect = (visit: VisitResponseV1) => {
    this.props.visitsStore.selectVisit(visit);
    this.props.history.push(this.props.visitsStore.currentVisitUrl);
  };

  public renderItem = ({ item }: { item: FullVisitResponse }) => {
    return (
      <VisitRow
        visit={item}
        t={this.injectedprops.t}
        onSelect={this.onVisitSelect}
        showStartTimeOnly={true}
      />
    );
  };
  public componentDidMount() {
    const date = new URLSearchParams(this.props.location.search).get("date");
    if (date !== null) {
      const dateMoment = moment(date);
      this.props.visitsStore.setDate(dateMoment, false);
    }
    this.props.visitsStore.goToPage(1);
  }

  private toggleDateSelector = () => {
    this.setState({ dateSelectorOpen: !this.state.dateSelectorOpen });
  };

  private isOutsideRange() {
    return false;
  }

  private setDate = (date: Moment | null) => {
    this.props.visitsStore.setDate(date, true);
  };

  public render() {
    const t = this.injectedprops.t!;
    return (
      <>
        <ExportVisitsModal
          isOpen={this.props.visitsStore.exportModalOpen}
          toggle={this.props.visitsStore.toggleExportModal}
          arke={this.injectedprops.arke}
          siteId={this.injectedprops.itemContainer.site.siteId}
          t={this.props.t}
        />

        <CardHeader tag="div">
          <Row>
            <Col md={6}>
              <h2>
                {!this.props.visitsStore.isGettingItems &&
                  this.props.visitsStore.totalItems}{" "}
                {t("title")}
              </h2>
            </Col>
          </Row>
        </CardHeader>
        <CardBody>
          <Row style={{ marginBottom: "1rem" }}>
            <Col md={6} sm={6} xs={8}>
              <Button
                outline={true}
                color="primary"
                style={{ borderRight: "none" }}
                onClick={this.props.visitsStore.previousDay}
                disabled={this.props.visitsStore.isGettingItems}
              >
                <ChevronLeft height={18} width={18} />
              </Button>
              <SingleDatePicker
                key={this.props.visitsStore.currentDateFormatted}
                id="visitViewDate"
                disabled={this.props.visitsStore.isGettingItems}
                date={this.props.visitsStore.currentDate}
                focused={this.state.dateSelectorOpen}
                onDateChange={this.setDate}
                onFocusChange={this.toggleDateSelector}
                hideKeyboardShortcutsPanel={true}
                noBorder={true}
                isOutsideRange={this.isOutsideRange}
              />
              <Button
                outline={true}
                color="primary"
                style={{ borderLeft: "none" }}
                onClick={this.props.visitsStore.nextDay}
                disabled={this.props.visitsStore.isGettingItems}
              >
                <ChevronRight height={18} width={18} />
              </Button>
              {!this.props.visitsStore.isToday && (
                <Button
                  color={"link"}
                  onClick={() => this.props.visitsStore.setDate(null, true)}
                >
                  {t("toToday")}
                </Button>
              )}
            </Col>
            <Col md={{ size: 2, offset: 4 }} sm={{ size: 2, offset: 4 }} xs={4}>
              <ShowWithPermission
                permission={SitePermissions.visitexportscreate}
              >
                <Button
                  outline={true}
                  color="secondary"
                  style={{ float: "right" }}
                  disabled={this.props.visitsStore.isGettingItems}
                  onClick={this.props.visitsStore.toggleExportModal}
                >
                  Export
                </Button>
              </ShowWithPermission>
            </Col>
          </Row>

          <Row>
            <Col>
              <PaginationView
                renderItem={this.renderItem}
                keyExtractor={this.extractKey}
                store={this.props.visitsStore}
                columns={[
                  t("common:host"),
                  t("common:visitor"),
                  t("common:status"),
                  t("common:createdVia"),
                  t("startAt"),
                  "",
                ]}
              />
            </Col>
          </Row>
          {this.props.visitsStore.isGettingItems && <Spinner />}
        </CardBody>
      </>
    );
  }
}

export default withTranslation("visits")(ViewVisits);
