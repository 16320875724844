// tslint:disable:object-literal-sort-keys
export default {
  splashscreen: {
    loggingIn: "We're logging you in",
    gatheringDetails: "We're getting your details",
    error:
      "Something went wrong while logging in. Make sure your computer's time is correct and try again or contact support@arke.io .",
    welcome: "Welcome to Arke",
  },
  newAccount: {
    hostButton: "People will be coming to visit me",
    accountButton: "My company would like to deploy Arke",
    chooseIntro:
      "Welcome to Arke! Let's get you setup, choose your option below:",
    hostIntro:
      "<p>If you haven't received a message from us that welcomes you to the system, that means you haven't been registered in the access system where you are.</p><p> Please speak with your local access administrator, this could be your estate manager or security administrator, for instance. </p> <p>If you're still having issues after doing this, you can contact us at support@arke.io .</p>",
    newAccountIntro:
      "<p>Thanks for your interest in Arke, we're looking forward to discussing things further.</p><p>Please fill in the below form so a member of our sales team can get in contact with you.</p>",
    newAccountCreated: "<p>Thanks, we'll be in touch soon!</p>",
    companyName: "Company name",
    units: "units",
    residents: "residents",
    visitorsPerMonth: "visitors per month",
    awaitingApproval:
      "We're processing the your account's approval. Contact sales@arke.io for more information.",
    termsAndConditions: "Terms and conditions",
    createButton: "Create account",
    acceptTermsAndConditions:
      "I agree to the <a href='https://arke.io/termsandconditions'>Terms and Conditions</a> and the <a href='https://arke.io/privacy'>Privacy policy</a>.",
  },
  newSite: {
    name: "Name of site",
    nameHelp: "The name will be used in notifications to hosts and visitors",
    title: "Add a new site",
    submit: "Add site",
    errorCreating: "Site could not be created, please try again",
  },
  dashboard: {
    title: "Dashboard",
    activeVisits: "Active visits",
    last30DaysVisits: "Visits last 30 days",
    noVisitsLast30Days: "There are no visits in the last 30 days.",
    hosts: "Hosts",
    last30DaysFailedVisits: "Failed visits last 30 days",
    failed: "Failed",
    visits: "Visits",
  },
  hosts: {
    title: "Hosts",
    searchPlaceholder: "Search for a name or a phone number",
    name: "Name",
    lastModified: "Last update",
    addHost: "Add host",
    addHostIntro:
      "Adding hosts manually means no host provisioning through the access control system is required. If you're unsure about this, make sure to contact your security provider for more information.",
    hostAddSuccess: "The host is being added",
    hostAddFail:
      "The host could not be added. Make sure to check the phone number format",
  },
  menu: {
    dashboard: "Dashboard",
    visits: "Visits",
    hosts: "Hosts",
    createvisit: "New visit",
    users: "Users",
    messaging: "Conversations",
    settings: "Settings",
    addNewSite: "Add new site",
    support: "Support",
    logOut: "Log out",
    broadcasts: "Community messages",
    conversations: "Conversations",
    manageAccount: "Manage account",
    watchlist: "Watchlist",
    tooManyAccounts:
      "You're active on multiple accounts. Contact support@arke.io for assistance creating a site.",
  },
  common: {
    back: "Back",
    connectionIssues:
      "The site {#sitename} seems to be having connection issues",
    downloadButton: "Download client",
    siteVersion:
      "The installed Arke client has version {#siteversion} while the latest version is {#version}. Make sure to update to use the latest features or speak to your security provider to install the latest version. ",
    siteSetup: "The site isn't setup yet.",
    siteSetupLinkText: "Finalize site setup.",
    siteInitialising: "Your site is setting up. Check back in a little bit.",
    save: "Save",
    savesuccess: "Save successful",
    clickAgainToConfirm: "Click again to confirm",
    savefail:
      "Sorry, we are unable to complete this request. Please refresh the page and try again. If this problem persists, please contact support@arke.io for help. Please include information on what caused this error message to appear.",
    errorToast:
      "Something went wrong. Try again or contact support@arke.io for help.",
    successToast: "Change was successful",
    confirmationToast: {
      title: "Warning",
      body:
        "Removing all permissions from a user will also remove the user. Proceed with  this action?",
    },
    replace: "replace",
    newFunctionality: {
      simultaneousVisits:
        "Arke can now limit the number of visits a host can have simultaneously.",
    },
    seconds: "Seconds",
    minutes: "Minutes",
    hours: "Hours",
    days: "Days",
    areYouSure: "Are you sure?",
    yes: "Yes",
    no: "No",
    cancel: "Cancel",
    add: "Add",
    trialExpiredAlert:
      "The trial has expired. Contact sales@arke.io for more information.",
    trialExpirationAlert: "The trial expires {#expiration}.",
    trialExpirationAlertAction: "Request site upgrade",
    paidRequestedAlert:
      "Your site upgrade is requested. Check back later or contact sales@arke.io .",
    unknown: "Unknown",
    visitor: "Visitor",
    status: "Status",
    host: "Host",
    email: "Email",
    notfound: "This couldn't be found. Are you sure this exists?",
    createdVia: "Created via",
    lastModified: "Last modified",
    phoneNumber: "Phone number",
    remove: "Remove",
    invalidMobilePhoneNumberInput:
      "The entered mobile phone number is invalid. Make sure to enter a valid number for your region or enter it in the international format.",
    PhoneNumber: "Phone number",
    LicensePlate: "License plate",
    All: "All",
  },
  messaging: {
    title: "Conversations",
    subject: "Subject",
    subjectPlaceholder: "Enter a subject...",
    subjectHelp:
      "Please keep the subject under 75 characters to allow us to send it to all residents. Not all characters are allowed.",
    backToMessaging: "Back to conversations",
    hostsWithoutEmail:
      "<span class=\"text-danger\">{HostCount} users do not have a registered email.</span> Contact <a href='mailto:support@arke.io'>support@arke.io</a> to receive a list of these users",
    sendToHosts: "Send message to {HostCount} hosts",
    sendNewBroadcast: "Send a broadcast message",
    sendNewMessage: "Send a personal message",
    testMessageSent: "Test message sent sucessfully",
    sendTestMessage: "Send test message",
    sendTestMessageHelp:
      "Press Enter to add email to recipient list. Click on an email to delete from the list.",
    sendTestMessageTo: "Insert emails to send a sample of the message to",
    send: "Send",
    broadcast: {
      title: "Send a message to all hosts on your estate",
      messageExplanation:
        "This feature allows you to send a message to all your Arke hosts. <br/> They will be notified via the email address they have registered their Arke account with.<br/> If a host does not have an Arke account, we cannot get in contact with them. We can provide you a list of hosts that do not have an email so you can ask them to sign up for an Arke account.",
      confirmMessageContent: "You're about to send a message to {Count} people",
      messageSent: "Message sent to {} hosts",
    },
    personal: {
      title: "Message host {}",

      messageExplanation:
        "This feature allows you to send a personal message to specific Arke users. <br/> They will be notified via the email address they have registered their Arke account with.<br/> If a user does not have an Arke account, we cannot get in contact with them. We can provide you a list of hostss that do not have an email so you can ask them to sign up for an Arke account.",
      confirmMessageContent: "You're about to send a message to {Count} people",
      messageSent: "Message successfully sent",
    },
    testMessageModal: {
      title: "Send test message",
      messageExplanation: "Insert emails to send a sample of the message to",
    },
  },
  conversations: {
    title: "Conversations",
    cancel: "Cancel",
    send: "Send",
    markAsClosed: "Mark as closed",
    markAsOpen: "Mark as Open",
    searchPlaceholder: "Search for a subject",
    startConversation: "Start conversation",
    conversationCreated: "Conversation created successfully",
    newMessage: "New message",
    management: "Management",
    back: "Back",
    open: "Open",
    closed: "Closed",
    all: "All",
    show: "Show",
    subjectPlaceholder: "Type your subject...",
  },
  broadcasts: {
    title: "Community messages",
    cancel: "Cancel",
    send: "Send",
    searchPlaceholder: "Search for a subject",
    sendBroadcastMessage: "New message",
  },
  sitesettings: {
    title: "Settings",
    general: "General",
    hosts: "Hosts",
    notifications: "Notifications",
    messages: "Messages",
    access: "Access",
    dailyVisitLimit: "Visit limit settings",
    users: "Users",
    screening: "Screening",
    additionalfields: "Additional fields",
    cannotEditAlert:
      "You don't have permission to edit these settings. Speak to your security provider to modify these settings.",
  },
  sitesettingsgeneral: {
    title: "General settings",
    syncLabel: "Synchronise site",
    syncButton: "Start new synchronisation",
    syncHelp:
      "Synchronizations are started automatically. Make sure not to trigger this too often as it will slow down actions for the site",
    syncFailed: "A synchronisation could not be started. Try again",
    syncSuccess: "A new synchronisation is started",
    nameLabel: "Name",
    nameHelp:
      "The name is used in notifications to hosts and visitors. Make sure it's easy to understand and recognizable.",
    cultureLabel: "Culture",
    cultureHelp:
      "Culture is used for formatting dates and times in notifications",
    countryLabel: "Country",
    countryHelp:
      "The country of the site is used for making sure phone numbers are recognized and hosts can text our phone numbers. This can only be changed by contacting support@arke.io .",
    timeZoneLabel: "Time zone",
    timeZoneHelp:
      "The time zone is used for making sure the dates and times are set correctly in notifications, statistics and for visit scheduling.",

    removalDaysLabel: "Retention days",
    removalDaysHelp:
      "We'll remove all data in the system for the site when they're older than this setting. Ensure this number is used in communication to your residents and visitors to ensure data protection compliance. A minimum of 1 day is required, and a maximum of 365 days is allowed.",
    emergencyPhoneNumberLabel: "Emergency phone number",
    emergencyPhoneNumberHelp:
      "Hosts can quick-dial this number from the app to reach out to on-duty staff quickly",
    representativeLogoLabel: "Representative logo",
    representativeLogoHelp:
      "Upload a 500x250 PNG file. The logo will be shown to staff using the platform",
    removeLogo:
      "Are you sure you want to remove the logo? This action is not reversible.",
    siteLogoLabel: "Site logo",
    siteLogoHelp:
      "Upload a 500x250 PNG file. The logo will be shown to your users and visitors",
  },
  sitesettingsscreening: {
    title: "Screening",
    screeningEnabled: "Screen visitors before handing out their access code",
    questions: "Questions",
    questionsHelp:
      "Add the questions you would like to ask visitors. You can add new and select questions in the [Additional fields] section.",
    chooseWhen:
      "Choose when the screening questions will be available to the visitor before the visit moment",
    days: "Days",
    hours: "Hours",
    minutes: "Minutes",
    areYouSure: "Are you sure you want to delete this question?",
    addANewQuestion: "Add a new question",
    questionName: "Name",
    selectAnswer: "Select answer",
    desiredAnswer: "Desired answer",
    noMoreAvailableFields:
      "There are no more additional fields available for selection. Add some more additional fields first.",
    order: "Order",
  },
  sitesettingsAdditionalFields: {
    title: "Additional fields",
    addField: "Add new additional field",
    User: "Users",
    Visitor: "Visitors",
    name: "Name",
    fieldType: "Type",
    required: "Required",
    default: "Default",
    description: "Description",
    delete: "Delete",
    edit: "Edit",
  },
  sitesettingsAdditionalFieldsDetails: {
    title: "Additional field details",
    name: "Name",
    label: "Label",
    fieldType: "Type",
    defaultValue: "Default value",
    required: "Required",
    requiredHelp: "This is a required field",
    YesNo: "Yes/No",
    Text: "Text",
    MultipleChoice: "Multiple choice",
    Date: "Date",
  },
  sitesettingsnotifications: {
    title: "Notification settings",
    statusEmailListHelp:
      "The status list will, in addition to all site managers, operators and administrators, get an email when the site goes online and offline. Seperate addresses using ;",
    statusEmailListLabel: "Email site status changes",
    sendWelcomeMessageShortLabel: "Send welcome message",
    sendWelcomeMessageLongLabel: "Send hosts a welcome message",
    sendWelcomeMessageHelp:
      "When a new host is provisioned we'll send them a welcome message",
    sendHostCalendarInviteShortLabel: "Send calendar invitations",
    sendHostCalendarInviteLongLabel: "Send hosts a calendar invitation",
    sendHostCalendarInviteHelp:
      "If a host has attached an Arke account to their phone number we will send them a calendar invite for their visit.",
    pushNotificationLongLabel: "Send visit status push notifications",

    emailNotificationLongLabel: "Send visit status emails",

    smsNotificationLongLabel: "Send visit status sms notifications",

    statusNotificationShortLabel: "Status notifications",
    statusNotificationHelp:
      "When the visitors enter or depart the site, notifications will be sent to the hosts. For example: 'Your visitor John Doe departed at 16:01'. On credit billing, message credits will be charged to you.",
  },
  sitesettingsmessages: {
    title: "Messages settings",
    invitationMessageLabel: "Invitation message",
    invitationMessageHelp:
      "This message will be used to send a PIN to a visitor. You can type your own text and select from variables to include dynamic data. On credit billing, message credits will be charged to you, contact support@arke.io to get specific billing details.",
    welcomeMessageLabel: "Welcome message",
    welcomeMessageHelp:
      "This message will be used to welcome newly provisioned hosts. You can type your own text and select from variables to include dynamic data. On credit billing, message credits will be charged to you, contact support@arke.io to get specific billing details.",
    welcomeMessageDisabledHelp:
      "Welcome message templates are disabled for your site. For more information contact support@arke.io .",
    variables: "Variables",
    variablesIntro: "You can use the following variables:",
    codeVariableKey: "$code",
    codeVariableDefinition: "The code that will be issued (required)",
    siteVariableKey: "$site",
    siteVariableDefinition: "The name of the site",
    expirationVariableKey: "$expiration",
    expirationVariableDefinition: "The date and time that the PIN will expire",
    startVariableKey: "$start",
    startVariableDefinition:
      "The start of the visit, especially handy for scheduled visits",
    weblinkVariableKey: "$weblink",
    weblinkVariableDefinition:
      "The link to the arke mobile website helping hosts download the right app",
  },
  sitesettingshosts: {
    title: "Host settings",
    phoneFieldLabel: "Telephone number field",
    phoneFieldHelp:
      "The field in Net2 used for provisioning hosts. This can only be changed when creating a site.",
    phoneFieldTelephone: "Telephone",
    phoneFieldFax: "Fax/Mobile 1",
    phoneFieldField8: "Mobile / Mobile 2 (Other details)",
    autoSyncNewDepartmentsShortLabel: "Automatic department syncing",
    autoSyncNewDepartmentsLongLabel: "Automatically sync new departments",
    autoSyncNewDepartmentsHelp:
      "New departments will automatically be used for host provisioning",
    syncedDepartmentsLabel: "Synced departments",
    syncedDepartmentsHelp:
      "Hosts from these departments will automatically be provisioned in Arke. If a new department isn't shown here yet, check back later.",
  },
  sitesettingsaccess: {
    title: "Access settings",
    useHostAccessLevelForVisitorShortLabel: "Use host access level",
    useHostAccessLevelForVisitorLongLabel: "Use the access level of the host",
    useHostAccessLevelForVisitorHelp:
      "To allow custom access on-site the access level of the host will be used. Hosts will need to be provisioned through Net2",
    pinLengthLabel: "Pin length",
    pinLengthHelp:
      "The length of the PINs issued. Will be set automatically when increased via Net2.",
    pinModeLabel: "Pin mode",
    pinModeInLabel:
      "In: a pin can only be used once. Useful when keypads are not used when leaving the site",
    pinModeInOutLabel: "In-out: a pin can be used once in, once out",
    pinModeUnlimitedLabel:
      "Unlimited: a pin can be used indefinitely until the pin validity expires",
    dailyVisitLimitShortLabel: "Limit",
    dailyVisitLimitLongLabel:
      "Limit the amount of visits a host can have any given day",
    dailyVisitLimitHelp:
      "When enabled, the host will be alerted when they exceed the visits and won't be allowed new visits.",
    dailyVisitLimitByGroup: "Visit limit by host department",
    visitLimitMode: "Visit limit mode",
    visitLimitModeHelp:
      "The simultaneous visit limit will not count any of the visits that are departed, expired or finished, while the daily limit will count those as well.",
    simultaneous:
      "Visits will be limited based on the number of visits occuring at the same time",
    daily: "Visits will be limited based on a daily maximum value",
    defaultVisitLimitValue: "Default visit limit",
    unlimitedDailyLimitMessage: "Unlimited",
    allowDailyVisitLimitOverrideByStaffShortLabel: "limit override",
    allowDailyVisitLimitOverrideByStaffLongLabel:
      "Allow staff to override the visit limit",
    allowDailyVisitLimitOverrideByStaffHelp:
      "When a host has exceeded the visit limit staff will be shown a popup blocking them from registering the visit. This setting allows them to override this warning.",
    idScanningShortLabel: "ID scanning",
    idScanningLongLabel: "Enable ID scanning add-on",
    idScanningHelp:
      "Contact support@arke.io for more information as this can affect your billing.",
    unregisteredVisitorShortLabel: "Unregistered visitor",
    unregisteredVisitorLongLabel: "Enable unregistered visitor add-on",
    unregisteredVisitorHelp:
      "Contact support@arke.io for more information as this can affect your billing",
    days: "Days",
    hours: "Hours",
    pinExpirationTimeLabel: "Pin expiration",
    pinExpirationHelp:
      "The time a PIN will stay valid once requested. This will reset after being used to go in. Use this setting to ensure visits stay current.",
    doorFilteringLabel: "Door filter",
    doorFilteringHelp:
      "Events will be filtered so visitors can move without restrictions within the site. Make sure to set the right permissions on the 'Arke Out <-' access level.",
  },
  sitesettingsusers: {
    title: "Site settings: {{userCount}} Users",
    email: "Email",
    back: "Back to users",
    roles: "Roles",
    detailsTitle: "User {{email}}",
    removeRoleConfirmation: "Remove role {{role}} from {{email}}?",
    removeConfirmation: "Remove user {{email}}?",
    cannotManageAccount:
      "This is an account level user. You don't have permission to change this.",
    cannotManageSite: "You don't have enough permissions to change this.",
    accountRole: "Account role: ",
    usersText:
      "Users are allowed to change settings, view visits, hosts, scan id cards, register unregistered visitors and more. \n Some operators and administrators are managed from an account and cannot be removed from a single site.",
    addUser: "Add user",
    removeUser: "Remove user",
    removePermissions: "Remove all permissions",
    addUserIntro:
      "Add a user to your site. The user will be sent an invitation email.",
    addUserTitle: "Add a new user",
  },
  sitesetup: {
    title: "Site setup",
    intro:
      "Download and install the Arke client and follow the steps to connect it to Net2. Then check back here to connect the client to your Arke site.",
    subscriptionLabel:
      "Copy and paste the subscription ID here shown in the Arke Client after installation",
    thumbprintLabel:
      "Enter the last 4 characters of the Thumbprint shown in the Arke Client after installation",
    step1: "Step 1: Install client",
    step2: "Step 2: Configure client details",
    step3: "Step 3: Connect site",
    connectButton: "Connect",
    siteSetupSuccess:
      "Your site has been setup. It can take a couple of minutes for everything to be sorted, check back soon.",
    siteSetupFail:
      "Looks like something's missing. Make sure the installer has ran and the Arke Client service is running.",
  },
  visits: {
    startAt: "Starts at",
    moreInfo: "View more info",
    title: "Visits",
    searchPlaceholder: "Search (Coming soon)",
    exportFail:
      "Something went wrong while requesting the export. Try again or contact support@arke.io",
    exportSuccess:
      "Your export is being created and will soon be delivered to your email address",
    toToday: "Back to today",
  },
  clients: {
    C6437C12FF1D741B7BD7AAF95E0292017: "SMS",
    CF8132F738DA74DCAA3CD857F9BBEF678: "Unregistered call",
    C8C15101BF79D4188B7722C1A30DA92D4: "Balwin app",
    CB45B52FB9E57422DB60D7C4AF69F7152: "Web app",
    C7605A344DEE7466A99EB5A69241DD429: "Arke app",
    CF88B7FA9BC724481837CB5E9503B0D0F: "Portal",
    C8FDF2C51FEC949B1923AD4B4CA9A1306: "EstateMate",
    unknown: "Unknown",
  },
  createVisit: {
    title: "Add a new visit",
    firstName: "First name",
    firstNamePlaceholder: "First name (optional)",
    lastName: "Last name",
    lastNamePlaceholder: "Last name (optional)",
    phoneNumber: "Phone number",
    visitorHelp: "Enter the phone number of your visitor",
    when: "When is your visit?",
    whenHelp:
      "Prepare for your future visits by scheduling them. The PINs will be created and distributed about 5 minutes before the scheduled time.",
    invite: "Invite visitor",
    visitCreatedSucessfully: "Visit created sucessfully",
    visitStatus: "Visit status",
    visitor: "Visitor",
    back: "Back",
    otherVisitors: "Other visitors (optional)",
    createVisit: "Create visit",
    addVisitor: "Add visitor",
  },
  visitDetails: {
    title: "Visit details",
    back: "Back to visits",
    timeline: "Timeline",
    visitunregisteredapprovedviacall: "Visit approved via phone call",
    visitrequested: "Visit requested via {#client}",
    visitscheduled: " Visit scheduled",
    visitorinvited: "Invitation code sent",
    visitorarrived: "Visitor arrived ({#content})",
    visitordeparted: "Visitor departed ({#content})",
    visitordenied: "Visit denied",
    hostexceededdailycount: "The host exceeded their visit count",
    hostdailycountoverride: "Daily count was overriden by {#content}",
    visitcancellationrequested: "Visit cancellation requested",
    visitcancellationfailed: "Visit cancellation failed",
    visitcancellationcompleted: "Visit cancelled",
    visitdocumentsscanned: "Scanned {#content} ID documents",
    visitinvalidinput: "The received input was invalid",
    visiterrorcreating:
      "There was a connectivity issue while creating this visit",
    callstaffstarted: "Staff {#content} started call",
    callhostanswered: "Host answered call",
    visitunregisteredunapprovedviacall:
      "Unregistered call completed, visit unapproved",
    accountdisabled: "The account was disabled while creating this visit",
    screeningsucceeded: "Visitor completed screening successfully",
    screeningfailed: "Visitor failed screening",
    schedule: "Schedule",
    starts: "Starts",
    ends: "Expires",
    expired: "Expired",
    code: "Code",
    created: "Created {#created}",
  },
  watchlist: {
    title: "Watchlist",
    addEntry: "Add new {#phonenumber} entry",
    type: "Type",
    dateAdded: "Date Added",
    description: "Description",
    blockVisits: "Block visits",
    notifyStaff: "Notify events to staff",
    searchPlaceholder: "Search for a value",
    show: "Show",
    all: "All",
  },
  watchlistEntryDetails: {
    title: "Watchlist entry",
    back: "back",
    removeEntry: "Remove",
    settings: "Watchlist entry settings",
    relatedEvents: "Related events",
    removeConfirmation:
      "Are you sure you want to remove the selected {#variable} from the Watchlist?",
  },
  hostDetails: {
    title: "Host details",
    back: "Back to hosts",
    visits: "Visits",
    start: "Start of visit",
    manual: "Host is manually provisioned",
    net2Sync: "Host is provisioned through Net2 synchronisation",
    removeConfirmation: "Are you sure you want to remove the host {#hostname}?",
    sendEmailConfirmation:
      "Are you sure you want to send the registered email address for this phone number to {#phoneNumber}?",
    removeSuccess: "The host will be removed soon",
    removeFail: "The host could not be removed. Please try again.",
    sendMessage: "Send message",
  },
  visitDocuments: {
    title: "Visit scanned ID documents",
    back: "Back to visit",
  },
  visitstatus: {
    0: "Unknown",
    5: "Requested",
    6: "Scheduled",
    7: "Awaiting screening",
    10: "Invited",
    20: "Arrived",
    30: "Departed",
    100: "Expired",
    150: " Finished",
    410: "Account disabled",
    420: "Invalid input",
    430: "Connection issues",
    440: "Exceeded daily count",
    450: "Unregistered call not approved",
    460: "Visit cancelled",
    470: "Denied",
    480: "Screening failed",
  },
  roles: {
    0: "Host",
    301: "Support",
    401: "Security",
    501: "Manager",
    801: "Operator",
    901: "Administrator",
    999: "Hidden Administrator",
  },
  iddocuments: {
    type2710: "South Africa drivers license",
    type2711: "South Africa temporary drivers license",
    type2720: "South Africa id card",
    type2730: "South Africa card disk",
    typenull: "Unknown type",
    sadlVehicleClass1Code: "Vehicle class 1 code",
    sadlVehicleClass2Code: "Vehicle class 2 code",
    sadlVehicleClass3Code: "Vehicle class 3 code",
    sadlVehicleClass4Code: "Vehicle class 4 code",
    sadlPersonSurname: "Surname",
    sadlPersonInitials: "Initials",
    sadlProfessionalDrivingPermitCategory: "Driving permit category",
    sadlIdentityDocumentCountryOfIssue: "Document country of issue",
    sadlDrivingLicenseCountryOfIssue: "Driving license country of issue",
    sadlVehicleClass1VehicleRestriction: "Vehicle class 1 restriction",
    sadlVehicleClass2VehicleRestriction: "Vehicle class 2 restriction",
    sadlVehicleClass3VehicleRestriction: "Vehicle class 3 restriction",
    sadlVehicleClass4VehicleRestriction: "Vehicle class 4 restriction",
    sadlDrivingLicenseCertificateNumber: "Driving license certificate number",
    sadlIdentityDocumentNumber: "Document number",
    sadlIdentityDocumentType: "Document type",
    sadlVehicleClass1FirstIssueDate: "Vehicle class 1 first issue date",
    sadlVehicleClass2FirstIssueDate: "Vehicle class 2 first issue date",
    sadlVehicleClass3FirstIssueDate: "Vehicle class 3 first issue date",
    sadlVehicleClass4FirstIssueDate: "Vehicle class 4 first issue date",
    sadlPersonDriverRestriction1: "Driver restriction 1",
    sadlPersonDriverRestriction2: "Driver restriction 2",
    sadlProfessionalDrivingPermitDateValidUntil: "Driving permit valid until",
    sadlCardIssueNumber: "Card issue number",
    sadlPersonDateOfBirth: "Date of birth",
    sadlCardDateValidFrom: "Card valid from",
    sadlCardDateValidUntil: "Card valid until",
    sadlPersonGender: "Gender",
    sacdNumber: "Number",
    sacdLicenseNumber: "License number",
    sacdVehicleRegistrationNumber: "Vehicle registration number",
    sacdDescription: "Description",
    sacdMake: "Make",
    sacdMakeDescription: "Make description",
    sacdColor: "Color",
    sacdVIN: "VIN",
    sacdEngineNumber: "Engine number",
    sacdExpiryDate: "Expiry date",
    saicSurname: "Surname",
    saicNames: "Names",
    saicSex: "Gender",
    saicNationality: "Nationality",
    saicIdentityNumber: "Identity number",
    saicDateOfBirth: "Date of birth",
    saicCountryOfBirth: "Country of birth",
    saicStatus: "Status",
    saicDateOfIssue: "Date issued",
    satdlIdentificationNumber: "Identification number",
    satdlInitialsAndSurname: "Initials and surname",
    satdlDriversLicenseCode: "License code",
    satdlDriversLicenseDate: "License date",
    satdlDateIssued: "Date issued",
  },
};
