import { inject, observer } from "mobx-react";
import * as React from "react";
import { withTranslation, WithTranslation } from "react-i18next";
import { CardBody } from "reactstrap";

interface IProps extends WithTranslation {}

@inject("arke")
@observer
class Host extends React.Component<IProps, {}> {
  public render() {
    const t = this.props.t!;
    return (
      <>
        <CardBody dangerouslySetInnerHTML={{ __html: t("hostIntro") }} />
      </>
    );
  }
}

export default withTranslation("newAccount")(Host);
