import { inject, observer } from "mobx-react";
import * as React from "react";
import {
  Button,
  Col,
  Form,
  FormGroup,
  FormText,
  Label,
  Row,
  CardBody,
  CardHeader,
} from "reactstrap";
import { IArke, SiteResponseV1 } from "@intreba/arke-api-client";
import Input from "reactstrap/lib/Input";
import { Spinner } from "../../../../components";
import { CannotEditWarning } from "../CannotEditWarning";
import { AccessSettingsState } from "./AccessSettingsState";
import { WithTranslation, withTranslation } from "react-i18next";
import { DailyVisitLimit } from "./DailyVisitLimit";

interface InjectedProps extends IProps {
  itemContainer: { site: SiteResponseV1 };
  arke: IArke;
}

interface IProps extends WithTranslation {}

@inject("itemContainer", "arke")
@observer
class AccessSettingsContent extends React.Component<IProps, {}> {
  private get injectedprops() {
    return this.props as InjectedProps;
  }
  private settingsState: AccessSettingsState;
  public constructor(props: any) {
    super(props);
    this.settingsState = new AccessSettingsState(
      this.injectedprops.t,
      this.injectedprops.arke,
      this.injectedprops.itemContainer.site
    );
  }
  public async componentDidMount() {
    await this.settingsState.load();
  }
  public render() {
    const t = this.injectedprops.t!;
    return (
      <>
        <CardHeader tag="div">
          <Row style={{ marginBottom: "1rem" }}>
            <Col md={12}>
              <h2>{t("title")}</h2>
            </Col>
          </Row>
        </CardHeader>
        <CardBody>
          <Row>
            <Col sm="12">
              <Form onSubmit={this.settingsState.onSubmit}>
                <CannotEditWarning state={this.settingsState} />
                <FormGroup row={true}>
                  <Label for="defaultVisitLimitValue" lg={2}>
                    {t("dailyVisitLimitShortLabel")}
                  </Label>
                  <Col lg={10}>
                    <FormGroup check={true}>
                      <Label check={true}>
                        <Input
                          disabled={this.settingsState.disabled}
                          type="checkbox"
                          name="visitLimits"
                          id="visitLimits"
                          checked={
                            this.settingsState.globalLimitSettings
                              .globalDailyVisitLimit !== null
                          }
                          // tslint:disable-next-line:jsx-no-lambda
                          onChange={() => {
                            if (
                              this.settingsState.globalLimitSettings
                                .globalDailyVisitLimit === null
                            ) {
                              this.settingsState.changeHostDailyLimit(10);
                            } else {
                              this.settingsState.changeHostDailyLimit(null);
                            }
                          }}
                        />
                        {t("dailyVisitLimitLongLabel")}
                      </Label>
                    </FormGroup>
                    <FormText color="muted">
                      {t("dailyVisitLimitHelp")}
                    </FormText>
                    <FormGroup>
                      {this.settingsState.globalLimitSettings
                        .globalDailyVisitLimit !== null && (
                        <DailyVisitLimit accessSettings={this.settingsState} />
                      )}
                    </FormGroup>
                  </Col>
                </FormGroup>
                <FormGroup row={true}>
                  <Label for="pinMode" lg={2}>
                    {t("pinModeLabel")}
                  </Label>
                  <Col lg={10}>
                    <FormGroup check={true}>
                      <Label check={true}>
                        <Input
                          type="radio"
                          name="pinMode"
                          disabled={this.settingsState.disabled}
                          value="In"
                          checked={this.settingsState.pinMode === "In"}
                          onChange={this.settingsState.changePinMode}
                        />{" "}
                        {t("pinModeInLabel")}
                      </Label>
                    </FormGroup>
                    <FormGroup check={true}>
                      <Label check={true}>
                        <Input
                          type="radio"
                          name="pinMode"
                          disabled={this.settingsState.disabled}
                          value="InOut"
                          checked={this.settingsState.pinMode === "InOut"}
                          onChange={this.settingsState.changePinMode}
                        />{" "}
                        {t("pinModeInOutLabel")}
                      </Label>
                    </FormGroup>
                    <FormGroup check={true}>
                      <Label check={true}>
                        <Input
                          type="radio"
                          name="pinMode"
                          value="Unlimited"
                          disabled={this.settingsState.disabled}
                          checked={this.settingsState.pinMode === "Unlimited"}
                          onChange={this.settingsState.changePinMode}
                        />{" "}
                        {t("pinModeUnlimitedLabel")}
                      </Label>
                    </FormGroup>
                  </Col>
                </FormGroup>
                <FormGroup row={true}>
                  <Label for="pinLength" lg={2}>
                    {t("pinLengthLabel")}
                  </Label>
                  <Col lg={10}>
                    <Input
                      disabled={this.settingsState.disabled}
                      type="select"
                      name="pinLength"
                      id="phoneField"
                      value={
                        this.settingsState.pinLength === null ||
                        this.settingsState === undefined
                          ? t("common:unknown").toString()
                          : this.settingsState.pinLength.toString()
                      }
                      onChange={this.settingsState.changePinLength}
                    >
                      {this.settingsState.pinLength === null ? (
                        <option value="0">0</option>
                      ) : null}

                      <option value="4">4</option>
                      <option value="5">5</option>
                      <option value="6">6</option>
                      <option value="7">7</option>
                      <option value="8">8</option>
                    </Input>
                    <FormText color="muted">{t("pinLengthHelp")}</FormText>
                  </Col>
                </FormGroup>
                <FormGroup row={true}>
                  <Label for="pinExpirationTime" lg={2}>
                    {t("pinExpirationTimeLabel")}
                  </Label>
                  <Col sm={2}>
                    <Input
                      disabled={this.settingsState.disabled}
                      type="number"
                      name="pinExpirationTimeDays"
                      id="pinExpirationTime"
                      value={this.settingsState.pinExpirationDays}
                      onChange={this.settingsState.changePinExpirationDays}
                    />
                    <FormText color="muted">{t("days")}</FormText>
                  </Col>
                  <Col sm={2}>
                    <Input
                      disabled={this.settingsState.disabled}
                      type="number"
                      name="pinExpirationTimeHours"
                      id="pinExpirationTime"
                      value={this.settingsState.pinExpirationHours}
                      onChange={this.settingsState.changePinExpirationHours}
                    />
                    <FormText color="muted">{t("hours")}</FormText>
                  </Col>
                  <Col sm={{ offset: 2, size: 10 }}>
                    <FormText color="muted">{t("pinExpirationHelp")}</FormText>
                  </Col>
                </FormGroup>
                <FormGroup row={true}>
                  <Label for="useHostAccessLevelForVisitor" lg={2}>
                    {t("useHostAccessLevelForVisitorShortLabel")}
                  </Label>
                  <Col lg={10}>
                    <FormGroup check={true}>
                      <Label check={true}>
                        <Input
                          disabled={this.settingsState.disabled}
                          type="checkbox"
                          name="useHostAccessLevelForVisitor"
                          id="useHostAccessLevelForVisitor"
                          checked={this.settingsState.useHostAccessLevel}
                          onChange={this.settingsState.changeUseHostAccessLevel}
                        />
                        {t("useHostAccessLevelForVisitorLongLabel")}
                      </Label>
                    </FormGroup>
                    <FormText color="muted">
                      {t("useHostAccessLevelForVisitorHelp")}
                    </FormText>
                  </Col>
                </FormGroup>
                <FormGroup row={true}>
                  <Label for="idScanning" lg={2}>
                    {t("idScanningShortLabel")}
                  </Label>
                  <Col lg={10}>
                    <FormGroup check={true}>
                      <Label check={true}>
                        <Input
                          disabled={this.settingsState.disabled}
                          type="checkbox"
                          name="idScanning"
                          id="idScanning"
                          checked={this.settingsState.scanning}
                          onChange={this.settingsState.changeScanning}
                        />
                        {t("idScanningLongLabel")}
                      </Label>
                    </FormGroup>
                    <FormText color="muted">{t("idScanningHelp")}</FormText>
                  </Col>
                </FormGroup>
                <FormGroup row={true}>
                  <Label for="unregisteredVisitor" lg={2}>
                    {t("unregisteredVisitorShortLabel")}
                  </Label>
                  <Col lg={10}>
                    <FormGroup check={true}>
                      <Label check={true}>
                        <Input
                          disabled={this.settingsState.disabled}
                          type="checkbox"
                          name="unregisteredVisitor"
                          id="unregisteredVisitor"
                          checked={this.settingsState.unregisteredVisitor}
                          onChange={
                            this.settingsState.changeUnregisteredVisitor
                          }
                        />
                        {t("unregisteredVisitorLongLabel")}
                      </Label>
                    </FormGroup>
                    <FormText color="muted">
                      {t("unregisteredVisitorHelp")}
                    </FormText>
                  </Col>
                </FormGroup>
                <FormGroup row={true}>
                  <Label for="doorFiltering" lg={2}>
                    {t("doorFilteringLabel")}
                  </Label>
                  <Col sm={10}>
                    <Row>
                      <Col sm={4}>
                        <Label for="doorFilterIn">IN filter</Label>
                        {this.settingsState.doors.map((d) => {
                          return (
                            <FormGroup check={true} key={d.serialNumber}>
                              <Label check={true}>
                                <Input
                                  disabled={this.settingsState.disabled}
                                  type="checkbox"
                                  name="doorFilterIn"
                                  id="doorFilterIn"
                                  value={d.serialNumber}
                                  checked={
                                    this.settingsState.net2InFilter.filter(
                                      (s) => s === d.serialNumber
                                    ).length > 0
                                  }
                                  onChange={
                                    this.settingsState.changeNet2InFilter
                                  }
                                />
                                {d.name}
                              </Label>
                            </FormGroup>
                          );
                        })}
                      </Col>
                      <Col sm={4}>
                        <Label for="doorFilterOut">OUT filter</Label>
                        {this.settingsState.doors.map((d) => {
                          return (
                            <FormGroup check={true} key={d.serialNumber}>
                              <Label check={true}>
                                <Input
                                  disabled={this.settingsState.disabled}
                                  type="checkbox"
                                  name="doorFilterOut"
                                  id="doorFilterOut"
                                  value={d.serialNumber}
                                  checked={
                                    this.settingsState.net2OutFilter.filter(
                                      (s) => s === d.serialNumber
                                    ).length > 0
                                  }
                                  onChange={
                                    this.settingsState.changeNet2OutFilter
                                  }
                                />
                                {d.name}
                              </Label>
                            </FormGroup>
                          );
                        })}
                      </Col>
                    </Row>
                    <FormText color="muted">{t("doorFilteringHelp")}</FormText>
                  </Col>
                </FormGroup>
                {this.settingsState.disabled ? (
                  <CannotEditWarning state={this.settingsState} />
                ) : (
                  <Button
                    type="submit"
                    color="primary"
                    disabled={this.settingsState.disabled}
                  >
                    Save
                  </Button>
                )}
              </Form>
              {this.settingsState.loading && <Spinner />}
            </Col>
          </Row>
        </CardBody>
      </>
    );
  }
}

const AccessSettings = withTranslation("sitesettingsaccess")(
  AccessSettingsContent
);

export { AccessSettings };
