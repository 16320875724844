import { observer } from "mobx-react";
import * as React from "react";
import { ChevronLeft, ChevronRight } from "react-feather";
import { ButtonGroup, ButtonToolbar, Table } from "reactstrap";
import Button from "reactstrap/lib/Button";
import { IPaginationStore } from "./IPaginationStore";

const PaginationButton = observer(
  (props: { pageNumber: number; currentPage: number; onClick: () => void }) => {
    return (
      <Button
        onClick={props.onClick}
        color={props.pageNumber === props.currentPage ? "primary" : "secondary"}
      >
        {props.pageNumber}
      </Button>
    );
  }
);

export interface IPaginationRenderRowInfo<ItemT> {
  item: ItemT;

  index: number;
}
export type PaginationRenderRow<ItemT> = (
  info: IPaginationRenderRowInfo<ItemT>
) => React.ReactElement<any> | null;

export interface IPaginationViewProps<ItemT> {
  store: IPaginationStore;
  renderItem: PaginationRenderRow<ItemT>;
  keyExtractor: (item: ItemT, index: number) => string;
  columns: string[];
  onItemClick?: (item: ItemT) => void;
}

@observer
export default class PaginationView<ItemT> extends React.Component<
  IPaginationViewProps<ItemT>,
  {}
> {
  public render() {
    const extraButtons: JSX.Element[] = [];
    let doneFirstDots = false;
    let doneSecondDots = false;
    for (let i = 1; i <= this.props.store.totalPages; i = i + 1) {
      if (
        this.props.store.totalPages > 7 &&
        i > 1 &&
        i < this.props.store.totalPages
      ) {
        if (Math.abs(this.props.store.currentPage - i) > 1) {
          if (this.props.store.currentPage > i && !doneFirstDots) {
            extraButtons.push(
              <Button disabled={true} key="firstdots">
                {" "}
                ...{" "}
              </Button>
            );
            doneFirstDots = true;
          } else if (this.props.store.currentPage < i && !doneSecondDots) {
            extraButtons.push(
              <Button disabled={true} key="seconddots">
                ...
              </Button>
            );
            doneSecondDots = true;
          }
          continue;
        }
      }
      extraButtons.push(
        <PaginationButton
          key={i}
          pageNumber={i}
          currentPage={this.props.store.currentPage}
          // tslint:disable-next-line:jsx-no-lambda
          onClick={() => this.props.store.goToPage(i)}
        />
      );
    }
    // <IboxContent loading={this.props.store.isGettingItems}>
    return (
      <>
        <Table size="sm" responsive={true} hover={true}>
          {this.props.columns.length > 0 && (
            <thead>
              <tr>
                {this.props.columns.map((column, key) => (
                  <td
                    style={{ fontWeight: "bold" }}
                    key={column !== "" ? column : key}
                  >
                    {column}
                  </td>
                ))}
              </tr>
            </thead>
          )}

          <tbody>
            {this.props.store.items.map(
              (singleItem: any, itemIndex: number) => {
                const element = this.props.renderItem({
                  item: singleItem,
                  index: itemIndex,
                });
                return (
                  element &&
                  React.cloneElement(element, {
                    key: this.props.keyExtractor(singleItem, itemIndex),
                  })
                );
              }
            )}
          </tbody>
        </Table>
        {!this.props.store.isGettingItems && this.props.store.totalPages > 1 && (
          <ButtonToolbar>
            <ButtonGroup>
              <Button
                disabled={!this.props.store.canGoBack}
                // tslint:disable-next-line:jsx-no-lambda
                onClick={() => this.props.store.getPreviousPage()}
              >
                <ChevronLeft height={18} width={18} />
              </Button>
              <Button
                disabled={!this.props.store.canGoNext}
                // tslint:disable-next-line:jsx-no-lambda
                onClick={() => this.props.store.getNextPage()}
              >
                <ChevronRight height={18} width={18} />
              </Button>
            </ButtonGroup>
          </ButtonToolbar>
        )}
      </>
    );
  }
}
