import {
  IArke,
  SitePermissions,
  SiteResponseV1,
} from "@intreba/arke-api-client";
import i18n from "i18next";
import { action, observable, runInAction } from "mobx";
import BaseSettingsState from "../../BaseSettingsState";

export class TemplatesState extends BaseSettingsState {
  private arke: IArke;

  constructor(t: i18n.TFunction, arke: IArke, site: SiteResponseV1) {
    super(
      t,
      SitePermissions.sitemessagetemplatesupdate,
      site.permissions,
      site.siteId
    );
    this.arke = arke;
  }
  public async save(): Promise<void> {
    await this.arke.sites.updateMessageTemplates(this.siteId, {
      pinMessage: this.invitationMessage,
      welcomeMessage: this.welcomeMessage,
    });
  }

  public async retrieve(): Promise<void> {
    const settings = await this.arke.sites.findMessageTemplates(this.siteId);
    if (settings !== null) {
      runInAction(() => {
        this.welcomeMessage = settings.welcomeMessage;
        this.welcomeMessageEnabled = settings.welcomeMessageEnabled;

        this.invitationMessage = settings.pinMessage;
      });
    }
  }

  @observable
  public invitationMessage: string = "";
  @observable
  public welcomeMessage: string = "";
  @observable
  public welcomeMessageEnabled: boolean = false;
  @action
  public changeInvitationMessage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    this.invitationMessage = event.target.value;
  };
  @action
  public changeWelcomeMessage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    this.welcomeMessage = event.target.value;
  };
}
