import { ScreeningAdditionalField } from "@intreba/arke-api-client";
import React from "react";
import { useTranslation } from "react-i18next";
import { Input } from "reactstrap";

export const QuestionSelectDesiredAnswer = (props: {
  question: ScreeningAdditionalField;
  disabled: boolean;
  onChange: (newAnswer: string) => void;
}) => {
  const { t } = useTranslation("sitesettingsscreening");
  return (
    <Input
      type="select"
      disabled={props.disabled}
      name="selectDesiredAnswer"
      id="selectDesiredAnswerForQuestion"
      value={props.question.expectedAnswer}
      onChange={(e) => {
        props.onChange(e.target.value);
      }}
    >
      <option value="" disabled></option>
      <option value="yes">{t("common:yes")}</option>
      <option value="no">{t("common:no")}</option>
    </Input>
  );
};
