import { IArke, BroadcastResponseV1 } from "@intreba/arke-api-client";
import { action, observable, runInAction } from "mobx";
import { ChangeEvent } from "react";
import { PaginationStore } from "../../services/pagination/PaginationStore";
import { EditorState, convertToRaw } from "draft-js";
import { draftjsToMd } from "draftjs-md-converter";
import {
  showSuccessToast,
  showApiErrorToast,
} from "../../services/commonToasts";
import { ConversationContent } from "./ConversationStore";
export default class MessageStore extends PaginationStore<BroadcastResponseV1> {
  private arke: IArke;
  private siteId: string;
  @observable
  public search: string = "";
  public url: string = "";
  public broadcastId: string = "";
  @observable
  public selectedBroadcast: ConversationContent | null = null;
  @observable
  public messageMarkdowncontent: EditorState = EditorState.createEmpty();
  @observable
  public broadcastList: Map<string, ConversationContent> = new Map<
    string,
    ConversationContent
  >();
  @observable
  public isWorking: boolean = false;
  constructor(arke: IArke, siteId: string) {
    super();
    this.arke = arke;
    this.siteId = siteId;
  }

  @action
  public onSearchChange = (event: ChangeEvent<HTMLInputElement>) => {
    this.search = event.target.value;
  };

  @action
  public resetSearch = () => {
    this.search = "";
    this.goToPage(1);
  };

  @action
  public setUrl(url: string) {
    this.url = url;
  }

  @action
  public getSelectedBroadcastUrl() {
    if (!this.selectedBroadcast) return "";
    let value = `${this.url}/broadcasts/${this.selectedBroadcast.conversationId}`;
    return value;
  }

  @action
  public getNewBroadcastUrl() {
    let value = `${this.url}/broadcasts/new`;
    return value;
  }

  @action
  public selectBroadcast(broadcastId: string) {
    let value = this.broadcastList.get(broadcastId);
    if (value) {
      this.selectedBroadcast = value;
    }
  }

  public convertBroadcast(broadcast: BroadcastResponseV1) {
    return {
      conversationId: broadcast.broadcastId,
      subject: broadcast.subject,
      sentAt: broadcast.sentAt,
      updatedAt: broadcast.sentAt,
      status: -1,
      participants: { Management: "Management" },
      messageList: [
        {
          messageId: broadcast.broadcastId,
          sentAt: broadcast.sentAt,
          markdownContent: broadcast.markdownContent,
          from: "Management",
          onBehalfOf: this.siteId,
        },
      ],
    };
  }

  @action
  public setSelectBroadcast(broadcast: ConversationContent) {
    this.selectedBroadcast = broadcast;
  }

  @action
  public setBroadcastId(broadcastId: string) {
    this.broadcastId = broadcastId;
  }

  @action
  public clearSelectedBroadcast() {
    this.selectedBroadcast = null;
  }

  @action
  public goToPage(page: number) {
    this.pageToBeRetrieved = page;
    this.getPage().then(() => {
      this.items.forEach((value) => {
        this.broadcastList.set(value.broadcastId, this.convertBroadcast(value));
      });
      if (this.broadcastId !== "") {
        this.getBroadcastDetails();
        this.broadcastId = "";
      }
    });
  }

  @action
  public async getBroadcastDetails() {
    if (!this.broadcastId) return;
    this.isGettingItems = true;
    try {
      if (this.selectedBroadcast === null) {
        const broadcast = await this.arke.conversation.getBroadcastMessage(
          this.siteId,
          this.broadcastId
        );
        this.setSelectBroadcast(this.convertBroadcast(broadcast));
      }
    } finally {
      runInAction(() => {
        this.isGettingItems = false;
      });
    }
  }

  @action
  public setMessageMarkdowncontent(state: EditorState) {
    this.messageMarkdowncontent = state;
  }

  @action
  public async startConversationFromBroadcast() {
    //TODO check with Roberto why this is here but doesn't do anything?
    this.isWorking = true;
    try {
      draftjsToMd(
        convertToRaw(this.messageMarkdowncontent.getCurrentContent())
      );
      if (this.selectedBroadcast !== null) {
      }
      await showSuccessToast("Conversation created sucessfully");
    } catch (error) {
      await showApiErrorToast(error);
    } finally {
      runInAction(() => {
        this.isWorking = false;
        this.messageMarkdowncontent = EditorState.createEmpty();
      });
    }
  }

  public getUrl() {
    return this.url;
  }

  public getItems(): Promise<{
    items: BroadcastResponseV1[];
    totalAmount: number;
  }> {
    return this.arke.conversation.getBroadcasts(
      this.siteId,
      this.pageToBeRetrieved,
      this.itemsPerPage,
      this.search === "" ? undefined : this.search
    );
  }
}
