import React from "react";
import { useTranslation } from "react-i18next";
import { Row, Col, FormText, FormGroup, Input } from "reactstrap";
import { TimeSpan } from "./TimeSpan";

export const TimeSpanInput = (props: {
  value: TimeSpan | string | null;
  onChange: (newTimeSpan: TimeSpan) => void;
  disabled?: boolean;
  granularity?: {
    daysDisabled?: boolean;
    hoursDisabled?: boolean;
    minutesDisabled?: boolean;
    secondsDisabled?: boolean;
  };
}) => {
  const { t } = useTranslation("common");
  let span: TimeSpan;
  if (props.value instanceof TimeSpan) {
    span = props.value;
  } else {
    span = new TimeSpan();
    span.parseString(props.value);
  }
  return (
    <Row style={{ margin: 0 }}>
      {(props.granularity === undefined ||
        props.granularity.daysDisabled !== true) && (
        <Col sm={1.5}>
          <FormText color="muted">{t("days")}</FormText>
          <FormGroup>
            <Input
              type="number"
              disabled={props.disabled}
              min="0"
              max="30"
              style={{
                display: "inline-block",
                width: "80%",
                paddingTop: "2px",
              }}
              value={span.days}
              onChange={(e) => {
                const newSpan = new TimeSpan(
                  parseInt(e.target.value, 10),
                  span.hours,
                  span.minutes,
                  span.seconds
                );
                props.onChange(newSpan);
              }}
            />
          </FormGroup>
        </Col>
      )}
      {(props.granularity === undefined ||
        props.granularity.hoursDisabled !== true) && (
        <Col sm={1.5}>
          <FormText color="muted">{t("hours")}</FormText>
          <FormGroup>
            <Input
              type="number"
              disabled={props.disabled}
              min="0"
              max="23"
              style={{
                display: "inline-block",
                width: "80%",
                paddingTop: "2px",
              }}
              value={span.hours}
              onChange={(e) => {
                const newSpan = new TimeSpan(
                  span.days,
                  parseInt(e.target.value, 10),
                  span.minutes,
                  span.seconds
                );
                props.onChange(newSpan);
              }}
            />
          </FormGroup>
        </Col>
      )}
      {(props.granularity === undefined ||
        props.granularity.minutesDisabled !== true) && (
        <Col sm={1.5}>
          <FormText color="muted">{t("minutes")}</FormText>
          <FormGroup>
            <Input
              type="number"
              disabled={props.disabled}
              min="0"
              max="59"
              value={span.minutes}
              style={{
                display: "inline-block",
                width: "80%",
                paddingTop: "2px",
              }}
              onChange={(e) => {
                const newSpan = new TimeSpan(
                  span.days,
                  span.hours,
                  parseInt(e.target.value, 10),
                  span.seconds
                );
                props.onChange(newSpan);
              }}
            />
          </FormGroup>
        </Col>
      )}
      {(props.granularity === undefined ||
        props.granularity.secondsDisabled !== true) && (
        <Col sm={1.5}>
          <FormText color="muted">{t("seconds")}</FormText>
          <FormGroup>
            <Input
              type="number"
              disabled={props.disabled}
              min="0"
              max="60"
              style={{
                display: "inline-block",
                width: "80%",
                paddingTop: "2px",
              }}
              value={span.seconds}
              onChange={(e) => {
                const newSpan = new TimeSpan(
                  span.days,
                  span.hours,
                  span.minutes,
                  parseInt(e.target.value, 10)
                );
                props.onChange(newSpan);
              }}
            />
          </FormGroup>
        </Col>
      )}
      <Col sm={10}></Col>
    </Row>
  );
};
