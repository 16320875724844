import * as React from "react";

export default class ErrorHandler extends React.Component<
  {},
  {
    hasError: boolean;
  }
> {
  public constructor(props: any) {
    super(props);
    this.state = { hasError: false };
  }
  public componentDidCatch(error: Error, errorInfo: React.ErrorInfo) {
    console.error(error);
    console.error(errorInfo);
    this.setState({ hasError: true });
  }
  public render() {
    if (this.state !== null && this.state.hasError) {
      return <h1>Something went wrong!</h1>;
    } else {
      return this.props.children;
    }
  }
}
