import {
  SitePermissions,
  SiteResponseV1,
  userHasSitePermission
} from "@intreba/arke-api-client";
import { inject } from "mobx-react";
import * as React from "react";

export interface InjectedPermissionProps extends IPermissionProps {
  itemContainer: { site: SiteResponseV1 };
}
export interface IPermissionProps {
  permission: SitePermissions;
}

@inject("itemContainer")
export default class ShowWithPermission extends React.Component<
  IPermissionProps,
  {}
> {
  private get injectedprops() {
    return this.props as InjectedPermissionProps;
  }
  public render() {
    const hasPermission = userHasSitePermission(
      this.injectedprops.itemContainer.site.permissions,
      this.props.permission
    );
    if (hasPermission) {
      return this.props.children;
    } else {
      return null;
    }
  }
}
