import i18n from "i18next";
import enTranslations from "../../translations/translations.en";
import { initReactI18next } from "react-i18next";

const instance = i18n.use(initReactI18next).init({
  debug: true,
  fallbackLng: "en",
  interpolation: { escapeValue: false },
  lng: "en",
  react: {
    nsMode: "default"
  },
  resources: {
    en: enTranslations
  }
});

export default instance;
