import { IArke, SiteResponseV1 } from "@intreba/arke-api-client";
import { inject, observer } from "mobx-react";
import * as React from "react";
import { withTranslation, WithTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { Alert } from "reactstrap";

import EnvironmentConfig from "../../services/environment";
interface InjectedProps extends IProps {
  itemContainer: { site: SiteResponseV1 };
  arke: IArke;
}

interface IProps extends WithTranslation {}

@inject("itemContainer", "arke")
@observer
class SiteStatusAlert extends React.Component<
  IProps,
  {
    version: string;
    siteVersion: string | null;
    siteThumbprint: string | null;
  }
> {
  private get injectedprops() {
    return this.props as InjectedProps;
  }

  public constructor(props: any) {
    super(props);
    this.state = {
      version: "",
      siteVersion: "",
      siteThumbprint: "",
    };
  }

  public async componentDidMount() {
    const version = await this.injectedprops.arke.clients.findLatestInstallerVersion(
      "net2"
    );
    const client = await this.injectedprops.arke.sites.findClient(
      this.injectedprops.itemContainer.site.siteId
    );
    if (client !== null && version !== null) {
      this.setState({
        version,
        siteVersion: client.version,
        siteThumbprint: client.thumbprint,
      });
    }
  }

  public render() {
    const t = this.props.t!;
    if (this.state.siteThumbprint === "") {
      return (
        <Alert color="info">
          <div>
            {t("siteSetup")}
            <Link
              to={`/sites/${this.injectedprops.itemContainer.site.siteId}/setup`}
              className="alert-link"
            >
              {" "}
              {t("siteSetupLinkText")}
            </Link>
          </div>
        </Alert>
      );
    }
    if (
      this.state.siteVersion === "0.0.0.0" ||
      this.state.siteVersion === null
    ) {
      return <Alert color="info">{t("siteInitialising")}</Alert>;
    }
    if (!this.injectedprops.itemContainer.site.online) {
      return (
        <Alert color="danger">
          {t("connectionIssues").replace(
            "{#sitename}",
            this.injectedprops.itemContainer.site.name
          )}
        </Alert>
      );
    }
    if (
      this.state.siteVersion === this.state.version &&
      this.state.siteVersion !== "0.0.0.0"
    ) {
      return null;
    }
    const downloadUrl = `${EnvironmentConfig.arkeApi}/v1/clients/installers/net2/latest`;
    return (
      <Alert color="warning">
        {t("siteVersion")
          .replace("{#version}", this.state.version)
          .replace("{#siteversion}", this.state.siteVersion)}
        <a href={downloadUrl} target="_blank" rel="noopener noreferrer">
          {t("common:downloadButton")}
        </a>
      </Alert>
    );
  }
}

export default withTranslation("common")(SiteStatusAlert);
