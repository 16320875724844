import {
  userHasSitePermission,
  SitePermissions
} from "@intreba/arke-api-client";
import { showErrorToast } from "../commonToasts";

export function decideHomePage(permissions: string[], siteId: string) {
  if (userHasSitePermission(permissions, SitePermissions.sitestatisticsread)) {
    return `/sites/${siteId}/dashboard`;
  } else if (
    userHasSitePermission(permissions, SitePermissions.visitsread) ||
    userHasSitePermission(permissions, SitePermissions.visitsreadall)
  ) {
    return `/sites/${siteId}/visits`;
  } else {
    showErrorToast();
    return ``;
  }
}
