import {
  HostResponseV1,
  VisitResponseV1,
  HostResponseWithIdentityResponseV1,
  SitePermissions,
  userHasSitePermission,
  SiteResponseV1,
  AccountResponseV1,
} from "@intreba/arke-api-client";
import i18n from "i18next";
import { useTranslation } from "react-i18next";
import { inject, observer } from "mobx-react";
import * as React from "react";
import { History } from "history";
import { ChevronLeft, Edit2, Check, X, Mail } from "react-feather";
import { withTranslation, WithTranslation } from "react-i18next";
import { RouteComponentProps } from "react-router";
import {
  CardBody,
  CardHeader,
  Col,
  Row,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
} from "reactstrap";
import { Spinner } from "../../components";
import HostsStore from "./HostsStore";
import { Phone } from "react-feather";
import PaginationView from "../../services/pagination/PaginationView";
import { VisitRow } from "../visits/VisitRow";
import { RefreshCw, User } from "react-feather";
import { useState } from "react";
import { showSuccessToast, showErrorToast } from "../../services/commonToasts";
import { Link } from "react-router-dom";
import ShowWithPermission from "../../components/user/ShowWithPermission";
let moment = require("moment");

async function MessageIdentity(
  host: HostResponseWithIdentityResponseV1,
  hostsStore: HostsStore
) {
  await hostsStore.messageIdentity(host.siteId, host.hostId);
  showSuccessToast();
}

async function RemoveHost(
  host: HostResponseV1,
  history: History,
  hostsStore: HostsStore,
  t: i18n.TFunction
) {
  await hostsStore.remove(host.hostId, t);
  history.push(hostsStore.hostsUrl);
}

const HostDetails = (props: {
  host: HostResponseWithIdentityResponseV1;
  hostsStore: HostsStore;
  history: History;
  itemContainer: { site: SiteResponseV1 };
}) => {
  const [removeModalOpen, toggleRemoveModal] = useState(false);
  const [sendEmailModalOpen, toggleEmailModal] = useState(false);
  const { t } = useTranslation("hostDetails");
  let hostDeletionpermission = userHasSitePermission(
    props.itemContainer.site.permissions,
    SitePermissions.hostsdelete
  );
  let hostUpdatepermission = userHasSitePermission(
    props.itemContainer.site.permissions,
    SitePermissions.hostsupdate
  );
  return (
    <>
      <Modal
        isOpen={removeModalOpen}
        toggle={() => toggleRemoveModal(!removeModalOpen)}
        backdrop={true}
      >
        <ModalHeader toggle={() => toggleRemoveModal(!removeModalOpen)}>
          {t("common:areYouSure")}
        </ModalHeader>
        <ModalBody>
          {t("removeConfirmation").replace("{#hostname}", props.host.name)}
        </ModalBody>
        <ModalFooter>
          <Button
            color="primary"
            onClick={() =>
              RemoveHost(props.host, props.history, props.hostsStore, t)
            }
          >
            {t("common:yes")}
          </Button>{" "}
          <Button
            color="secondary"
            onClick={() => toggleRemoveModal(!removeModalOpen)}
          >
            {t("common:cancel")}
          </Button>
        </ModalFooter>
      </Modal>
      <Modal
        isOpen={sendEmailModalOpen}
        toggle={() => toggleEmailModal(!sendEmailModalOpen)}
        backdrop={true}
      >
        <ModalHeader toggle={() => toggleEmailModal(!sendEmailModalOpen)}>
          {t("common:areYouSure")}
        </ModalHeader>
        <ModalBody>
          {t("sendEmailConfirmation").replace(
            "{#phoneNumber}",
            props.host.localFormattedPhoneNumber
          )}
        </ModalBody>
        <ModalFooter>
          <Button
            color="primary"
            onClick={async () => {
              try {
                await MessageIdentity(props.host, props.hostsStore);
              } catch (e) {
                showErrorToast();
              } finally {
                toggleEmailModal(false);
              }
            }}
          >
            {t("common:yes")}
          </Button>{" "}
          <Button
            color="secondary"
            onClick={() => toggleEmailModal(!sendEmailModalOpen)}
          >
            {t("common:cancel")}
          </Button>
        </ModalFooter>
      </Modal>
      <Row>
        <Col sm={6}>
          <p>
            <User /> {props.host.name}{" "}
          </p>
        </Col>
        <Col sm={12}>
          <p>
            <Phone /> {props.host.localFormattedPhoneNumber}
          </p>
        </Col>
        <Col sm={12}>
          <p>
            {props.host.identityId !== null ? (
              <Check className="text-success" />
            ) : (
              <X className="text-danger" />
            )}
            {props.host.identityId !== null ? (
              <>
                <span>Host has an Arke account </span>
                <br />
                {hostUpdatepermission && (
                  <Button
                    color="link"
                    className="text-primary"
                    style={{ marginLeft: "12px" }}
                    onClick={() => toggleEmailModal(true)}
                  >
                    Send email address
                  </Button>
                )}
              </>
            ) : (
              <span>
                Host does not have an Arke account, they can sign up with their
                phone number.
              </span>
            )}
          </p>
        </Col>
        <Col sm={12}>
          <p>
            {props.host.externalUserId === null ? <Edit2 /> : <RefreshCw />}{" "}
            {props.host.externalUserId === null ? t("manual") : t("net2Sync")}
            {props.host.externalUserId === null ? (
              <>
                {" "}
                <br />
                {hostDeletionpermission && (
                  <Button
                    onClick={() => toggleRemoveModal(true)}
                    color="danger"
                    style={{ marginTop: "15px" }}
                  >
                    {t("common:remove")}
                  </Button>
                )}
              </>
            ) : null}
          </p>
        </Col>

        <Col sm={12}>
          <p className="text-muted">
            {t("common:lastModified")}{" "}
            {moment.utc(props.host.lastModifiedUtc).local().format("LT l")}
          </p>
        </Col>
      </Row>
      <Row>
        <Col sm={12}>
          <h2 style={{ marginTop: "15px" }}>{t("visits")}</h2>
        </Col>
      </Row>
      <Row>
        <Col>
          {props.hostsStore.hostPaginationStore !== null && (
            <PaginationView
              renderItem={({ item }: { item: VisitResponseV1 }) => {
                return (
                  <VisitRow
                    visit={item}
                    t={t}
                    onSelect={(v) => {
                      props.history.push(
                        `/sites/${v.siteId}/visits/${v.visitId}`
                      );
                    }}
                    showStartTimeOnly={false}
                  />
                );
              }}
              keyExtractor={(visit: VisitResponseV1) => visit.visitId}
              store={props.hostsStore.hostPaginationStore}
              columns={[
                t("common:visitor"),
                t("common:status"),
                t("common:createdVia"),
                t("start"),
                "",
              ]}
            />
          )}
        </Col>
      </Row>
    </>
  );
};

interface InjectedProps extends IProps {
  hostsStore: HostsStore;
  itemContainer: { site: SiteResponseV1; accounts: AccountResponseV1[] };
}

interface IProps extends WithTranslation {}

@inject("hostsStore", "itemContainer")
@observer
class HostDetailsPageContent extends React.Component<
  InjectedProps & RouteComponentProps<{ hostId: string }>,
  {}
> {
  private get injectedprops() {
    return this.props as InjectedProps;
  }
  public async componentWillMount() {
    this.injectedprops.hostsStore.signalIsGettingItems();
  }
  public async componentDidMount() {
    this.injectedprops.hostsStore.getHost(this.props.match.params.hostId);
  }

  private goBack = (event: React.MouseEvent<HTMLAnchorElement>) => {
    event.preventDefault();
    this.props.history.push(this.injectedprops.hostsStore.hostsUrl);
  };

  public render() {
    const t = this.props.t;
    return (
      <>
        <CardHeader>
          <a
            href={this.props.hostsStore.hostsUrl}
            style={{ marginLeft: "-10px" }}
            onClick={this.goBack}
          >
            <ChevronLeft />
            {t("back")}
          </a>
          <Row style={{ marginBottom: "1rem" }}>
            <Col md={4}>
              <h2 style={{ marginTop: "15px" }}>{t("title")}</h2>
            </Col>
            <Col sm={{ offset: 6, size: 2 }}>
              <ShowWithPermission
                permission={SitePermissions.conversationscreate}
              >
                <Link
                  to={`/sites/${this.injectedprops.itemContainer.site.siteId}/messaging/conversations/new/${this.props.match.params.hostId}`}
                  style={{ textDecoration: "none" }}
                >
                  <Button outline={true} color="secondary" block={true}>
                    {t("sendMessage")} <Mail size={20} />
                  </Button>
                </Link>
              </ShowWithPermission>
            </Col>
          </Row>
        </CardHeader>
        <CardBody>
          {this.injectedprops.hostsStore.isGettingItems === true && <Spinner />}
          {this.injectedprops.hostsStore.isGettingItems === false &&
            this.injectedprops.hostsStore.selectedItem === null && (
              <h2>{t("common:notfound")}</h2>
            )}
          {this.injectedprops.hostsStore.isGettingItems === false &&
            this.injectedprops.hostsStore.selectedHostDetails !== null && (
              <HostDetails
                host={this.injectedprops.hostsStore.selectedHostDetails}
                itemContainer={this.injectedprops.itemContainer}
                hostsStore={this.injectedprops.hostsStore}
                history={this.props.history}
              />
            )}
        </CardBody>
      </>
    );
  }
}

const HostDetailPage = withTranslation("hostDetails")(HostDetailsPageContent);

export { HostDetailPage };
