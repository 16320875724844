import i18n from "i18next";
import { inject, observer } from "mobx-react";
import * as React from "react";
import { withTranslation, WithTranslation } from "react-i18next";

import { HostResponseV1, SitePermissions } from "@intreba/arke-api-client";

import { Search, ChevronRight } from "react-feather";
import {
  Button,
  CardBody,
  CardHeader,
  Col,
  Form,
  FormGroup,
  Row,
} from "reactstrap";
import Input from "reactstrap/lib/Input";
import PaginationView from "../../services/pagination/PaginationView";
import AddManualHostModal from "../../views/hosts/AddManualHostModal";
import { LocalisedPhoneNumber, Spinner, Ctd } from "../../components";
import HostsStore from "./HostsStore";
import { RouteComponentProps } from "react-router";
import ShowWithPermission from "../../components/user/ShowWithPermission";

let moment = require("moment");
interface InjectedProps extends IProps {
  hostsStore: HostsStore;
}

interface IProps extends WithTranslation {}

class HostRow extends React.Component<{
  host: HostResponseV1;
  t: i18n.TFunction;
  onSelect: (host: HostResponseV1) => void;
}> {
  private onClick = (event: React.MouseEvent<HTMLTableRowElement>) => {
    event.preventDefault();
    this.props.onSelect(this.props.host);
  };

  public render() {
    const { host } = this.props;
    const dateTime = moment.utc(host.lastModifiedUtc);
    const formattedModified = dateTime.local().format("llll");
    const hostHref = `hosts/${this.props.host.hostId}`;
    return (
      <tr style={{ cursor: "pointer" }} onClick={this.onClick}>
        <Ctd href={hostHref}>{host.name}</Ctd>
        <Ctd href={hostHref}>
          <LocalisedPhoneNumber phoneNumber={host.phoneNumber} />
        </Ctd>
        <Ctd href={hostHref}>{formattedModified}</Ctd>
        <Ctd
          href={hostHref}
          style={{ verticalAlign: "middle", textAlign: "center" }}
        >
          <ChevronRight />
        </Ctd>
      </tr>
    );
  }
}

@inject("hostsStore")
@observer
class ViewHosts extends React.Component<
  InjectedProps & RouteComponentProps<{}>,
  {
    dateSelectorOpen: boolean;
    addHostModalOpen: boolean;
  }
> {
  public extractKey(item: HostResponseV1) {
    return item.hostId;
  }
  private onHostSelect = (host: HostResponseV1) => {
    this.injectedprops.hostsStore.selectHost(host);
    this.props.history.push(this.injectedprops.hostsStore.currentHostUrl);
  };
  public renderItem = ({ item }: { item: HostResponseV1 }) => {
    return (
      <HostRow
        host={item}
        t={this.injectedprops.t}
        onSelect={this.onHostSelect}
      />
    );
  };
  private get injectedprops() {
    return this.props as InjectedProps;
  }
  public constructor(props: any) {
    super(props);
    this.state = {
      dateSelectorOpen: false,
      addHostModalOpen: false,
    };
  }

  public componentDidMount() {
    this.injectedprops.hostsStore.goToPage(1);
  }

  private onSearch = (event: React.FormEvent) => {
    event.preventDefault();
    this.injectedprops.hostsStore.goToPage(1);
  };

  private closeAddHostModal = () => {
    this.setState({
      addHostModalOpen: false,
    });
  };

  private openAddHostModal = () => {
    this.setState({ addHostModalOpen: true });
  };

  public render() {
    const t = this.injectedprops.t!;
    return (
      <>
        <AddManualHostModal
          isOpen={this.state.addHostModalOpen}
          toggle={this.closeAddHostModal}
          t={t}
        />
        <CardHeader tag="div">
          <Row style={{ marginBottom: "1rem" }}>
            <Col md={2}>
              <h2>
                {!this.injectedprops.hostsStore.isGettingItems &&
                  this.injectedprops.hostsStore.totalItems}{" "}
                {t("title")}
              </h2>
            </Col>
            <Col sm={{ offset: 8, size: 2 }}>
              <ShowWithPermission permission={SitePermissions.hostscreate}>
                <Button
                  outline={true}
                  color="secondary"
                  block={true}
                  onClick={this.openAddHostModal}
                >
                  {t("addHost")}
                </Button>
              </ShowWithPermission>
            </Col>
          </Row>
          <Row>
            <Col md={{ size: 1, offset: 8 }}>
              {" "}
              <Search
                style={{
                  marginTop: "6px",
                  marginRight: "-25px",
                  float: "right",
                }}
              />
            </Col>

            <Col md={{ size: 3 }}>
              <Form onSubmit={this.onSearch}>
                <FormGroup style={{ marginBottom: 0 }}>
                  <Input
                    type="text"
                    value={this.injectedprops.hostsStore.search}
                    onChange={this.injectedprops.hostsStore.onSearchChange}
                    placeholder={this.props.t("searchPlaceholder")}
                    disabled={this.injectedprops.hostsStore.isGettingItems}
                  />
                </FormGroup>
              </Form>
            </Col>
          </Row>
        </CardHeader>
        <CardBody>
          <Row>
            <Col>
              <PaginationView
                renderItem={this.renderItem}
                keyExtractor={this.extractKey}
                store={this.injectedprops.hostsStore}
                columns={[
                  t("name"),
                  t("common:phoneNumber"),
                  t("lastModified"),
                  "",
                ]}
              />
            </Col>
          </Row>
          {this.injectedprops.hostsStore.isGettingItems && <Spinner />}
        </CardBody>
      </>
    );
  }
}

export default withTranslation("hosts")(ViewHosts);
