import React, { useState, useEffect } from "react";
import { WithTranslation, useTranslation } from "react-i18next";
import { inject, observer } from "mobx-react";
import { RouteComponentProps } from "react-router-dom";
import {
  SiteResponseV1,
  IArke,
  AdditionalFieldUserType,
  AdditionalFieldResponseV1,
} from "@intreba/arke-api-client";
import { AdditionalFieldsStore } from "./AdditionalFieldsStore";
import {
  CardHeader,
  Row,
  Col,
  CardBody,
  Button,
  Nav,
  NavItem,
  TabContent,
  TabPane,
  NavLink,
} from "reactstrap";
import PaginationView from "../../../../services/pagination/PaginationView";
import { AdditionalFieldRow } from "./AdditionalFieldRow";
import { AdditionalFieldDetails } from "./AdditionalFieldDetails";
import { Spinner } from "../../../../components";

const AdditionalFieldsList = (props: {
  additionalFieldsStore: AdditionalFieldsStore;
  isWorking: boolean;
}) => {
  const { t } = useTranslation("sitesettingsAdditionalFields");

  const [additionalFieldDetailsShow, setAdditionalFieldDetailsShow] = useState(
    false
  );

  const [activeTab, setActiveTab] = useState("1");

  const toggle = (tab: string) => {
    if (activeTab !== tab) setActiveTab(tab);
    setAdditionalFieldDetailsShow(false);
  };

  const extractKey = (item: AdditionalFieldResponseV1, index: number) => {
    return item.additionalFieldId;
  };

  const onAdditionalFieldSelect = (item: AdditionalFieldResponseV1) => {
    props.additionalFieldsStore.selectAdditionalField(item);
    setAdditionalFieldDetailsShow(true);
  };

  const onAdditionalFieldDelete = (additionalFieldId: string) => {
    props.additionalFieldsStore.deleteAdditionalField(additionalFieldId);
    props.additionalFieldsStore.goToPage(1);
  };

  const renderItem = ({ item }: { item: AdditionalFieldResponseV1 }) => {
    return (
      <AdditionalFieldRow
        additionalField={item}
        onSelect={onAdditionalFieldSelect}
        onDelete={onAdditionalFieldDelete}
      />
    );
  };

  useEffect(() => {
    props.additionalFieldsStore.goToPage(1);
  }, []);

  return (
    <>
      <CardHeader tag="div">
        <Row>
          <Col md={4}>
            <h2>{t("title")}</h2>
          </Col>
          <Col sm={{ offset: 6, size: 2 }}>
            {!additionalFieldDetailsShow && (
              <Button
                outline={true}
                color="secondary"
                block={true}
                onClick={() => {
                  props.additionalFieldsStore.selectedItem = null;
                  setAdditionalFieldDetailsShow(true);
                }}
              >
                {t("addField")}
              </Button>
            )}
          </Col>
        </Row>
      </CardHeader>
      <CardBody>
        <div style={{ position: "absolute", width: "100%", zIndex: 99 }}>
          {props.isWorking && <Spinner />}
        </div>
        <div>
          <>
            <Nav tabs>
              <NavItem>
                <NavLink
                  className={`1 ${activeTab === "1" ? "active" : ""}`}
                  onClick={() => {
                    toggle("1");
                  }}
                >
                  {t(AdditionalFieldUserType[AdditionalFieldUserType.Visitor])}
                </NavLink>
              </NavItem>
              {!props.additionalFieldsStore.betaVersion && (
                <NavItem>
                  <NavLink
                    className={`2 ${activeTab === "2" ? "active" : ""}`}
                    onClick={() => {
                      toggle("2");
                    }}
                  >
                    {t(AdditionalFieldUserType[AdditionalFieldUserType.User])}
                  </NavLink>
                </NavItem>
              )}
            </Nav>
            <TabContent>
              <TabPane>
                <Row>
                  <Col>
                    {additionalFieldDetailsShow ? (
                      <AdditionalFieldDetails
                        additionalFieldsStore={props.additionalFieldsStore}
                        goback={() => setAdditionalFieldDetailsShow(false)}
                      />
                    ) : (
                      <PaginationView
                        renderItem={renderItem}
                        keyExtractor={extractKey}
                        store={props.additionalFieldsStore}
                        columns={[
                          t("name"),
                          t("fieldType"),
                          t("description"),
                          "",
                          "",
                        ]}
                      />
                    )}
                  </Col>
                </Row>
              </TabPane>
            </TabContent>
          </>
        </div>
      </CardBody>
    </>
  );
};

interface InjectedProps extends IProps {
  itemContainer: { site: SiteResponseV1 };
  arke: IArke;
  additionalFieldsStore: AdditionalFieldsStore;
}

interface IProps extends WithTranslation {}

@inject("itemContainer", "arke", "additionalFieldsStore")
@observer
export class ViewAdditionalFields extends React.Component<
  InjectedProps & RouteComponentProps<{}>
> {
  render() {
    return (
      <AdditionalFieldsList
        additionalFieldsStore={this.props.additionalFieldsStore}
        isWorking={
          this.props.additionalFieldsStore.isWorking ||
          this.props.additionalFieldsStore.isGettingItems
        }
      />
    );
  }
}
