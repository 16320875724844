import React from "react";
import { Row, Col, Button, Input, CardHeader } from "reactstrap";
import { RouteComponentProps } from "react-router";
import { ChevronLeft, Send } from "react-feather";
import history from "../../services/history";
import { inject, observer } from "mobx-react";
import { WithTranslation, withTranslation } from "react-i18next";
import { HostResponseWithIdentityResponseV1 } from "@intreba/arke-api-client";
import ConversationgStore from "./ConversationStore";
import { RichEditor } from "./RichEditor";
import { Spinner } from "../../components";

interface InjectedProps extends IProps {
  conversationStore: ConversationgStore;
}

interface IProps extends WithTranslation {}

@inject("conversationStore")
@observer
class NewConversation extends React.Component<
  InjectedProps & RouteComponentProps<{ hostId: string; siteId: string }>,
  {
    hostDetails: HostResponseWithIdentityResponseV1 | null;
  }
> {
  private get injectedprops() {
    return this.props as InjectedProps;
  }

  public constructor(props: any) {
    super(props);
    this.state = {
      hostDetails: null,
    };
  }

  public async componentDidMount() {
    if (this.props.match.params.hostId) {
      let host = await this.injectedprops.conversationStore.getHostDetails(
        this.props.match.params.hostId
      );
      this.setState({ hostDetails: host });
    }

    this.injectedprops.conversationStore.getNewConversationStoredEditorState(
      this.props.match.params.hostId !== undefined
        ? this.props.match.params.hostId
        : this.props.match.params.siteId
    );
  }

  private goBack() {
    if (this.props.match.params.hostId) {
      history.goBack();
      return;
    }
    history.replace("../conversations");
  }

  private createConversation = async () => {
    let sendTo = [
      this.props.match.params.hostId
        ? this.props.match.params.hostId
        : this.props.match.params.siteId,
    ];
    await this.injectedprops.conversationStore.createConversation(sendTo);
    this.injectedprops.conversationStore.clearStoredContent(
      this.props.match.params.hostId
        ? this.props.match.params.hostId
        : this.props.match.params.siteId
    );
    this.goBack();
  };

  render() {
    const t = this.injectedprops.t!;
    return (
      <>
        <CardHeader tag="div">
          <Button
            color="link"
            style={{ marginLeft: "-10px" }}
            onClick={(e) => {
              e.preventDefault();
              this.goBack();
            }}
          >
            <ChevronLeft />
            {t("back")}
          </Button>
          <Row style={{ marginBottom: "1rem" }}>
            <Col>
              <h2>{t("newMessage")}</h2>
            </Col>
          </Row>
        </CardHeader>
        <div
          className="main main-visible"
          data-mobile-height=""
          style={{ overflowY: "auto" }}
        >
          <div
            id="chat-2"
            className="chat dropzone-form-js"
            data-dz-url="some.php"
            style={{ height: "unset" }}
          >
            <div
              className="chat-body"
              style={{
                marginLeft: "2.5em",
                marginRight: "2.5em",
                marginBottom: "2.5em",
                border: "1px solid #e3ebf6",
              }}
            >
              <div className="chat-header border-bottom py-4 py-lg-6 px-lg-8">
                <div className="container-xxl">
                  <div className="row align-items-center">
                    <div className="col-3 d-xl-none">
                      <ul className="list-inline mb-0">
                        <li className="list-inline-item">
                          <Button
                            color="link"
                            onClick={(e) => {
                              e.preventDefault();
                              this.goBack();
                            }}
                          >
                            <ChevronLeft />
                            {t("back")}
                          </Button>
                          <h6 className="mb-n1" style={{ fontWeight: "bold" }}>
                            <ChevronLeft color={"transparent"} />
                          </h6>
                        </li>
                      </ul>
                    </div>

                    <div className="col-6 col-xl-6">
                      <div className="media text-center text-xl-left">
                        <div className="avatar avatar-sm d-none d-xl-inline-block mr-5"></div>

                        <div
                          className="media-body align-self-center"
                          style={{ whiteSpace: "nowrap" }}
                        >
                          <h6 className="mb-n1" style={{ fontWeight: "bold" }}>
                            <Input
                              type={"text"}
                              autoFocus={true}
                              maxLength={100}
                              value={
                                this.injectedprops.conversationStore
                                  .messageSubject
                              }
                              pattern={
                                "^[@£$¥èéùìòÇØøÅå_ÆæßÉ!\"#%&'()*+,\\-./0123456789:;<=>? ¡ABCDEFGHIJKLMNOPQRSTUVWXYZÄÖÑÜ§¿abcdefghijklmnopqrstuvwxyzäöñüà]+$"
                              }
                              placeholder={t("subjectPlaceholder")}
                              onChange={(e) =>
                                this.injectedprops.conversationStore.setMessageSubject(
                                  e.target.value
                                )
                              }
                            />
                          </h6>
                          <div
                            style={{
                              margin: 10,
                              lineHeight: 1,
                              verticalAlign: "middle",
                            }}
                          >
                            {"Conversation with <"}
                            {this.props.match.params.hostId !== undefined ? (
                              <span>{this.state.hostDetails?.name}</span>
                            ) : (
                              <span>{t("management")}</span>
                            )}
                            {">"}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="chat-content" style={{ overflow: "auto" }}>
                <div className="container-xxl vertical flip-container"></div>

                <div className="end-of-chat"></div>
              </div>
              <div className="chat-footer border-top py-lg-6 px-lg-8">
                <Row style={{ margin: "unset" }}>
                  <Col style={{ padding: "unset" }}>
                    {this.injectedprops.conversationStore.isWorking && (
                      <div style={{ position: "absolute", width: "100%" }}>
                        <Spinner />
                      </div>
                    )}
                    <RichEditor
                      onChange={(state) =>
                        this.injectedprops.conversationStore.setMessageMarkdowncontent(
                          state,
                          this.props.match.params.hostId
                            ? this.props.match.params.hostId
                            : this.props.match.params.siteId
                        )
                      }
                      state={
                        this.injectedprops.conversationStore
                          .messageMarkdowncontent
                      }
                      actionButton={
                        <Button
                          style={{ width: "100%" }}
                          color={"primary"}
                          onClick={this.createConversation}
                          disabled={
                            this.injectedprops.conversationStore.isWorking ||
                            this.injectedprops.conversationStore.messageSubject
                              .length < 1 ||
                            !this.injectedprops.conversationStore.messageMarkdowncontent
                              .getCurrentContent()
                              .hasText()
                          }
                        >
                          <Send />
                          &nbsp;
                          <span
                            dangerouslySetInnerHTML={{
                              __html: t("send"),
                            }}
                          />
                        </Button>
                      }
                    />
                  </Col>
                </Row>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default withTranslation("conversations")(NewConversation);
