export class TimeSpan {
  public days: number = 0;
  public hours: number = 0;
  public minutes: number = 0;
  public seconds: number = 0;

  constructor(
    days?: number,
    hours?: number,
    minutes?: number,
    seconds?: number
  ) {
    this.days = days ?? 0;
    this.hours = hours ?? 0;
    this.minutes = minutes ?? 0;
    this.seconds = seconds ?? 0;
  }

  public toString = (): string => {
    return `${this.days}.${this.hours}:${this.minutes}:${this.seconds}`;
  };

  public parseString(timeSpan: string | null) {
    if (timeSpan === null) {
      return;
    }
    const daySplit = timeSpan.split(".");
    let subDayText = daySplit[0];
    if (daySplit.length > 1) {
      this.days = parseInt(daySplit[0], 10);
      subDayText = daySplit[1];
    }

    let subDaySplit = subDayText.split(":");
    if (subDaySplit.length === 3) {
      this.hours = parseInt(subDaySplit[0], 10);
      this.minutes = parseInt(subDaySplit[1], 10);
      this.seconds = parseInt(subDaySplit[2], 10);
    } else {
      const message = `Unknown timespan format for ${timeSpan}`;
      console.error(message);
      throw new Error(message);
    }
  }
}
