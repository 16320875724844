import { inject, observer } from "mobx-react";
import * as React from "react";
import { withTranslation, WithTranslation } from "react-i18next";
import { RouteComponentProps } from "react-router-dom";
import { CardBody } from "reactstrap";
import { Spinner } from "../../components";
import AppState from "../../services/AppState";
interface InjectedProps extends IProps {
  appState: AppState;
}

interface IProps extends WithTranslation {}

@inject("arke", "appState")
@observer
class StatusPage extends React.Component<
  InjectedProps & RouteComponentProps<{ accountId: string }>,
  {
    requesting: boolean;
  }
> {
  public constructor(props: any) {
    super(props);
    this.state = { requesting: false };
  }
  private get injectedprops() {
    return this.props as InjectedProps;
  }

  public render() {
    const account = this.props.appState.account;
    if (account === null) {
      return <Spinner />;
    }
    const t = this.props.t!;
    if (account.approved === false) {
      return (
        <CardBody dangerouslySetInnerHTML={{ __html: t("awaitingApproval") }} />
      );
    }
    return null;
  }
}

export default withTranslation("newAccount")(StatusPage);
