import { inject, observer } from "mobx-react";
import * as React from "react";
import {
  Button,
  Col,
  Form,
  FormGroup,
  Input,
  Label,
  Row,
  CardHeader,
  CardBody,
  UncontrolledTooltip
} from "reactstrap";
import {
  useTranslation,
  withTranslation,
  WithTranslation
} from "react-i18next";
import { Slash, ChevronLeft } from "react-feather";
import { AddUserState } from "./AddUserState";
import { IArke, SiteResponseV1, UserRoles } from "@intreba/arke-api-client";
import { AddUserRole } from "./AddUserRole";
import history from "../../../../services/history";
import { UserRole } from "./UserRole";

interface InjectedProps extends IProps {
  arke: IArke;
  itemContainer: { site: SiteResponseV1 };
}

interface IProps extends WithTranslation {}

const CannotDeleteSlash = (props: { role: UserRoles }) => {
  const id = `r${props.role.toString(10)}`;
  const { t } = useTranslation("sitesettingsusers");
  return (
    <>
      <Slash id={id} />
      <UncontrolledTooltip placement="right" target={id}>
        {t("cannotManageSite")}
      </UncontrolledTooltip>
    </>
  );
};

const RoleCheckRow = observer(
  (props: { role: AddUserRole; disabled: boolean }) => {
    return (
      <FormGroup check={true} key={props.role.role}>
        <Label check={true}>
          <Input
            type="checkbox"
            name="roles"
            value={props.role.role}
            checked={props.role.checked}
            onChange={props.role.toggleRole}
            disabled={!props.role.manageable || props.disabled}
          />
          <UserRole role={props.role.role} />{" "}
          {!props.role.manageable && (
            <CannotDeleteSlash role={props.role.role} />
          )}
        </Label>
      </FormGroup>
    );
  }
);

@inject("arke", "itemContainer")
@observer
class AddUserContent extends React.Component<IProps, {}> {
  private get injectedprops() {
    return this.props as InjectedProps;
  }
  private addState: AddUserState;
  constructor(props: any) {
    super(props);
    this.addState = new AddUserState(
      this.injectedprops.arke,
      this.injectedprops.itemContainer.site.siteId
    );
  }
  private goBack = (event: React.MouseEvent<HTMLAnchorElement>) => {
    event.preventDefault();
    history.push(
      `/sites/${this.injectedprops.itemContainer.site.siteId}/settings/users`
    );
  };

  public async componentDidMount() {
    await this.addState.getManageableRoles();
  }
  public render() {
    const t = this.injectedprops.t!;
    const props = this.injectedprops;
    return (
      <>
        <CardHeader>
          <a
            href={`/sites/${
              this.injectedprops.itemContainer.site.siteId
            }/settings/users`}
            style={{ marginLeft: "-10px" }}
            onClick={this.goBack}
          >
            <ChevronLeft />
            {props.t("back")}
          </a>
          <h2>{t("addUserTitle")}</h2>
        </CardHeader>
        <CardBody>
          <Row>
            <Col sm="12">
              <Form onSubmit={this.addState.onAddUserSubmit}>
                <FormGroup row={true}>
                  <Label for="name" lg={2}>
                    {t("email")}
                  </Label>
                  <Col lg={10}>
                    <Input
                      type="text"
                      name="email"
                      id="email"
                      value={this.addState.email}
                      onChange={this.addState.changeEmail}
                      disabled={this.addState.isWorking}
                      valid={this.addState.validEmail}
                      invalid={!this.addState.validEmail}
                    />
                  </Col>
                </FormGroup>

                <FormGroup row={true}>
                  <Label lg={2} for="roles">
                    {props.t("roles")}
                  </Label>
                  <Col lg={10}>
                    {this.addState.roles.map(r => {
                      return (
                        <RoleCheckRow
                          key={r.role}
                          role={r}
                          disabled={this.addState.isWorking}
                        />
                      );
                    })}
                  </Col>
                </FormGroup>
                <Button
                  type="submit"
                  color="primary"
                  disabled={this.addState.addUserDisabled}
                >
                  {t("common:add")}
                </Button>
              </Form>
            </Col>
          </Row>
        </CardBody>
      </>
    );
  }
}

const AddUser = withTranslation("sitesettingsusers")(AddUserContent);
export { AddUser };
