import { inject, observer } from "mobx-react";
import * as React from "react";
import { Line } from "react-chartjs-2";
import { Activity, LogIn, Users, XCircle } from "react-feather";
import { withTranslation, WithTranslation } from "react-i18next";
import { Card, CardBody, CardHeader, CardTitle, Col, Row } from "reactstrap";
import { Widget } from "../../components";

import {
  IArke,
  SiteResponseV1,
  SiteStatisticsResponseV1,
} from "@intreba/arke-api-client";
import { ChartPoint, LinearTickOptions } from "chart.js";
import { SiteTrialAlert } from "../../services/sitestatus/SiteTrialAlert";
import SiteStatusAlert from "../../services/sitestatus/SiteStatusAlert";
let moment = require("moment");
interface InjectedProps extends IProps {
  itemContainer: { site: SiteResponseV1 };
  arke: IArke;
}

interface IProps extends WithTranslation {}

@inject("itemContainer", "arke")
@observer
class Dashboard extends React.Component<
  IProps,
  {
    topBarOpen: boolean;
    loadingStatistics: boolean;
    statistics?: SiteStatisticsResponseV1;
  }
> {
  private filterXAxes(dataLabel: string, index: number): string {
    const isThird = index % 3 === 0;
    const dateMoment = moment(dataLabel);
    return isThird ? dateMoment.format("ll") : "";
  }
  public getGraphData(): ChartPoint[] {
    if (this.state === null || this.state.statistics === undefined) {
      return [];
    }
    return this.state.statistics.last30DaysGraph.map((item, index) => {
      return { x: index, y: item.validCount };
    });
  }
  public hasGraphData(): boolean {
    if (this.state === null || this.state.statistics === undefined) {
      return false;
    }
    if (
      this.state.statistics.failedVisitsLast30Days !== 0 ||
      this.state.statistics.visitsLast30Days !== 0
    ) {
      return true;
    }
    return false;
  }
  public getFailData(): ChartPoint[] {
    if (this.state === null || this.state.statistics === undefined) {
      return [];
    }
    return this.state.statistics.last30DaysGraph.map((item, index) => {
      return { x: index, y: item.failedCount };
    });
  }
  public getGraphLabels = (): Array<string | string[]> => {
    if (this.state === null || this.state.statistics === undefined) {
      return [];
    }
    const labels = this.state.statistics.last30DaysGraph.map((i) =>
      i.date.toString()
    );
    return labels;
  };
  constructor(props: any) {
    super(props);
    this.state = {
      topBarOpen: false,
      loadingStatistics: true,
      statistics: undefined,
    };
  }
  private get injectedprops() {
    return this.props as InjectedProps;
  }
  public async componentDidMount() {
    const statistics = await this.injectedprops.arke.sites.findStatistics(
      this.injectedprops.itemContainer.site.siteId
    );
    if (statistics !== null) {
      this.setState({
        statistics,
        loadingStatistics: false,
      });
    }
  }

  public render() {
    if (this.state === null) {
      return null;
    }
    const t = this.injectedprops.t!;
    return (
      <>
        <CardHeader tag="h2">{t("title")}</CardHeader>
        <CardBody>
          <Row>
            <Col md={12}>
              <SiteStatusAlert />
              <SiteTrialAlert />
            </Col>
          </Row>
          <Row>
            <Col md={3} className="widget">
              <Widget
                color="rgb(240, 104, 44)"
                title={t("activeVisits")}
                count={
                  this.state.statistics !== undefined
                    ? this.state.statistics.activeVisits
                    : 0
                }
                loading={this.state.loadingStatistics}
              >
                <Activity
                  height={35}
                  width={35}
                  style={{ height: "100%", verticalAlign: "middle" }}
                />
              </Widget>
            </Col>
            <Col md={3} className="widget">
              <Widget
                color="rgb(58, 151, 166)"
                title={t("last30DaysVisits")}
                count={
                  this.state.statistics !== undefined
                    ? this.state.statistics.visitsLast30Days
                    : 0
                }
                loading={this.state.loadingStatistics}
              >
                <LogIn
                  height={35}
                  width={35}
                  style={{ height: "100%", verticalAlign: "middle" }}
                />
              </Widget>
            </Col>
            <Col md={3} className="widget">
              <Widget
                color="rgb(109, 190, 70)"
                title={t("hosts")}
                count={
                  this.state.statistics !== undefined
                    ? this.state.statistics.hosts
                    : 0
                }
                loading={this.state.loadingStatistics}
              >
                <Users
                  height={35}
                  width={35}
                  style={{ height: "100%", verticalAlign: "middle" }}
                />
              </Widget>
            </Col>
            <Col md={3} className="widget">
              <Widget
                color="red"
                title={t("last30DaysFailedVisits")}
                count={
                  this.state.statistics !== undefined
                    ? this.state.statistics.failedVisitsLast30Days
                    : 0
                }
                loading={this.state.loadingStatistics}
              >
                <XCircle
                  height={35}
                  width={35}
                  style={{ height: "100%", verticalAlign: "middle" }}
                />
              </Widget>
            </Col>
          </Row>
          <Row>
            <Col md={12}>
              <Card
                style={{
                  marginTop: "15px",
                }}
              >
                <CardBody>
                  {this.hasGraphData() && (
                    <>
                      <CardTitle>{t("last30DaysVisits")}</CardTitle>
                      <Line
                        options={{
                          responsive: true,
                          legend: { display: false },
                          scales: {
                            yAxes: [
                              {
                                display: true,
                                ticks: {
                                  beginAtZero: true,
                                  min: 0,
                                  precision: 0,
                                } as LinearTickOptions,
                              },
                            ],
                            xAxes: [
                              {
                                ticks: {
                                  callback: this.filterXAxes,
                                  autoSkip: false,
                                },
                                gridLines: { display: false },
                              },
                            ],
                          },
                        }}
                        height={100}
                        data={{
                          labels: this.getGraphLabels(),
                          datasets: [
                            {
                              label: t("failed"),
                              backgroundColor: "rgba(255, 99, 132, 0.2)",
                              borderColor: "rgba(255,99,132,1)",
                              pointBackgroundColor: "rgba(255,99,132,1)",
                              pointBorderColor: "#fff",
                              pointHoverBackgroundColor: "#fff",
                              pointHoverBorderColor: "rgba(255,99,132,1)",
                              data: this.getFailData(),
                            },
                            {
                              label: t("visits"),
                              backgroundColor: "rgba(109, 190, 70,0.5)",
                              borderColor: "rgba(109, 190, 70,0.7)",
                              pointBackgroundColor: "rgba(109, 190, 70,1)",
                              pointBorderColor: "#fff",
                              pointHoverBackgroundColor: "#fff",
                              pointHoverBorderColor: "rgba(109, 190, 70,1)",
                              data: this.getGraphData(),
                            },
                          ],
                        }}
                      />
                    </>
                  )}
                  {!this.hasGraphData() &&
                    this.state.loadingStatistics === false && (
                      <CardTitle>{t("noVisitsLast30Days")}</CardTitle>
                    )}
                </CardBody>
              </Card>
            </Col>
          </Row>
        </CardBody>
      </>
    );
  }
}

export default withTranslation("dashboard")(Dashboard);
