import {
  SitePermissions,
  VisitResponseV1,
  VisitStatus,
} from "@intreba/arke-api-client";
import i18n from "i18next";
import { inject, observer } from "mobx-react";
import * as React from "react";
import { ChevronLeft } from "react-feather";
import { withTranslation, WithTranslation } from "react-i18next";
import { RouteComponentProps } from "react-router";
import { Badge, Button, CardBody, CardHeader, Col, Row } from "reactstrap";
import ShowWithPermission from "../../components/user/ShowWithPermission";
import { ShowVisitor, Spinner } from "../../components";
import { StatusUpdateTimeline } from "./StatusUpdateTimeline";
import { VisitDetailsResponseV1 } from "./VisitDetailsResponseV1";
import VisitsStore from "./VisitsStore";
import { ChevronsRight } from "react-feather";
import { Link } from "react-router-dom";

let moment = require("moment");
const StatusBadge = (props: { status: VisitStatus; t: i18n.TFunction }) => {
  const error = props.status > 400;
  const finished = props.status >= 100 && props.status <= 200;
  const active = props.status < 100;
  let color = "";
  if (active) {
    color = "success";
  } else if (finished) {
    color = "secondary";
  } else if (error) {
    color = "danger";
  } else {
    color = "light";
  }
  return <Badge color={color}>{props.t(`visitstatus:${props.status}`)}</Badge>;
};

const VisitDetails = observer(
  (props: {
    visit: VisitResponseV1;
    details: VisitDetailsResponseV1;
    t: i18n.TFunction;
    onScannedDocumentsClick: () => void;
  }) => {
    return (
      <Row>
        <Col sm={6}>
          <h5>{props.t("common:status")}</h5>
          <p>
            <StatusBadge t={props.t} status={props.visit.status} />
            <br />
          </p>
          {props.visit.status < 400 && (
            <>
              <h5>{props.t("schedule")}</h5>
              <p>
                {props.t("starts")}:{" "}
                {moment(props.visit.startAt).local().format("LT l")}
                <br />
                {props.visit.status > 99 && props.t("expired")}
                {props.visit.status < 100 && props.t("ends")} :{" "}
                {moment(props.visit.endAt).local().format("LT l")}
              </p>
            </>
          )}
          <Row>
            <Col sm={5}>
              <h5>{props.t("common:visitor")}</h5>
              <p>
                {props.visit.displayCode !== null &&
                  props.visit.displayCode !== "unknown" &&
                  props.visit.displayCode !== "UNKNOWN" && (
                    <>
                      {props.t("code")}:{" "}
                      <span>
                        <strong>{props.visit.displayCode}</strong>
                      </span>
                      <br />
                    </>
                  )}
                <ShowVisitor visitor={props.visit.mainVisitor} />
              </p>
              {props.details.statusUpdates.filter(
                (s) => s.status === "visit.documents.scanned"
              ).length > 0 && (
                <ShowWithPermission
                  permission={SitePermissions.visitdocumentsread}
                >
                  <p>
                    <Button onClick={props.onScannedDocumentsClick}>
                      View scanned documents
                    </Button>
                  </p>
                </ShowWithPermission>
              )}
            </Col>
            <Col sm={2}>
              <ChevronsRight
                className="align-middle"
                style={{
                  position: "absolute",
                  top: "50%",
                  bottom: "50%",
                  transform: "translate(-50%, -50%)",
                }}
              />
            </Col>
            <Col sm={5}>
              <h5>{props.t("common:host")}</h5>
              <p>
                <Link
                  to={`/sites/${props.visit.siteId}/hosts/${props.details.host.hostId}`}
                  className="stretched-link"
                >
                  {props.details.host.name}
                </Link>
                <br />
                {props.details.host.localFormattedPhoneNumber}
              </p>
            </Col>
          </Row>
          <p className="text-muted">
            {props
              .t("created")
              .replace(
                "{#created}",
                moment.utc(props.visit.createdAtUtc).local().format("LT l")
              )}
          </p>
        </Col>
        <Col sm={6}>
          {props.details.statusUpdates.length > 0 && (
            <StatusUpdateTimeline
              t={props.t}
              statusUpdates={props.details.statusUpdates}
              clientId={props.visit.createdByClientId}
            />
          )}
        </Col>
      </Row>
    );
  }
);

interface InjectedProps extends IProps {
  visitsStore: VisitsStore;
}

interface IProps extends WithTranslation {}

@inject("visitsStore")
@observer
class VisitDetailPageContent extends React.Component<
  InjectedProps & RouteComponentProps<{ visitId: string }>,
  {}
> {
  private get injectedprops() {
    return this.props as InjectedProps;
  }
  public async componentWillMount() {
    this.injectedprops.visitsStore.signalIsGettingItems();
  }
  public async componentDidMount() {
    this.injectedprops.visitsStore.getVisitAndDetails(
      this.props.match.params.visitId
    );
  }

  private goBack = (event: React.MouseEvent<HTMLAnchorElement>) => {
    event.preventDefault();
    this.props.visitsStore.goToVisits(this.props.history);
  };

  private onScannedDocumentsClick = () => {
    this.props.history.push(this.props.visitsStore.currentVisitDocumentsUrl);
  };

  public render() {
    const t = this.props.t;
    return (
      <>
        <CardHeader>
          <a
            href={this.props.visitsStore.siteVisitsUrlTimed}
            style={{ marginLeft: "-10px" }}
            onClick={this.goBack}
          >
            <ChevronLeft />
            {t("back")}
          </a>
          <h2 style={{ marginTop: "15px" }}>{t("title")}</h2>
        </CardHeader>
        <CardBody>
          {this.injectedprops.visitsStore.isGettingItems === true && (
            <Spinner />
          )}
          {this.injectedprops.visitsStore.isGettingItems === false &&
            this.injectedprops.visitsStore.selectedVisitDetails === null && (
              <h2>{t("common:notfound")}</h2>
            )}
          {this.injectedprops.visitsStore.isGettingItems === false &&
            this.injectedprops.visitsStore.selectedVisitDetails !== null &&
            this.injectedprops.visitsStore.selectedItem !== null && (
              <VisitDetails
                visit={this.injectedprops.visitsStore.selectedItem}
                details={this.injectedprops.visitsStore.selectedVisitDetails}
                onScannedDocumentsClick={this.onScannedDocumentsClick}
                t={t}
              />
            )}
        </CardBody>
      </>
    );
  }
}

const VisitDetailPage = withTranslation("visitDetails")(VisitDetailPageContent);

export { VisitDetailPage };
