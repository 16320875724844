import { inject, observer } from "mobx-react";
import * as React from "react";
import AuthState from "./AuthState";

export interface InjectedProps extends IProps {
  authState: AuthState;
}

export interface IProps {}
@inject("authState")
@observer
export class SigninCallback extends React.Component<IProps, {}> {
  private get injectedprops() {
    return this.props as InjectedProps;
  }
  public async componentDidMount() {
    console.debug("Handling signing callback");
    await this.injectedprops.authState.handleSigninCallback();
  }
  public render() {
    return <></>;
  }
}
