import {
  SitePermissions,
  userHasSitePermission,
} from "@intreba/arke-api-client";
import i18n from "i18next";
import { action, computed, observable, runInAction } from "mobx";
import { toast } from "react-toastify";
import { showApiErrorToast } from "../../services/commonToasts";
export default abstract class BaseSettingsState {
  @observable
  public loading: boolean = false;
  protected t: i18n.TFunction;
  @observable
  public hasEditPermissions: boolean = false;

  @computed
  public get disabled() {
    return !this.hasEditPermissions || this.loading;
  }

  @observable
  public siteId: string;
  @observable
  public retrieved: boolean = false;
  public editPermission: SitePermissions;
  constructor(
    t: i18n.TFunction,
    editPermission: SitePermissions,
    permissions: string[],
    siteId: string
  ) {
    this.t = t;
    this.hasEditPermissions = userHasSitePermission(
      permissions,
      editPermission
    );
    this.siteId = siteId;
    this.editPermission = editPermission;
  }
  @action
  public onSubmit = async (event: React.FormEvent) => {
    event.preventDefault();
    this.loading = true;
    try {
      await this.save();
      toast.success(this.t("common:savesuccess"));
      await this.load();
    } catch (e) {
      console.error(e);
      showApiErrorToast(e);
    } finally {
      runInAction(() => {
        this.loading = false;
      });
    }
  };
  public abstract save(): Promise<void>;

  public abstract retrieve(): Promise<void>;

  @action
  public async load() {
    this.loading = true;
    await this.retrieve();
    runInAction(() => {
      this.loading = false;
      this.retrieved = true;
    });
  }
}
