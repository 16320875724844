import { AdditionalFieldResponseV1 } from "@intreba/arke-api-client/dist/types/Entities/AdditionalFields";
import { ScreeningAdditionalField } from "@intreba/arke-api-client/dist/types/Entities/Screening";
import React from "react";
import { Trash } from "react-feather";
import { QuestionSelectDesiredAnswer } from "./QuestionSelectDesiredAnswer";
import { ScreeningOrderSelect } from "./ScreeningOrderSelect";

export const SelectedScreeningItem = (props: {
  screeningItem: ScreeningAdditionalField;
  disabled: boolean;
  selectedQuestions: ScreeningAdditionalField[];
  additionalField?: AdditionalFieldResponseV1;
  onRemove: (additionalFieldId: string) => void;
  onChange: (screeningField: ScreeningAdditionalField) => void;
}) => {
  if (props.additionalField === undefined) {
    throw new Error("Screening question found without additional field");
  }
  return (
    <tr>
      <td>
        <ScreeningOrderSelect
          disabled={props.disabled}
          otherQuestions={props.selectedQuestions}
          value={props.screeningItem.order}
          onChange={(order) => {
            const newData: ScreeningAdditionalField = {
              ...props.screeningItem,
              order: order,
            };
            props.onChange(newData);
          }}
        />
      </td>
      <td style={{ width: "60%" }}>
        {props.additionalField.name} ({props.additionalField.description})
      </td>
      <td>
        <QuestionSelectDesiredAnswer
          disabled={props.disabled}
          question={props.screeningItem}
          onChange={(newAnswer: string) => {
            const newData: ScreeningAdditionalField = {
              ...props.screeningItem,
              expectedAnswer: newAnswer,
            };
            props.onChange(newData);
          }}
        />
      </td>
      <td>
        <Trash
          onClick={() => props.onRemove(props.screeningItem.additionalFieldId)}
        />
      </td>
    </tr>
  );
};
