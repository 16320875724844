import { WatchlistRequestV1, WatchlistType } from "@intreba/arke-api-client";
import { observable, action } from "mobx";

export class ValidatedWatchlistRequestV1 extends WatchlistRequestV1 {
  isValid!: boolean;
}

export class AddWatchlistEntryStore {
  @observable
  public addNewEntryModalOpen: boolean = false;
  @observable
  public newEntry: ValidatedWatchlistRequestV1 = {
    type: WatchlistType.PhoneNumber,
    pattern: "",
    description: "",
    isValid: false,
  };

  @action
  public setNewEntryModal(value?: boolean) {
    this.newEntry = {
      type: WatchlistType.PhoneNumber,
      pattern: "",
      description: "",
      isValid: false,
    };
    this.addNewEntryModalOpen = value ? value : !this.addNewEntryModalOpen;
  }

  @action
  public onNewEntryInputChange(value: ValidatedWatchlistRequestV1) {
    this.newEntry = value;
  }
}
