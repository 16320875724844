import { inject, observer } from "mobx-react";
import * as React from "react";
import AuthState from "../../services/authentication/AuthState";

interface InjectedProps extends IProps {
  authState: AuthState;
}

interface IProps {}

@inject("authState")
@observer
export default class Username extends React.Component<IProps, {}> {
  private get injectedprops() {
    return this.props as InjectedProps;
  }
  public render() {
    return <span>{this.injectedprops.authState.userName}</span>;
  }
}
