import { VisitorResponseV1 } from "@intreba/arke-api-client";
import { observer } from "mobx-react";
import * as React from "react";
import { LocalisedPhoneNumber } from "../../components";

interface IProps {
  visitor: VisitorResponseV1;
}

@observer
export default class ShowVisitor extends React.Component<IProps, {}> {
  public render() {
    const items = [];
    if (this.props.visitor.firstName !== null) {
      items.push(
        <span key={this.props.visitor.firstName}>
          {this.props.visitor.firstName} {this.props.visitor.lastName}
        </span>
      );
    }
    if (this.props.visitor.phoneNumber !== null) {
      if (items.length > 0) {
        items.push(<br key="lineBreak" />);
      }
      items.push(
        <LocalisedPhoneNumber
          phoneNumber={this.props.visitor.phoneNumber}
          key={this.props.visitor.phoneNumber}
        />
      );
    }
    return items;
  }
}
