import * as React from "react";

const Ctd = (
  props: { href: string } & {
    children?: React.ReactNode;
  } & React.DetailedHTMLProps<
      React.TdHTMLAttributes<HTMLTableDataCellElement>,
      HTMLTableDataCellElement
    >
) => {
  const { href, children, ...otherProps } = props;
  return (
    <td {...otherProps}>
      <a href={props.href} style={{ color: "inherit", textDecoration: "none" }}>
        <div style={{ height: "100%", width: "100%" }}>{props.children}</div>
      </a>
    </td>
  );
};

export { Ctd };
