import { IArke, SiteResponseV1 } from "@intreba/arke-api-client";
import { inject, observer } from "mobx-react";
import * as React from "react";
import { withTranslation, WithTranslation } from "react-i18next";
import { Alert } from "reactstrap";

import EnvironmentConfig from "../environment";
interface InjectedProps extends IProps {
  itemContainer: { site: SiteResponseV1 };
  arke: IArke;
}

interface IProps extends WithTranslation {
  messageName: string;
  version: string;
}

@inject("itemContainer", "arke")
@observer
class FunctionalityRequiresClientUpgradeAlert extends React.Component<
  IProps,
  {
    version: string;
    siteVersion: string | null;
    siteThumbprint: string | null;
  }
> {
  private get injectedprops() {
    return this.props as InjectedProps;
  }

  public constructor(props: any) {
    super(props);
    this.state = {
      version: "",
      siteVersion: "",
      siteThumbprint: "",
    };
  }

  public async componentDidMount() {
    const version = await this.injectedprops.arke.clients.findLatestInstallerVersion(
      "net2"
    );
    const client = await this.injectedprops.arke.sites.findClient(
      this.injectedprops.itemContainer.site.siteId
    );
    if (client !== null && version !== null) {
      this.setState({
        version,
        siteVersion: client.version,
        siteThumbprint: client.thumbprint,
      });
    }
  }

  public render() {
    const t = this.props.t!;
    if (
      this.state.siteVersion === "0.0.0.0" ||
      this.state.siteVersion === null
    ) {
      return null;
    }
    if (
      this.state.siteVersion === this.state.version &&
      this.state.siteVersion !== "0.0.0.0"
    ) {
      return null;
    }

    let isOlderVersion = false;

    try {
      const siteClientVersion = this.state.siteVersion.split(".");
      const fromVersion = this.props.version.split(".");

      for (let index = 0; index < siteClientVersion.length; index++) {
        const siteVersionPart = parseInt(siteClientVersion[index], 10);
        if (index < fromVersion.length) {
          const fromVersionPart = parseInt(fromVersion[index], 10);
          if (fromVersionPart > siteVersionPart) {
            isOlderVersion = true;
          }
        }
      }
    } catch (e) {
      console.error(e);
      isOlderVersion = true;
    }

    const downloadUrl = `${EnvironmentConfig.arkeApi}/v1/clients/installers/net2/latest`;

    if (isOlderVersion) {
      return (
        <>
          <Alert color="warning">
            <span className="font-weight-bold">
              {t(`common:newFunctionality:${this.injectedprops.messageName}`)}
            </span>
            <br />
            {t("siteVersion")
              .replace("{#version}", this.state.version)
              .replace("{#siteversion}", this.state.siteVersion)}
            <a href={downloadUrl} target="_blank" rel="noopener noreferrer">
              {t("common:downloadButton")}
            </a>
          </Alert>
        </>
      );
    }
    return null;
  }
}

export default withTranslation("common")(
  FunctionalityRequiresClientUpgradeAlert
);
