import {
  AccountResponseV1,
  IArke,
  SiteResponseV1,
  BillingStatus
} from "@intreba/arke-api-client";
import { runInAction } from "mobx";
import { inject, observer } from "mobx-react";
import * as React from "react";
import { WithTranslation, withTranslation } from "react-i18next";
import { Alert } from "reactstrap";
import { Spinner } from "../../components";
let moment = require("moment");
interface InjectedProps extends IProps {
  itemContainer: { site: SiteResponseV1; accounts: AccountResponseV1[] };
  arke: IArke;
}

interface IProps extends WithTranslation {}

@inject("itemContainer", "arke")
@observer
class SiteTrialAlertItem extends React.Component<
  IProps,
  {
    requesting: boolean;
  }
> {
  constructor(props: any) {
    super(props);
    this.state = { requesting: false };
  }
  private requestPaid = async (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
    this.setState({ requesting: true });
    const site = this.injectedprops.itemContainer.site;
    await this.injectedprops.arke.sites.requestPaid(site.siteId);
    const newSite = await this.injectedprops.arke.sites.find(site.siteId);

    if (newSite !== null) {
      runInAction(() => {
        site.billingStatus = newSite.billingStatus;
      });
    }

    this.setState({ requesting: false });
  };
  private get injectedprops() {
    return this.props as InjectedProps;
  }

  public render() {
    const t = this.props.t!;
    const site = this.injectedprops.itemContainer.site;
    if (site === null) {
      return null;
    }
    if (this.state.requesting) {
      return (
        <Alert color="info">
          <Spinner />
        </Alert>
      );
    }

    if (site.trialExpiration === null) {
      return null;
    }
    let expiration = moment(site.trialExpiration);
    if (site.billingStatus === BillingStatus.RequestedPaid) {
      return (
        <Alert color="info">
          {t("paidRequestedAlert")}{" "}
          {t("trialExpirationAlert").replace(
            "{#expiration}",
            expiration.format("L") + " " + expiration.format("LT")
          )}
        </Alert>
      );
    }
    if (site.billingStatus === BillingStatus.TrialExpired) {
      return <Alert color="danger">{t("trialExpiredAlert")}</Alert>;
    }

    if (site.billingStatus === BillingStatus.Trial) {
      return (
        <Alert color="info">
          {t("trialExpirationAlert").replace(
            "{#expiration}",
            expiration.format("L") + " " + expiration.format("LT")
          )}{" "}
          <button className="alert-link button-link" onClick={this.requestPaid}>
            {" "}
            {t("trialExpirationAlertAction")}
          </button>
        </Alert>
      );
    }

    return null;
  }
}

export const SiteTrialAlert = withTranslation("common")(SiteTrialAlertItem);
