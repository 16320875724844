import React, { useState } from "react";
import {
  Button,
  CardBody,
  CardHeader,
  Col,
  FormText,
  Input,
  Label,
  Row,
} from "reactstrap";
import { useTranslation } from "react-i18next";
import { convertToRaw, EditorState, convertFromRaw } from "draft-js";
import { RichEditor } from "./RichEditor";
import { ChevronLeft, Send } from "react-feather";
import { draftjsToMd } from "draftjs-md-converter";
import { RouteComponentProps } from "react-router";
import { BroadcastMessageSettingsResponseV1 } from "@intreba/arke-api-client";
import { useSite, useAppState } from "../../state/State";

import {
  showApiErrorToast,
  showSuccessToast,
} from "../../services/commonToasts";
import { Spinner } from "../../components";
import { MessageType } from "./MessagingRouter";
import { debounce } from "lodash";
import { ConfirmModal } from "../../components/modals/ConfirmModal";
import { EmailInputModal } from "./EmailInputModal";

type StoredEditorState = {
  subject: string;
  editorState: any;
};

type StorageInfo = {
  site: string;
};

export function NewMessage(props: RouteComponentProps) {
  const appState = useAppState();
  const site = useSite();

  const { t } = useTranslation("messaging");
  let translation: { [key: string]: string } = t(MessageType.Broadcast, {
    returnObjects: true,
  });
  let testMessageModal = t("testMessageModal", {
    returnObjects: true,
  });

  const [
    broadcastSettings,
    setBroadcastSettings,
  ] = React.useState<BroadcastMessageSettingsResponseV1 | null>(null);
  const [subject, setSubject] = useState("");
  const [working, setWorking] = useState(true);
  React.useEffect(() => {
    async function loadSettings() {
      const settings = await appState.arke.conversation.getBroadcastSettings(
        site.siteId
      );

      setBroadcastSettings(settings);
      setWorking(false);
      if (site.siteId) {
        let userInfo: StorageInfo = {
          site: site.siteId,
        };

        let storedEditorValue = await localStorage.getItem(
          JSON.stringify(userInfo)
        );

        if (storedEditorValue !== null) {
          let currentEditorState = JSON.parse(storedEditorValue);
          setSubject(currentEditorState.subject);
          setEditorState(
            EditorState.createWithContent(
              convertFromRaw(currentEditorState.editorState)
            )
          );
        }
      }
    }
    loadSettings();
  }, [appState.arke.conversation, site.siteId]);

  const [editorState, setEditorState] = React.useState(
    EditorState.createEmpty()
  );

  const countWithoutIdentity: string =
    broadcastSettings !== null
      ? (
          broadcastSettings.hostCount - broadcastSettings.identityUserCount
        ).toString()
      : t("common:unknown");
  const hostsToSend: string =
    broadcastSettings !== null
      ? broadcastSettings.identityUserCount.toString()
      : t("common:unknown");

  const storeEditorState = async (state: EditorState) => {
    if (site.siteId) {
      let editorContent = convertToRaw(editorState.getCurrentContent());
      let stateToStore: StoredEditorState = {
        subject: subject,
        editorState: editorContent,
      };

      let userInfo: StorageInfo = {
        site: site.siteId,
      };

      localStorage.setItem(
        JSON.stringify(userInfo),
        JSON.stringify(stateToStore)
      );
    }
  };

  const goBack = () => {
    props.history.replace("../broadcasts");
  };

  async function sendBroadcast() {
    try {
      setWorking(true);
      const mdOutput = draftjsToMd(
        convertToRaw(editorState.getCurrentContent())
      );
      await appState.arke.conversation.sendBroadcast(site.siteId, {
        markdownContent: mdOutput,
        to: site.siteId,
        subject: subject,
      });
      localStorage.removeItem(
        JSON.stringify({
          site: site.siteId,
        })
      );
      await showSuccessToast(
        translation["messageSent"].replace("{}", hostsToSend)
      );

      goBack();
    } catch (e) {
      await showApiErrorToast(e);
    } finally {
      setWorking(false);
    }
  }

  async function sendTestMessage(email: string[]) {
    try {
      setWorking(true);
      const mdOutput = draftjsToMd(
        convertToRaw(editorState.getCurrentContent())
      );
      await appState.arke.conversation.sendTestMessage(site.siteId, {
        markdownContent: mdOutput,
        subject: subject,
        sendToEmail: email,
      });
      await showSuccessToast(t("testMessageSent"));
    } catch (e) {
      await showApiErrorToast(e);
    } finally {
      setWorking(false);
    }
  }

  const [confirmModalOpen, setConfirmModalOpen] = useState(false);
  const [testModalOpen, setTestModalOpen] = useState(false);

  const toggleTestModal = (value: boolean) => {
    setTestModalOpen(value);
  };

  const debounced = debounce(storeEditorState, 250);

  const processEditorChange = (state: EditorState) => {
    setEditorState(state);
    debounced(state);
  };

  return (
    <>
      <ConfirmModal
        open={confirmModalOpen}
        toggle={() => setConfirmModalOpen(!confirmModalOpen)}
        working={working}
        text={translation["confirmMessageContent"].replace(
          "{Count}",
          hostsToSend?.toString()
        )}
        onConfirm={sendBroadcast}
      />

      <EmailInputModal
        working={working}
        modalState={testModalOpen}
        toggleModal={toggleTestModal}
        sendMessage={sendTestMessage}
      />
      <CardHeader tag="div">
        <Row>
          <Col md={6}>
            <Button
              style={{ marginLeft: "-10px" }}
              color="link"
              onClick={(e) => {
                e.preventDefault();
                goBack();
              }}
            >
              <ChevronLeft />
              {t("backToMessaging")}
            </Button>
            <h2>{translation["title"]}</h2>
          </Col>
        </Row>
      </CardHeader>
      <CardBody>
        <Row>
          <Col md={12} lg={9}>
            <Row className="mb-4">
              <Col>
                <Label>{t("subject")}</Label>
                <Input
                  value={subject}
                  onChange={(e) => setSubject(e.target.value)}
                  placeholder={t("subjectPlaceholder")}
                  type="text"
                  name="messageSubject"
                  maxLength={70}
                  pattern={
                    "^[@£$¥èéùìòÇØøÅå_ÆæßÉ!\"#%&'()*+,\\-./0123456789:;<=>? ¡ABCDEFGHIJKLMNOPQRSTUVWXYZÄÖÑÜ§¿abcdefghijklmnopqrstuvwxyzäöñüà]+$"
                  }
                />
                <FormText color="muted">{t("subjectHelp")}</FormText>
              </Col>
            </Row>
            <Row>
              <Col>
                <RichEditor
                  onChange={(state) => {
                    processEditorChange(state);
                  }}
                  state={editorState}
                />
              </Col>
            </Row>
            <Row className="mt-2">
              <Col>
                <Button
                  disabled={
                    subject === "" ||
                    !editorState.getCurrentContent().hasText() ||
                    working
                  }
                  color="primary"
                  onClick={() => setConfirmModalOpen(true)}
                >
                  {" "}
                  <Send />
                  {
                    <>
                      &nbsp;
                      <span
                        dangerouslySetInnerHTML={{
                          __html: t("sendToHosts").replace(
                            "{HostCount}",
                            hostsToSend
                          ),
                        }}
                      />
                    </>
                  }
                </Button>{" "}
                {
                  <Button
                    onClick={() => setTestModalOpen(true)}
                    disabled={
                      subject === "" ||
                      !editorState.getCurrentContent().hasText() ||
                      working
                    }
                  >
                    {testMessageModal["title"]}
                  </Button>
                }
                <div style={{ display: working ? "inline-block" : "none" }}>
                  <Spinner className="m-0" />
                </div>
                <Row>
                  <Col>
                    {
                      <p
                        dangerouslySetInnerHTML={{
                          __html: t("hostsWithoutEmail").replace(
                            "{HostCount}",
                            countWithoutIdentity
                          ),
                        }}
                      />
                    }
                  </Col>
                </Row>
              </Col>
            </Row>
          </Col>
          <Col md={12} lg={3}>
            <p
              className="small"
              dangerouslySetInnerHTML={{
                __html: translation["messageExplanation"],
              }}
            />
          </Col>
        </Row>
      </CardBody>
    </>
  );
}
