import { SiteResponseV1 } from "@intreba/arke-api-client";
import * as React from "react";
import { DropdownItem } from "reactstrap";
export class SiteDropdownItem extends React.Component<
  {
    site: SiteResponseV1;
    onSelect: (siteId: string) => void;
    selected?: boolean;
  },
  {}
> {
  private onSiteSelect = () => {
    this.props.onSelect(this.props.site.siteId);
  };
  public render() {
    return (
      <DropdownItem onClick={this.onSiteSelect}>
        <span
          className={
            this.props.selected !== undefined && this.props.selected === true
              ? "font-weight-bold"
              : ""
          }
        >
          {this.props.site.name}
        </span>
      </DropdownItem>
    );
  }
}
