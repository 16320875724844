import { useTranslation } from "react-i18next";
import * as React from "react";
import i18n from "i18next";

const ClientName: React.FC<{ clientId: string | null }> = props => {
  const { t } = useTranslation("clients");
  if (props.clientId === null) {
    return t("clients:unknown");
  }
  // eslint-disable-next-line no-useless-escape
  return t("clients:C" + props.clientId.toUpperCase().replace(/\-/g, ""));
};

const clientName = (clientId: string | null, t: i18n.TFunction): string => {
  if (clientId === null) {
    return t("clients:unknown");
  }
  // eslint-disable-next-line no-useless-escape
  return t("clients:C" + clientId.toUpperCase().replace(/\-/g, ""));
};

export { ClientName, clientName };
