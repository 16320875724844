import { inject, observer } from "mobx-react";
import * as React from "react";
import {
  Button,
  Col,
  Form,
  FormGroup,
  FormText,
  Input,
  Label,
  Row,
  CardHeader,
  CardBody,
} from "reactstrap";

import { IArke, SiteResponseV1 } from "@intreba/arke-api-client";
import { Spinner } from "../../../../components";
import { CannotEditWarning } from "../CannotEditWarning";
import { NotificationsState } from "./NotificationsState";
import { withTranslation, WithTranslation } from "react-i18next";

interface InjectedProps extends IProps {
  itemContainer: { site: SiteResponseV1 };
  arke: IArke;
}

interface IProps extends WithTranslation {}

@inject("itemContainer", "arke")
@observer
class NotificationSettingsContent extends React.Component<IProps, {}> {
  private settingsState: NotificationsState;
  public constructor(props: any) {
    super(props);
    this.settingsState = new NotificationsState(
      this.injectedprops.t,
      this.injectedprops.arke,
      this.injectedprops.itemContainer.site
    );
  }
  private get injectedprops() {
    return this.props as InjectedProps;
  }
  public async componentDidMount() {
    await this.settingsState.load();
  }
  public render() {
    const t = this.injectedprops.t!;
    return (
      <>
        <CardHeader tag="div">
          <Row style={{ marginBottom: "1rem" }}>
            <Col md={12}>
              <h2>{t("title")}</h2>
            </Col>
          </Row>
        </CardHeader>
        <CardBody>
          <Row>
            <Col sm="12">
              <Form onSubmit={this.settingsState.onSubmit}>
                <CannotEditWarning state={this.settingsState} />
                <FormGroup row={true}>
                  <Label for="name" lg={2}>
                    {t("statusEmailListLabel")}
                  </Label>
                  <Col lg={10}>
                    <Input
                      type="text"
                      name="statusEmailList"
                      id="statusEmailList"
                      value={this.settingsState.statusEmailList}
                      onChange={this.settingsState.changeStatusEmailList}
                      disabled={this.settingsState.disabled}
                    />
                    <FormText color="muted">
                      {t("statusEmailListHelp")}
                    </FormText>
                  </Col>
                </FormGroup>
                <FormGroup row={true}>
                  <Label for="sendWelcomeMessage" lg={2}>
                    {t("sendWelcomeMessageShortLabel")}
                  </Label>
                  <Col lg={10}>
                    <FormGroup check={true}>
                      <Label check={true}>
                        <Input
                          disabled={this.settingsState.disabled}
                          type="checkbox"
                          name="sendWelcomeMessage"
                          id="sendWelcomeMessage"
                          checked={this.settingsState.sendWelcomeMessage}
                          onChange={this.settingsState.changeSendWelcomeMessage}
                        />
                        {t("sendWelcomeMessageLongLabel")}
                      </Label>
                    </FormGroup>
                    <FormText color="muted">
                      {t("sendWelcomeMessageHelp")}
                    </FormText>
                  </Col>
                </FormGroup>
                <FormGroup row={true}>
                  <Label for="sendHostCalendarInvite" lg={2}>
                    {t("sendHostCalendarInviteShortLabel")}
                  </Label>
                  <Col lg={10}>
                    <FormGroup check={true}>
                      <Label check={true}>
                        <Input
                          disabled={this.settingsState.disabled}
                          type="checkbox"
                          name="sendHostCalendarInvite"
                          id="sendHostCalendarInvite"
                          checked={this.settingsState.sendHostCalendarInvite}
                          onChange={
                            this.settingsState.changeSendHostCalendarInvite
                          }
                        />
                        {t("sendHostCalendarInviteLongLabel")}
                      </Label>
                    </FormGroup>
                    <FormText color="muted">
                      {t("sendHostCalendarInviteHelp")}
                    </FormText>
                  </Col>
                </FormGroup>
                <FormGroup row={true}>
                  <Label for="statusNotification" lg={2}>
                    {t("statusNotificationShortLabel")}
                  </Label>
                  <Col lg={10}>
                    <FormGroup check={true}>
                      <Label check={true}>
                        <Input
                          disabled={this.settingsState.disabled}
                          type="checkbox"
                          name="pushNotification"
                          id="pushNotification"
                          checked={this.settingsState.sendPushNotifications}
                          onChange={
                            this.settingsState.changeSendPushNotifications
                          }
                        />
                        {t("pushNotificationLongLabel")}
                      </Label>
                    </FormGroup>
                    <FormText color="muted">
                      {t("statusNotificationHelp")}
                    </FormText>
                  </Col>
                </FormGroup>
                {this.settingsState.disabled ? (
                  <CannotEditWarning state={this.settingsState} />
                ) : (
                  <Button
                    type="submit"
                    color="primary"
                    disabled={this.settingsState.disabled}
                  >
                    Save
                  </Button>
                )}
              </Form>
              {this.settingsState.loading && <Spinner />}
            </Col>
          </Row>
        </CardBody>
      </>
    );
  }
}

const NotificationSettings = withTranslation("sitesettingsnotifications")(
  NotificationSettingsContent
);
export { NotificationSettings };
